import React from 'react'
import styles from './FluIndices.module.scss'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import GeoProvider from '../../components/GeoProvider'
import DashboardMap from './DashboardMap'
import dataDisplayOptions from './dataDisplayOptions'
import { startDate, endDate } from './dates'
import Header from './Header'
import VulnerabilityBreakdown from './VulnerabilityBreakdown'
import SystemNotification from './../Dashboard/SystemNotification'
const VulnerabilityDashboard = () => {
  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.body}>
            <GeoProvider
              startDate={startDate}
              endDate={endDate}
              displayDate={"October 2023"}
              locationName={"US"}
              mapOptions={dataDisplayOptions[0]['options'][1]}
              indicatorName={dataDisplayOptions[0]['options'][1].indicatorName}
              regionSelected={'state'}
            >
              <SystemNotification/>
              <Header headerType="NAT" headerNumber={1} />
              <DashboardMap />
              <VulnerabilityBreakdown geo_type={"NAT"} geo_id={"NAT"}/>
            </GeoProvider>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default VulnerabilityDashboard
