import Highcharts from 'highcharts'
import HC_patternFill from 'highcharts-pattern-fill'
import HC_rounded from 'highcharts-rounded-corners'
HC_rounded(Highcharts)
HC_patternFill(Highcharts)
function getColumnChartOptions(finalData,pointLine,titleText,locationName) {
  return {
    chart: {
      type: 'bar',
      // height: 300,
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'bottom',
      margin: 50,
      floating: true,
    },
    yAxis: {
      title: {
        text: titleText,
        style: {
          color: Highcharts.getOptions().colors[1],
          fontSize: '14px',
        },
      },
      opposite: true,
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          return this.total 
        },
      },
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: true,
    },
    xAxis: {
      categories: finalData[0].data,
      crosshair:{color:'#E5E5E6'},
      
      labels: {
        style: {
          fontSize: pointLine?'12px':'14px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
      },
     
      
    },
    tooltip: {
      enabled: true, //Added to disable tooltip
      borderRadius: 10,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      style: {
        padding: 0,
      },
      
      formatter: function () {
        return (
          `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
          border-radius: 4px; font-family: 'Optum Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          ">
          <div >${this.key} </div>
          <div style="color:#002677;padding-top:10px">${locationName}</div>
          <div style="padding-top:10px">${this.y}</div>
          </div>`
        )
      },
    },
    // tooltip: {
    //   enabled: false, //Added to disable tooltip
    //   formatter: function () {
    //     return (
    //       '<b>' +
    //       this.x +
    //       '</b><br/>' +
    //       this.series.name +
    //       ': ' +
    //       this.y +
    //       '<br/>'
    //     )
    //   },
    // },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y 
          },
        },
        enableMouseTracking: true,
      },
      series: {
        borderRadiusTopLeft: '30%',
        borderRadiusTopRight: '30%',
        dataLabels: {
          enabled: true,
          style:{fontSize:'14'},
          color:'black',  
      }
      },
    },
    defs: {
      patterns: [
        {
          id: 'custom-pattern',
          path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            stroke: 'white',
            strokeWidth: 1.5,
            fill: '#c71e83',
          },
        },
      ],
    },
    series: [
      {
        name: finalData[1].name,
        data: finalData[1].data,
        showInLegend: false,
        color: '#15A796',
        // enableMouseTracking: false,
        pointWidth: 15,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {},
          chartOptions: {
            legend: {
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'right',
              labelFormatter: function () {
                return this.name + ': ' + this.y 
              },
            },
          },
        },
      ],
    },
  }
}

export default getColumnChartOptions
