import React from "react";
import BreadcrumbItem from "./Item";

import styles from "./Breadcrumbs.module.scss";

const Breadcrumbs = ({ children = null }) => (
  <ol className={styles.base}>{children}</ol>
);

Breadcrumbs.Item = BreadcrumbItem;

export default Breadcrumbs;
