import React from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'
import Spinner from '../Loader/Spinner'
import getColumnChartOptions from './getColumnChartOptions'
import styles from './ColumnChartDisplay.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const BarChartDisplay = ({ data, chartName ,pointLine,drillSeleted,setDrillSeleted,setDrillValueSeleted}) => {
  let categories = []
  let graph_race_data = []
  const finalData = []
  let titleText=""

  if (chartName==='cdcSVIColumnChart') {
    titleText="Amount"
    let res = data //JSON.parse(data)
    res.map((data_val) => {
      categories.push(
         data_val.race_display 
      )

      graph_race_data.push(data_val.data_value)
    })
    finalData.push({ name: 'category', data: categories })
    finalData.push({ name: 'Race', data: graph_race_data })
  }
  else{
    titleText="Population Proportion %"
    let res = data //JSON.parse(data)
  res.map((data_val) => {
    categories.push(
      '<b>' + data_val.race_display + '</b><br/>' + data_val.data_value 
    )

    graph_race_data.push(data_val.data_value<1?1:data_val.data_value)
  })
  finalData.push({ name: 'category', data: categories })
  finalData.push({ name: 'Race', data: graph_race_data })}

  //dummy data
  const chartOptions = getColumnChartOptions(finalData,pointLine,titleText,drillSeleted,setDrillSeleted,setDrillValueSeleted)

  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          // allowChartUpdate={false}
        
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default BarChartDisplay
