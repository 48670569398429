import React from 'react'
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs'
import { useGeoState } from '../GeoProvider'
const DefaultBreadcrumbs = ({
  geo_parent,
  geo_id,
  geo_type,
  dashboardType,
}) => {
  const {

    setRegionSelected,
  } = useGeoState()
  const history = useHistory();

  const handleClick = () => {
    setRegionSelected('zip3')
    if (geo_type==='ZIP3')
    {
      history.push(`/${dashboardType}Dashboard#zip3`)
    }
    else{
      history.push(`/${dashboardType}Dashboard`)
    }
  }


  return (
    <Breadcrumbs>
      {geo_type === 'NAT' && (
        <>
          <Breadcrumbs.Item
            key="My Dashboard"
            label="My Dashboard"
            href={'/Dashboard'}
          />
          <Breadcrumbs.Item className={'label'} key={'US'} label={'US'} last />
        </>
      )}
      {geo_type === 'STATE' && (
        <>
          <Breadcrumbs.Item
            key="My Dashboard"
            label="My Dashboard"
            href={'/Dashboard'}
            first
          />
          <Breadcrumbs.Item
            key="US"
            label="US"
            href={'/' + dashboardType + 'Dashboard'}
          />
          <Breadcrumbs.Item
            className={'label'}
            key={geo_id}
            label={geo_id}
            last
          />
        </>
      )}
      {(geo_type === 'MSA' || geo_type === 'COUNTY' || geo_type === 'ZIP3') && (
        <>
          <Breadcrumbs.Item
            key="My Dashboard"
            label="My Dashboard"
            href={'/Dashboard'}
            first
          />
          <Breadcrumbs.Item
            key="US"
            label="US"
            // href={'/' + dashboardType + 'Dashboard'}
            onClick={handleClick}
          />
          {geo_type !== 'ZIP3'   &&
           <Breadcrumbs.Item
            key={geo_parent}

            last={geo_type === 'ZIP3'}
            href={
              '/' + dashboardType + 'StateDashboard' + '/STATE/' + geo_parent
            }
            label={geo_parent}
          />
          }

{geo_type === 'ZIP3' &&  geo_id===undefined &&
           <Breadcrumbs.Item
            key={geo_parent}

            last={true}
            href={
              '/' + dashboardType + 'StateDashboard' + '/STATE/' + geo_parent
            }
            label={geo_parent}
          />
          }
        </>
      )}
    </Breadcrumbs>
  )
}

export default DefaultBreadcrumbs
