import React, { useState } from 'react'
import LinkButton from '../../../components/LinkButton'
import getVectorImage from '../../../utils/getVectorImage'
import styles from './SideTable.module.scss'

const Databind = ({ state, chartRef }) => {
  
  const handleMouseOver = (element) => {
    const chart = chartRef.current.chart

    if (chart) {
      const point = chart.series[2].points.find(
        (point) => point.geo_id === element.attributes['geo_id'].textContent  
      )
      point && chart.tooltip.refresh(point)
    }
  }

  const handleMouseOut = () => {
    const chart = chartRef.current.chart
    if (chart && chart.tooltip) {
      chart.tooltip.destroy()
    }
  }

  return (
    <div style={{ display: 'flex' }} className={styles.Layout}>
      <div
        style={{
          width: '50%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={state.statename}
      >
        <LinkButton
          onMouseOver={(e) => handleMouseOver(e.target)}
          onMouseOut={() => handleMouseOut()}
          className={styles.btn}
          // onClick={() => {
          //   history.push(`/dashboard/Rsvmap#state-${state.identifier}`)
          // }}
          data-title={state.statename}
          title={state.statename}
          geo_id={state.geo_id}
        >
          {state.statename.length > 15
            ? state.statename.substring(0, 15) + '...'
            : state.statename}
        </LinkButton>
      </div>
      <div className={styles.columnRate}>{state.datavalue}</div>
      <div className={styles.columnDays}>
        <span> {getVectorImage(state.istrue)} </span>
        <span
          className={
            state.istrue ? styles.percentageRed : styles.percentageGreen
          }
        >
          {' '}
          {state.percentage}%
        </span>
      </div>
    </div>
  )
}

const SideTable = ({ stateData, chartRef }) => { 

  let noDataFlag = stateData.length === 0 ? true : false
 
  const [getFilter, setfilter] = useState('down')
  const sortBy = (action) => {
    if (action === 'up') {
      setfilter('down')
      //stateData.sort((a, b) => b.datavalue - a.datavalue)
    } else if (action === 'down') {
      setfilter('up')
      //stateData.sort((a, b) => a.datavalue - b.datavalue)
    }
  }
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: '16px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '90%',
              }}
            >
              <span>
                <h5
                  style={{
                    // width: '209px',
                    height: '24px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '20.25px',
                    lineHeight: '24px',
                    color: '#002677',
                  }}
                >
                 State & Zip 3 Incidence
                </h5>
              </span>
            </div>
          </div>
          <div
            style={{
              fontSize: '12px',
              display: 'flex',
              lineHeight: '18px',
              padding: '1rem 0rem 0',
            }}
          >
            <span>
              {' '}
              <svg
                height="20"
                width="20"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                focusable="false"
                aria-hidden="true"
              >
                <path d="M11 7h2v2h-2zm1 10c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm0-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
              </svg>
              <button data-react-popover-target="true" type="button"></button>
            </span>{' '}
            &nbsp;&nbsp;
            {noDataFlag ? (
              <div
                style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}
                className={styles.sideTableHeader}
              >
                {' '}
                {'geo_name'} MSA and CDO info is not available right now.
              </div>
            ) : (
              <div
                style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}
                className={styles.sideTableHeader}
              >
                {' '}
                Flu Rates represent the incidence of Flu within the population in
              a given week.
              </div>
            )}
          
          </div>
        </div>
        <div
          style={{
            padding: '0rem 0rem',
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderTop: '1px solid #CBCCCD',
            borderBottom: '2px solid #002677',
            marginTop: '5px',
          }}
        >
          <div style={{ display: 'inline-flex', width: '48%' }}>
            <h4>Zip3</h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '27%',
              justifyContent: 'flex-middle',
              cursor: 'pointer',
            }}
          >
            <h4 onClick={() => sortBy(getFilter)}>
              Rate {getVectorImage(getFilter)}
            </h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '25%',
              justifyContent: 'flex-middle',
            }}
          >
            <h4>7-Day</h4>
          </div>
        </div>
      </div>

      <div className={styles.scroll}>       
      
            <Databind
              state={stateData}
              key={stateData.geo_id}
              chartRef={chartRef}
            />
         
        
      </div>
    </div>
  )
}

export default SideTable
