import React, { useState, useEffect } from 'react'
import styles from './Common.module.scss'
import closeIcon from '../../assets/images/icon/close_24dp.png'
import Modal from '../../components/ModalPopupProfile'
import styles1 from '../../pages/ServiceNow/ListIncidentRequest.module.scss'
import 'react-toastify/dist/ReactToastify.css';
import {  useGeoFetch } from '../../components/GeoProvider'
import { useAuth } from '../../components/AuthProvider'
import Priority from './priority'
import Issuetype from './issuetype'

const ReadMore = ({ children }) => { 
  const text = children; 
  const [isReadMore, setIsReadMore] = useState(true); 
  const toggleReadMore = () => { 
    setIsReadMore(!isReadMore); 
  }; 
  return ( 
    <p className={styles1.text}> 
      {isReadMore ? text.slice(0, 50) : text} 
      <span onClick={toggleReadMore} className={styles1.readorhide}> 
        {isReadMore ? "...see more" : " see less"} 
      </span> 
    </p> 
  ); 
};

const ListIncidentRequest = ({ istrue, sendDataToParent, data }) => {

  const [{ isAuthenticated, user, authMode }] = useAuth()

  const [getIncidents, setIncidents] = useState()
  const [getCount, setCount] = useState()
  const { data: incidents, error: stateDataError } = useGeoFetch(
    "",
    "",
    'getincident',
    `?&email=${user.email}`
  )

  useEffect(() => {
    if (incidents !== null && incidents !== undefined) {
      setIncidents(incidents)    
      setCount( incidents.length )
    }
  }, [incidents])


  return getIncidents ?(
    <>
      <Modal open={istrue} >
        <div style={{ display: 'flex' }}>
          <div style={{ width: '19%' }}>
            <span className={styles.captionFormat}>{getCount} Reported Issues</span>
          </div>
          <div>
            <img
              className={styles.closePopup}
              src={closeIcon}
              alt="Close"
              onClick={sendDataToParent}
            ></img>
          </div>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div className={styles1.modal}>
            <div className={styles1.base}>
              <div className={styles1.maindivFormat}>

                <div style={{ marginTop: '0px', width: '70%' }}>

                  {getIncidents.map(function (object, i) {
                    return <><div className={styles1.container} style={{  marginTop: '20px', borderRadius: '4px' }} >
                      <div className={styles1.inc}>
                        Incident: {object.incident_number}
                      </div>
                      <div className={styles1.type}>{Priority.find(a => a.value === object.priority).text} {Issuetype.find(a => a.value === object.issue_type).text}</div>
                      <div className={styles1.incdesc}><ReadMore>{object.discription}</ReadMore></div>
                      <div className={styles1.status}>Pending...</div>
                    </div>
                      <hr style={{ width: '29%', background: '#CBCCCD', height: '1px' }} />
                    </>
                  })}

                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  ):(<></>)
}
export default ListIncidentRequest
