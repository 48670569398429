import styles from './GenderAgeDetails.module.scss'
import CountingMethod from './../../utils/getCountingMethod'
const GraphCountTable = ({ data,multiColumn }) => {
  
  return multiColumn===true? 
  (<td>{CountingMethod(data[0],'fixed')} | {CountingMethod(data[1],'fixed')}</td>):
  ( <td>{CountingMethod(data[0],'fixed')}</td>)
}
const getGenderAgeDetails = ({ data,multiColumn }) => {
  let arr_male=[]
  let arr_female=[]
  if(multiColumn)
  {
    for(let i=0;i<data[0].length;i++)
    {
      let mar=[],far=[]
      mar.push(data[0][i].male_Count)
      far.push(data[0][i].female_Count)
      let match_flag=false
      for(let j=0;j<data[1].length;j++)
      {
        if(data[0][i].age_bucket_id===data[1][j].age_bucket_id)
        {
          match_flag=true
          mar.push(data[1][j].male_Count)
          far.push(data[1][j].female_Count)
        }
      }
      if(!match_flag)
      { mar.push(0)
        far.push(0)
      }
      arr_male.push(mar)
      arr_female.push(far)
    }
  }
  else{
    for(let i=0;i<data[0].length;i++)
    {
      let mar=[],far=[]
      mar.push(data[0][i].male_Count)
      arr_male.push(mar)

      far.push(data[0][i].female_Count)
      arr_female.push(far)
    }
  }
  if (data !== null && data !== undefined) {
    return (
      <div className={styles.maindivFormat}>
      <table>
        <thead>
          <tr>
            <th>Gender</th>
            {data[0].map((data_val) => {
              return <th>{data_val.age_bucket_display}</th>}
              )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Male</td>
            {arr_male.map((data_val)=>{
              return <GraphCountTable data={data_val} isMale={true} multiColumn={multiColumn} />}
              )}
          </tr>
          <tr>
            <td>Female</td>
            {arr_female.map((data_val)=>{
              return <GraphCountTable data={data_val} isMale={true} multiColumn={multiColumn} />}
              )}
          </tr>
        </tbody>
      </table>
      </div>
    )
  } else {
    return <div></div>
  }
}

export default getGenderAgeDetails
