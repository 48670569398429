import React from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useAuth } from '../AuthProvider'
import Header from '../Header'
import Menu from '../Menu'
import Navigation from '../Navigation'
import Footer from '../Footer'
import ErrorBoundary from '../ErrorBoundary'
import ErrorMessage from './ErrorMessage'

import {
  APP_NAME,
  ENHANCEMENT_REQUESTS,
  HUB_CONNECT,
  SUPPORT_EMAIL,
} from '../../utils/constants'
import { RouteContext } from '../RouteProvider'
import Badge from '../Badge'

const Layout = ({ children = null }) => {
  const history = useHistory()
  const location = useLocation()
  const [{ isAuthenticated, user }] = useAuth()
  const { rootRoute, routes } = React.useContext(RouteContext)

  const isValidPath = routes.some(
    (route) => !route.isHidden && location.pathname.startsWith(route.path)
  )

    
  return (
    <React.Fragment>
      <Header
        appName={APP_NAME}
        logoUrl="/"     
      >        
      </Header>
      {isAuthenticated && rootRoute && (
        <Navigation>
          {rootRoute.routes
            .filter(
              (route) =>
                !route.isHidden &&
                route.authorizedRoles.includes(user.role) &&
                route.envAccess.includes(process.env.REACT_APP_ENV)
            )
            .map((route) => (
              <Navigation.Item
                key={route.path}
                active={isValidPath && location.pathname.startsWith(route.path)}
                label={route.label}
                new={route.new}
                render={() => <Link to={route.path}>{route.label}</Link>}
              >
                {route.routes && route.routes.some((route) => !route.isHidden) && (
                  <Menu>
                    {route.routes
                      .filter(
                        (route) =>
                          !route.isHidden &&
                          route.authorizedRoles.includes(user.role)
                      )
                      .map((route) => (
                        <>
                          {'externalLink' in route ? (
                            <Menu.Item
                              key={route.path}
                              render={() => (
                                <a
                                  href={route.externalLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span style={{ whiteSpace: 'pre' }}>
                                    {route.label}
                                  </span>
                                  {route.new && <Badge text={''} />}
                                </a>
                              )}
                            />
                          ) : (
                            <Menu.Item
                              key={route.path}
                              render={() => (
                                <Link to={route.path}>
                                  {route.label}
                                  {route.new && <Badge text={''} />}
                                </Link>
                              )}
                            />
                          )}
                        </>
                      ))}
                  </Menu>
                )}
              </Navigation.Item>
            ))}
        </Navigation>
      )}
      <main>
        <ErrorBoundary displayError={<ErrorMessage />} locationPath={location}>
          {children}
        </ErrorBoundary>
      </main>
      <Footer  logoUrl="/" appName={APP_NAME}>        
      </Footer>
    </React.Fragment>
  )
}

export default Layout
