import L from 'leaflet';
import LocationIcon from '../../assets/images/provider_location.svg';
export const ProviderLocationIcon =  L.icon({
    iconUrl: LocationIcon,
    iconRetinaUrl: LocationIcon,
    popupAnchor: [-3, -76],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(25, 30),
  })