// TO-DO:
// This file is temporary fix as interceptor is not adding headers in local for useGeoFetch()
import useRequest from '../../utils/useRequest'
import { useAuthState } from '../AuthProvider'

function useUHCGeoFetch(
  startDate,
  endDate,
  entity,
  location,
  pathparam = '',
  id = ''
) {
  const { user } = useAuthState()
  const getHeaders = () => {
    if (
      process.env.REACT_APP_DEVELOPMENT_MODE === undefined ||
      process.env.REACT_APP_DEVELOPMENT_MODE === 'true'
    ) {
      return {
        'Content-Type': 'application/json',
        authenticatedUser: user,
      }
    } else {
      return {
        'Content-Type': 'application/json',
      }
    }
  }
  const { data, error } = useRequest({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    url: `/${entity}/${location}/orgs/${id}`,
    headers: getHeaders(),
    params:
      location === 'activecbsa'
        ? {}
        : {
            startDate: startDate,
            endDate: endDate,
          },
    withCredentials: true,
  })

  return { data, error }
}

export default useUHCGeoFetch
