function mapEvent(mapType, options, history, dashboardType) {  
  let stateDash = dashboardType + 'StateDashboard'
  let msaDash = dashboardType + 'MSADashboard'
  let zip3Dash = dashboardType + 'ZIP3Dashboard'
  let hash = undefined
  if (mapType !== undefined && mapType === 'RSVMAP' && 'state' in options) {
    hash = `/${stateDash}/STATE/${options.state}`
    history.push(hash)
  } else if (
    mapType === 'RSVMAPMSA' &&
    'state' in options &&
    options.geo_parent !== 'US'
  ) {
    hash = `/${msaDash}/${options.geo_parent}/MSA/${options.state}`
    history.push(hash)
  } else if (
    mapType === 'RSVMAPMSA' &&
    'state' in options &&
    options.geo_parent === 'US'
  ) {
    window.location.href = `/${stateDash}/STATE/${options.state}`
  } else if (
    mapType === 'RSVMAPCDO' &&
    'state' in options &&
    options.geo_parent !== 'US'
  ) {
    window.location.href = `/${msaDash}/${options.geo_parent}/MSA/${options.state}`
  } else if (
    mapType === 'RSVMAPCDO' &&
    'state' in options &&
    options.geo_parent === 'US'
  ) {
    window.location.href = `/${stateDash}/STATE/${options.state}`
  }
  else if (
    mapType === 'ZIP3MAP' &&
    'state' in options && options.forecast_level<=0
  ) {    
  }
  else if (
    mapType === 'ZIP3MAP' &&
    'state' in options && options.geo_parent !== 'US'
  ) {
    window.location.href = `/${zip3Dash}/${options.geo_parent}/ZIP3/${options.state}`
  }
  else if (
    mapType === 'ZIP3MAP' &&
    'state' in options
  ) {
    window.location.href = `/FLUZIP3Dashboard/${options.state}/ZIP3`
  }
  
}

export default mapEvent
