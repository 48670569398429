import { useState, useEffect } from 'react'

export const useStatesJson = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        console.log(data)
        setData(data)
      })
      .catch(function (err) {
        console.log(err, 'Failed to import states JSON')
      })
  }, [])

  return { data, error, loading }
}
