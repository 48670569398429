import React from 'react'
import rightIcon from './../../assets/images/chevron-right.png'
import styles from './Item.module.scss'

const Item = ({
  href,
  label,
  onClick,
  render,
  icon,
  last: isLast,
  first:isFirst,
  ...rest
}) => {
  const className = `${styles.base} ${rest.className ? rest.className : ''}`
  return (
    <li className={className}  style={{width: isFirst?'130px':''}}>
      {typeof render === 'function' ? (
        render()
      ) : isLast ? (
        label
      ) : (
        <a href={href} onClick={onClick}>
          {icon} {label}
        </a>
      )}
      {!isLast && <img src={rightIcon} className={styles.iconImage} />}
    </li>
  )
}

export default Item
