import React from 'react'

import Spinner from '../Loader/Spinner'
import BarChartDisplay from './ColumnChartDisplay'

const ColumnChartVul = ({ data, chartName,renderType,multiColumn,locationName ,searchLocationText,displayDate}) => {
  return data ? (
    <BarChartDisplay data={data} 
    chartName={chartName} renderType={renderType} 
    multiColumn={multiColumn} 
    locationName={locationName}
    searchLocationText={searchLocationText}
    displayDate={displayDate}
    />
  ) : (
    <Spinner />
  )
}

export default ColumnChartVul
