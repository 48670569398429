import React from "react";

import styles from "./Link.module.scss";

const Link = ({
  children = null,
  href,
  label,
  onClick,
  rel,
  render,
  target,
  ...rest
}) => {
  const className = `${styles.base} ${rest.className ? rest.className : ""}`;
  return (
    <li className={className}>
      <span>
        {typeof render === "function" ? (
          render()
        ) : (
          <a href={href} onClick={onClick} rel={rel} target={target}>
            {label}
          </a>
        )}
      </span>
    </li>
  );
};

export default Link;
