import Highcharts from 'highcharts'
import HC_patternFill from 'highcharts-pattern-fill'
import HC_rounded from 'highcharts-rounded-corners'
import imgs from  '../../assets/images/icon/image-line-png.png'
import CountingMethod from '../../utils/getCountingMethod'
HC_rounded(Highcharts)
HC_patternFill(Highcharts)
function getSplitByTwoCharacter(string,char1,char2)
{return string.slice(
  string.indexOf(char1) + 1,
  string.lastIndexOf(char2),
)}  
function getColumnChartOptions(finalData,renderType,chartName,multiColumn,locationName,searchLocationText,displayDate) {
  var titleText=""
  switch (chartName) {
    case 'estimateChart':
      titleText="% Estimates"
      break
    case 'raceEthnicity':
      titleText="Population Proportion %"
      break
    default:
      titleText=""
      break
  }
  
  // (renderType==="dashboard"?'Incidence':'Population Proportion %')
 
  return {
    chart: {
      type: 'column',
      // height: 300,
      marginTop:20
      
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'bottom',
      margin: 50,
      floating: true,
    },
    yAxis: {
      title: {
        text: chartName==="cdcSVIColumnChart2"?'Amount':titleText,
        // renderType==="dashboard"?'Incidence':'Population Proportion %',
        style: {
          color: Highcharts.getOptions().colors[1],
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          fontSize: '16px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          return this.total + '%'
        },
      },
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: true,
    },
    xAxis: {
      categories: finalData[0].data,
      // crosshair: true,
      crosshair:{color:'#E5E5E6'},
      labels: {
        style:{
          fontSize: '14px',
          fontWeight: '700',
          color: '#4B4D4F',
        }
      },
      plotLines:chartName==='estimateChart'? [
        {
        color: 'transparent',
        width: 2,
        value: .1,
        zIndex: 5,
      
        label: {
           x: -30,
          rotation: 0,
          text: 'Socioeconomic Status' ,
          style: { color: '#4B4D4F', fontSize: '12px' },
         
        },
      
    },
    {
      color: '#CBCCCD',
      width: 2,
      value: 4.5,
      zIndex: 5,
      label: {
        //  x: -300,
        rotation: 0,
        text: 'Household Characteristics' ,
        style: { color: '#4B4D4F', fontSize: '12px' },
      },
  },
  {
    color: '#CBCCCD',
    width: 2,
    value: 9.5,
    zIndex: 5,
    label: {
     
      rotation: 0,
      text: 'Racial &...' ,
      style: { color: '#4B4D4F', fontSize: '12px' },
     
    },
    events: {
      mouseover: function() {
       var chart = this.axis.chart;
        var chart = this.axis.chart;
        chart.customTooltip = chart.renderer.label('Racial & Ethnic Minority Status', 850, -5).attr({
          fill: 'white',
          style:"font-size:14px;font-color:#CBCCCD"
        }).add()
      },
      mouseout: function() {
        var chart = this.axis.chart;
        if (chart.customTooltip) {
          chart.customTooltip.destroy()
        }
      }
  }
  },
  {
   color: '#CBCCCD',
  width: 2,
  value: 10.5,
  zIndex: 5,
  label: {
    // x: -300,
    rotation: 0,
    text: 'Household Characteristics' ,
    style: { color: '#4B4D4F', fontSize: '12px' },
  },
  }]:[],
 
    },
    tooltip: {
      outside:true,
      enabled: true, 
      borderRadius: 10,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      style: {
        padding: 0,
      },
      valuesuffix: '',
      formatter:multiColumn? function () {
       if(chartName==='estimateChart')
       {
        
        return (
          `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
          border-radius: 4px; font-family: 'Optum Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          ">
          
            <div >${this.key.split('<br/>')[0]}</div>
            <div style='min-width:100%;max-width:100%;text-wrap: wrap;font-weight: 400;font-size: 12px;'>
            ${this.point.data_desc1} </div>
            <div style='display:flex;gap:20px;padding-top:10px'>
            <div style='border-right:1px solid grey;padding-right:20px'> 
            <div style="color:#002677">${locationName} (MOE)</div>
            <div style='margin-top:10px' >${CountingMethod(this.point.data_value1,'fixed').toFixed(2)} (&#177;${CountingMethod(this.point.data_moe1,'fixed').toFixed(2)})</div>
            </div>
            <div style=''> 
            <div style="color:#002677">${searchLocationText} (MOE)</div>
            <div style='margin-top:10px' >${CountingMethod(this.point.data_value2,'fixed').toFixed(2)} (&#177;${CountingMethod(this.point.data_moe2,'fixed').toFixed(2)})</div>
            </div>
            </div>
          
          </div>`
        )
       }
       else if(chartName==="raceEthnicity")
       {
        
        return (
        `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
        border-radius: 4px; font-family: 'Optum Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        ">
      
          <div >${this.key.split('<br/>')[0]}</div>
          <div style='display:flex;gap:20px;padding-top:10px'>
          <div style='border-right:1px solid grey;padding-right:20px'> 
          <div style="color:#002677">${locationName} (MOE)</div>
          <div style='margin-top:10px' >${CountingMethod(this.point.data_value1,'fixed')} (&#177;${this.point.data_moe1})</div>
         
          </div>
          <div style=''> 
          <div style="color:#002677">${searchLocationText} (MOE)</div>
          <div style='margin-top:10px' >${CountingMethod(this.point.data_value2,'fixed')} (&#177;${this.point.data_moe2})</div>
          
          </div>
          </div>
        
        </div>`
      )}
       else{
        return (
          `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
          border-radius: 4px; font-family: 'Optum Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          ">
          <div >${displayDate}</div>
            <div style='padding-top:10px'>${this.key.split('<br/>')[0]}</div>
            <div style='display:flex;gap:20px;padding-top:10px'>
            <div style='border-right:1px solid grey;padding-right:20px'> 
            <div style="color:#002677">${locationName}</div>
            <div style='margin-top:10px' >${CountingMethod(getSplitByTwoCharacter(this.key,'^','^').split('|')[0],'fixed')}</div>
            </div>
            <div style=''> 
            <div style="color:#002677">${searchLocationText}</div>
            <div style='margin-top:10px' >${CountingMethod(getSplitByTwoCharacter(this.key,'^','^').split('|')[1],'fixed')}</div>
            </div>
            </div>
          
          </div>`
        )
       }
        
      }:function () {
        if(chartName==="raceEthnicity")
        {
          
          return (
          `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
          border-radius: 4px; font-family: 'Optum Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          ">
         
            <div >${this.key.split('<br/>')[0]} </div>
            <div style='display:flex;gap:20px;padding-top:10px'>
            <div style='padding-right:20px'> 
            <div style="color:#002677">${locationName} (MOE)</div>
            <div style='margin-top:10px' >${CountingMethod(this.y,'fixed') } (&#177;${CountingMethod(this.point.data_moe,'fixed')})</div>
            </div>
           
            </div>
          
          </div>`
        )}
        else if(chartName==="estimateChart")
        {
          
          return (
            `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
            border-radius: 4px; font-family: 'Optum Sans', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;">
              <div >${this.key.split('<br/>')[0]}</div>
              <div style='min-width:250px;max-width:250px;text-wrap: wrap;font-weight:400;font-size: 12px;'>
              ${this.point.data_desc} </div>
              <div style='display:flex;gap:20px;padding-top:10px'>
              <div style='padding-right:20px'> 
              <div style="color:#002677">${locationName} (MOE)</div>
              <div style='margin-top:10px' >${CountingMethod(this.y,'fixed').toFixed(2)} (&#177;${CountingMethod(this.point.data_moe,'fixed').toFixed(2)})</div>
              </div>
             
              </div>
            
            </div>`
          )
        }
        else{
          
          return (
          `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
          border-radius: 4px; font-family: 'Optum Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          ">
          <div >${displayDate}</div>
            <div  style='padding-top:10px'>${this.key.split('<br/>')[0]}</div>
            <div style='display:flex;gap:20px;padding-top:10px'>
            <div style='padding-right:20px'> 
            <div style="color:#002677">${locationName}</div>
            <div style='margin-top:10px' >${CountingMethod(this.y,'fixed')}</div>
            </div>
           
            </div>
          
          </div>`
        )}
        
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false,
          formatter: function () {
            return this.y 
          },
          fontSize: '14px',
          fontWeight: '700',
          color: '#4B4D4F',
        },
        
        enableMouseTracking: true,
       
      },
      
      series: {
        borderRadiusTopLeft: '20%',
        borderRadiusTopRight: '20%',
        pointWidth:24,
        dataLabels: {
          enabled: false,
          style:{fontSize:'14px',fontWeight:'400'}
          
         
      }
      },
    },
  
    defs: {
      patterns: [
        {
          id: 'custom-pattern',
          path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            stroke: 'white',
            strokeWidth: 1.5,
            fill: '#c71e83',
          },
        },
      ],
    },
    series:multiColumn? [
      {
        name: finalData[1].name,
        data: finalData[1].data,
        showInLegend: false,
        color: '#15A796',
        enableMouseTracking: true,
        
      },
      {
        name: finalData[2].name,
        data: finalData[2].data,
        showInLegend: false,
         type:'scatter',
          marker: {
          symbol:'url('+imgs+')'
        },

        borderColor:'#7D7F81',
        enableMouseTracking: true,
      },
    ]:[
      {
        name: finalData[1].name,
        data: finalData[1].data,
        showInLegend: false,
       
        color: '#15A796',
        enableMouseTracking: true,
      }
      
    ],
    responsive: {
      rules: [
        {
          condition: {            
          },
          chartOptions: {
            legend: {
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'right',
              labelFormatter: function () {
                return this.name + ': ' + this.y + '%'
              },
            },
          },
        },
      ],
    },
  }
}

export default getColumnChartOptions
