import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Tabs from '../../components/Tabs'
import SeasonsChart from './SeasonsChart'
import seasonweeks from './seasonChartWeeks.json'
import offseasonweeks from './OffSeasonChartWeeks.json'
import fullweeks from './FullYearSeasonChartWeeks.json'
import { useGeoState } from '../../components/GeoProvider'
import Spinner from '../../components/Loader/Spinner'
const ModelTabs = ({
  data,
  dataForecast,
  identifier,
  selectedOptions,
  
}) => {
  const { displayDate  } = useGeoState() 
  const tabIndices = React.useMemo(
    () => ['#fullyear', '#season', '#offseason'],
    []
  )
  const [selectedTab, setSelectedTab] = React.useState(0)

  const history = useHistory()
  const location = useLocation()

  const onLoadTabs = React.useCallback(() => {
    for (const key in tabIndices) {
      if (location.hash === tabIndices[key]) setSelectedTab(+key)
    }
  }, [location.hash, tabIndices])

  const tabsChangeHandler = (index) => {
    setSelectedTab(index)
    history.push({ hash: tabIndices[index] })
  }

  React.useEffect(() => {
    onLoadTabs()
  }, [onLoadTabs])

  // var dataobject = data[displayDate]
  // var mathIdentifier = Object.keys(dataobject)[0] === identifier

  return (
      <Tabs selectedIndex={selectedTab} onChange={tabsChangeHandler}>
        <Tabs.Panel label="Full Year">
          {tabIndices[selectedTab] === '#fullyear' ? (
            <SeasonsChart
              data={data}
              identifier={identifier}
              selectedOptions={selectedOptions}
              dataForecast={dataForecast}
              weeks={fullweeks}
            />
          ) : (
            <Spinner />
          )}
        </Tabs.Panel>
        <Tabs.Panel label="Season">
          {tabIndices[selectedTab] === '#season' ? (
            //  <div><h1>Season</h1></div>
            <SeasonsChart
              data={data}
              identifier={identifier}
              selectedOptions={selectedOptions}
              dataForecast={dataForecast}
              weeks={seasonweeks}
            />
          ) : (
            <Spinner />
          )}
        </Tabs.Panel>
        <Tabs.Panel label="Off Season">
          {tabIndices[selectedTab] === '#offseason' ? (
            <SeasonsChart
              data={data}
              identifier={identifier}
              selectedOptions={selectedOptions}
              dataForecast={dataForecast}
              weeks={offseasonweeks}
            />
          ) : (
            <Spinner />
          )}
        </Tabs.Panel>
      </Tabs>
    
  )
}

export default ModelTabs
