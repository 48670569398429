import { useState, useEffect } from 'react'

export const useCBSAJson = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setData(data)
      })
      .catch(function (err) {
        console.log(err, ' Failed to fetch CBSA data ')
      })
  }, [])

  return { data, error, loading }
}
