import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import Spinner from '../../components/Loader/Spinner'
import EmptyMap from '../../components/Map/emptyMap'
import Map from '../../components/Map'
import toolTipFormatter from './toolTipFormatter'
import VulMapLegend from './vulnerabilityMapLegendHorizontal'
import SideTable from './SideTable'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
import DisplayDateProvider from '../../components/DisplayDateProvider/DisplayDateProvider'
import HorizontalColumnBarChart from '../../components/HorizontalColumnBarChart'
import closeIcon from '../../assets/images/icon/close_24dp.png'
const DashboardMap = ({ zoomLocation }) => {
  const { user } = useAuthState()
  const chartRef = useRef(null)
  const location = useLocation()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [metros, setMetros] = useState()
  const [getStats, setStats] = useState()
  const [getMSAStats, setMSAStats] = useState()
  const [getMedicalFactorData, setMedicalFactorData] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getStateJson, setStateJson] = useState()
  const [getCBSAJsonData, setCBSAJsonData] = useState()
  const [activeCbsa, setActiveCbsa] = useState()
  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
    locationName,
    isChecked,
    displayDate,
    setDisplayDate,
    setIsChecked,
  } = useGeoState()


const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')
  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }

  const { data: medData, error: medDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'vulnerability',
    'getmedicalfactor'
  )

  useEffect(() => {
    if (medData !== null && medData !== undefined) {
      if (medData.response_code === 200) {
        setMedicalFactorData(medData.response_data)
      } else {
        setErrorCode(medData.response_code)
        setErrorMessage(medData.response_message)
        setErrorSubject(medData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [medData])

  const { data: statesData, error: MapError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'vulnerability',
    'getdashmap/STATE?geo_parent=US'
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Dashboard access success',
          user: user,
        })
        var datacount= Object.keys(statesData.response_data).length-1        
        setDisplayDate(Object.keys(statesData.response_data)[datacount])
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  const { data: msaData, error: MapmsaError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'vulnerability',
    'getdashmap/MSA?geo_parent='
  )

  useEffect(() => {
    if (msaData !== null && msaData !== undefined) {
      if (msaData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Dashboard access success',
          user: user,
        })
        setMetros(msaData.response_data)
      } else {
        setErrorCode(msaData.response_code)
        setErrorMessage(msaData.response_message)
        setErrorSubject(msaData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaData])



  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'vulnerability',
    'getsidetable/STATE?geo_parent=US'
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {
        setStats(stats.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [stats])

  const { data: msaStats, error: msaDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'vulnerability',
    'getsidetable/MSA?geo_parent='
  )

  useEffect(() => {
    if (msaStats !== null && msaStats !== undefined) {
      if (msaStats.response_code === 200) {
        setMSAStats(msaStats.response_data)
      } else {
        setErrorCode(msaStats.response_code)
        setErrorMessage(msaStats.response_message)
        setErrorSubject(msaStats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaStats])

  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'vulnerability',
    'getactivelocations?geo_type=MSA'
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      if (activeCbsasData.response_code === 200) {
        setActiveCbsa(activeCbsasData.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [activeCbsasData])

  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setCBSAJsonData(data)
      })
      .catch(function (err) {
        console.log(err, ' error file us_hrr_values ')
      })
  }, [1])

  if ( MapError ||msaDataError||MapmsaError|| errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }
  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'state' && (
            <>
              {getStats !== undefined ? (
                <SideTable stateData={getStats} chartRef={chartRef} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
           {regionSelected === 'metro' && (
            <>
              {getMSAStats !== undefined ? (
                <SideTable stateData={getMSAStats} chartRef={chartRef} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'state' && (
          <>
            {getMapStates !== undefined && getStateJson ? (
              <>
                <div className={`${styles.providerHeader}`}>
               <DisplayDateProvider
                textShow={'Vulnerability Index for '}
                        showWeek={false}
                        lastweekEndDate={displayDate}
                      />
                  
               </div>
               {isChecked&&
               <div className={styles.multifactorGraph}>
               <div className={styles.multifactorGraphHeader}>
           
          <div className={styles.captionFormat} style={{ width: '98%' }}>
            <span >Factors Impacting Outcome</span>
          </div>
          <div >
            <img
              className={styles.closePopup}
              src={closeIcon}
              alt="Close"
              onClick={() => setIsChecked(!isChecked)}
            ></img>
          </div>
      
               {/* <span className={styles.captionFormat}>
               Factors Impacting Outcome
            <p className={styles.subCaptionFormat}>
            <a href='#' target='_blank'>Link</a> ((Apr 1, 2021 - Mar 31, 2022))
            </p>
          </span> */}
          
                </div>
              
                <div>
          {getMedicalFactorData !== undefined && locationName!==undefined ? (
            <>
             <HorizontalColumnBarChart data={getMedicalFactorData} chartName="columnBarChart" locationName={locationName}/>
            </>
          ) : null}
                </div>
               </div>}
               <div className={styles.legendDiv}>
                <VulMapLegend />
                </div>
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={null}
                  statesData={getMapStates}
                  cbsaData={null}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="RSVMAP"
                  enableButtons={true}
                  chartRef={chartRef}
                  mapDataType={0}
                  stateJson={getStateJson}
                  dashboardType="Vulnerability"
                />

               
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
         {regionSelected === 'metro' && (
          <>
            {metros !== undefined && getCBSAJsonData ? (
              <>
                <div className={`${styles.providerHeader}`}>
               <DisplayDateProvider
                textShow={'Vulnerability Index for '}
                        showWeek={false}
                        lastweekEndDate={displayDate}
                      />
                  
               </div>
               {isChecked&&
               <div className={styles.multifactorGraph}>
               <div className={styles.multifactorGraphHeader}>
               
               <span className={styles.captionFormat}>
               Factors Impacting Outcome
            <p className={styles.subCaptionFormat}>
            <a href='#' target='_blank'>Link</a> ((Apr 1, 2021 - Mar 31, 2022))
            </p>
          </span>
                </div>
              
                <div>
          {getMedicalFactorData !== undefined && locationName!==undefined ? (
            <>
             <HorizontalColumnBarChart data={getMedicalFactorData} chartName="columnBarChart" locationName={locationName}/>
            </>
          ) : null}
                </div>
               </div>}
               <div className={styles.legendDiv}>
                <VulMapLegend />
                </div>
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={activeCbsa}
                  statesData={getMapStates}
                  cbsaData={metros}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="RSVMAPMSA"
                  enableButtons={true}
                  zoomLocation={zoomLocation}
                  chartRef={chartRef}
                  mapDataType={1}
                  //mapDataType={geo_type === 'STATE' ? 0 : 1}
                  JsonData={getCBSAJsonData}
                  stateJson={getStateJson}
                 
                  dashboardType="Vulnerability"
                />

               
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardMap
