
const MapBordercolor = (level,type) => {  
  if(type.toLowerCase()==='vulnerability')
  {
    let color='#FFFFFF'
    switch(level)
    {
      case 1:
        color='#2B9181'
        break
      case 2:
        color='#2D6EA3'
        break
      case 3:
        color='#0E456B'
        break
      case 4:
        color='#FFFFFF'
        break
      case 5:
        color='#FFFFFF'
        break
      default:
        color='#FFFFFF'
        break
    }
    return color
  }
  else 
  { 
    let color='#FFFFFF'
    switch(level)
    {
      case 1:
        color='#FFFFFF'
        break
      case 2:
        color='#0D5E4F'
        break
      case 3:
        color='#AD8D01'
        break
      case 4:
        color='#990000'
        break
      case 5:
        color='#FFFFFF'
        break
      default:
        color='#FFFFFF'
        break
    }
    return color
  }
  
}

export default MapBordercolor
