const dataSeasons = [
  {
    value: 12,
    label: '2024-25',
  },
  {
    value: 11,
    label: '2023-24',
  },
  {
    value: 10,
    label: '2022-23',
  },
  {
    value: 9,
    label: '2021-22',
  },
  {
    value: 8,
    label: '2020-21',
  },
  {
    value: 7,
    label: '2019-20',
  },
  {
    value: 6,
    label: '2018-19',
  },
  {
    value: 5,
    label: '2017-18',
  },
  // {
  //   value: 4,
  //   label: '2016-17',
  // },
  // {
  //   value: 3,
  //   label: '2015-16',
  // },
  // {
  //   value: 2,
  //   label: '2014-15',
  // },
]

export default dataSeasons
