export default function MapLegend(props) {
  return (
    <svg viewBox="0 0 480 25" height="25" width="480" align="center" {...props}>
    
    <rect
      x="0"
      y="0"
      width="16"
      height="16"
      fill="#422C88"
      stroke="#FFFFFF"
      strokeWidth="1px"
    ></rect>
    <text
      x="25"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Very High
    </text>
    <rect
      x="90"
      y="0"
      width="16"
      height="16"
      fill="#5163AE"
      stroke="#FFFFFF"
      strokeWidth="1px"
    ></rect>
    <text
      x="115"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      High
    </text>
    <rect
      x="150"
      y="0"
      width="16"
      height="16"
      fill="#7796CC"
      stroke="#0E456B"
      strokeWidth="1px"
    ></rect>
    <text
      x="175"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Medium
    </text>
    <rect
      x="230"
      y="0"
      width="16"
      height="16"
      fill="#AAC9E4"
      stroke="#2D6EA3"
      strokeWidth="1px"
    ></rect>
     <text
      x="255"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Low
    </text>
    <rect
      x="290"
      y="0"
      width="16"
      height="16"
      fill="#E5FDF7"
      stroke="#2B9181"
      strokeWidth="1px"
    ></rect>

    <text
      x="315"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      Minimal
    </text>
    <line x1="370" y1="0" x2="370" y2="18" stroke="#CBCCCD" strokeWidth={2} />
    <rect
      x="380"
      y="0"
      width="16"
      height="16"
      fill="#6D6F70"
      stroke="#6D6F70"
      strokeWidth="1px"
    ></rect>
    
    <text
      x="405"
      y="12"
      style={{
        width: '57px',
        height: '16px',
        fontWeight: 400,
        fontSize: '12.64px',
      }}
    >
      No Data
    </text>
    

  
  </svg>
  )
      
}