const Issuetype = [
  {
    value: "break_fix",
    text: "Break/Fix",
  },
  {
    value: "request_for_information",
    text: "Request for Information",
  },
  {
    value: "service_request",
    text: "Service Request",
  },
]

export default Issuetype