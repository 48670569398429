import moment from 'moment'
import { React, useState, useEffect } from 'react'

import { outbreak,forecastsStartDate,lastweekEndDate } from '../CovidDashboard/dates'
import styles from './TrendChart.module.scss'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import Select from 'react-select'
import CovidCaseTrend from './CovidCaseTrend'
import { useAuthState } from '../../components/AuthProvider'
import timePeriodSelectionOptions from './timePeriodOptions'
import Spinner from '../../components/Loader/Spinner'

const TrendChart = ({ actualStartDate,
  setActualStartDate,
  forecastStartDate,
  setForecastStartDate, time,geo_type, geo_id, data_type }) => {
  
  
  const { user } = useAuthState()
  const [seasonalPattern, setSeasonalPatternData] = useState()
  const [seasonalPatternForecast, setSeasonalPatternDataForecast] = useState()
  // const timeJson =  { value: '-1', label: 'Time Period: 1 Year',text_value:'Time Period: '}
  const timeJson = timePeriodSelectionOptions.filter(
    (value) => value.value === time
  )
  const timeJsonAppen={value:timeJson[0].value,label:timeJson[0].text_value+timeJson[0].label}
  const [gettimeOption, selectedtimeOptions] = useState(timeJsonAppen)
  const [errorCode, setErrorCode] = useState('401')
  const [errorMessage, setErrorMessage] = useState(
    'Unable to process the request.'
  )
  const [errorSubject, setErrorSubject] = useState(
    'Impotant Tips: clear browser cache, open dashboard in new window, check network connectivity.'
  )
  const [errorOccured, seterrorOccured] = useState(false)
  
  var seasonendpoint =
  data_type === 'RSV' ? 'seasonaldetails' :  data_type + 'seasonaldetails'
const { data: seasonalPatternData, error: seasonalPatternDataError } =
  useGeoFetch('', '', seasonendpoint, geo_type + '/' + geo_id)

useEffect(() => {
  if (seasonalPatternData !== null && seasonalPatternData !== undefined) {
    if (seasonalPatternData.response_code === 200) {
      ErrorLogs({
        message_type: 'Activity',
        error_details: 'SeasonalPattern success',
        user: user,
      })
   
      setSeasonalPatternData(seasonalPatternData.response_data)
    } else {
      setErrorCode(seasonalPatternData.response_code)
      setErrorMessage(seasonalPatternData.response_message)
      setErrorSubject(seasonalPatternData.response_subject)
      seterrorOccured(true)
    }
  }
}, [seasonalPatternData])

const { data: seasonalPatternForecastData, error: seasonalPatternDataForecastError } =
  useGeoFetch('', '', seasonendpoint, geo_type + '/' + geo_id+'?data_type=FORECAST')

useEffect(() => {
  if (seasonalPatternForecastData !== null && seasonalPatternForecastData !== undefined) {
    if (seasonalPatternForecastData.response_code === 200) {
      ErrorLogs({
        message_type: 'Activity',
        error_details: 'Seasonal Pattern Forecast Success',
        user: user,
      })
      setSeasonalPatternDataForecast(seasonalPatternForecastData.response_data)
    } else {
      setErrorCode(seasonalPatternForecastData.response_code)
      setErrorMessage(seasonalPatternForecastData.response_message)
      setErrorSubject(seasonalPatternForecastData.response_subject)
      seterrorOccured(true)
    }
  }
}, [seasonalPatternForecastData])


if (seasonalPatternDataError ||seasonalPatternDataForecastError || errorOccured) {
  return (
    <ErrorMessage
      errorCode={errorCode}
      errorMessage={''}
      errorSubject={errorSubject}
      errorDetails={errorMessage}
    />
  )
}
  

  const ddlChange = (e) => {
    selectedtimeOptions({ value: `${e.value}`, label: `${e.text_value} ${e.label}` })
    var value = e.value //index.target.value
    var type = 'month'
    setActualStartDate(moment().add(value, type))
    setForecastStartDate(moment().add(value,type))
    if (value === '-1') {
      type = 'year'
      setActualStartDate(moment().add(value, type))
      setForecastStartDate(forecastsStartDate)
    } else if (value=== '-0') {
      setActualStartDate(moment(outbreak))
      setForecastStartDate(forecastsStartDate)
    }
  }
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '4px',
      border: '1px solid #4B4D4F',
      boxSizing: 'border-box',
      cursor: 'pointer',
    }),
    option: (base, state) => ({
      ...base,
      color: '#1e2022',
      backgroundColor: 'white',
      padding: '.5rem 3rem .5rem .5rem',
      cursor: 'pointer',
      fontWeight: state.isSelected ? 'bold' : '',
    }),

 
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
 
  }
  return (
    <div className={styles.base}>
    <div className={styles.maindivFormat}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <span className={styles.captionFormat}>Incidence</span>
        </div>

        <div
          style={{
            minWidth: '20%',
            maxWidth: '40%',
            marginLeft: '70%',
            marginBottom: '24px',
            borderRadius: '4px',
            //border: '1px solid #4B4D4F',
            boxSizing: 'border-box',
            width: '100%',
            marginRight: '1.5%',
            marginTop: '1px',
            cursor: 'pointer',
          }}
        >
          
    <Select
     styles={colourStyles}
          options={timePeriodSelectionOptions}
           onChange={ddlChange}
          value={gettimeOption}
        
         />
        </div>
      </div>
      <div>
        
        {seasonalPattern !== undefined && seasonalPattern !== null && seasonalPatternForecast!==undefined && seasonalPatternForecast!==null ? (
       
         <CovidCaseTrend actualStartDate={actualStartDate} forecastStartDate={forecastStartDate} seasonalPattern={seasonalPattern} seasonalPatternForecast={seasonalPatternForecast} yAxisLabel loc={geo_id} currentWeekEnd={lastweekEndDate} />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  </div>

  )
}

export default TrendChart
