export default function dedupList(data) {
  return data.filter((row, index) => {
    const jsonRow = JSON.stringify(row)
    return (
      index ===
      data.findIndex((obj) => {
        return JSON.stringify(obj) === jsonRow
      })
    )
  })
}
