import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'
import getVectorImage from '../../utils/getVectorImage'
import styles from './SideTable.module.scss'
import toTitleCase from '../../utils/toTitleCase'
import { useGeoState } from '../../components/GeoProvider'
import statesMap from '../../utils/stateMap'
const Databind = ({ state, chartRef, stateRef ,zip3Ref, msaRef}) => {
  const { regionSelected, setGeoIdandGeoType } = useGeoState()
  const history = useHistory()
  const handleMouseOver = (element) => {
    
    const mapLayer   =  regionSelected === 'metro' ? msaRef : regionSelected === 'zip3' ? zip3Ref: stateRef
    mapLayer.current.eachLayer(function (layer) {      
      if (
        layer.feature.properties.geoid ===
        element.attributes['geo_id'].textContent
      ) {
        layer.openTooltip()
      }
    })
  }
  const handleMouseOut = () => {
    const mapLayer   =  regionSelected === 'metro' ? msaRef : regionSelected === 'zip3' ? zip3Ref: stateRef
    if (mapLayer.current !== null && mapLayer.current !== undefined) {
      mapLayer.current.eachLayer(function (layer) {
        layer.closeTooltip()
      })
    }
  }

  const handleClick = () => {

    regionSelected === 'metro'
      ? setGeoIdandGeoType({geoid:state.geo_id, geo_type:'MSA'})
      : regionSelected === 'zip3'
      ? setGeoIdandGeoType({geoid:state.geo_id, geo_type:'ZIP3'})
      :         
    setGeoIdandGeoType({geoid:state.geo_id, geo_type:'STATE'})
  }
  let statename=(regionSelected.toUpperCase()==='STATE'?state.statename+' ('+state.geo_id+')':state.statename)
  return (
    <div style={{ display: 'flex' }} className={styles.Layout}>
      <div
        style={{
          width: '80%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={statename}
      >
        <LinkButton
          onMouseOver={(e) => handleMouseOver(e.target)}
          onMouseOut={() => handleMouseOut()}
          className={styles.btn}
          onClick={handleClick}
          data-title={state.statename}
          title={statename}
          geo_id={state.geo_id}
        >
             {statename}
        </LinkButton>
      </div>
      <div className={styles.columnRate}>{state.datavalue}</div>
      <div className={styles.columnDays}>
        <span> {getVectorImage(state.istrue)} </span>
        <span
          className={
            state.istrue ? styles.percentageRed : styles.percentageGreen
          }
        >
          {' '}
          {state.percentage}%
        </span>
      </div>
    </div>
  )
}

const SideTable = ({
  stateData,
  chartRef,
  activezip,
  zoomForZipState,
  geo_id,
  stateRef,
  msaRef,
  zip3Ref,
}) => {
  const { regionSelected } = useGeoState()
  const geo_name = statesMap()[geo_id]
  const stateDataForDisplayDate = Object.entries(stateData)
  let filteredFeats = []
  let mappedStateData = []

  if (regionSelected === 'zip3' && zoomForZipState === 0) {
    mappedStateData = stateDataForDisplayDate.forEach((state) => {
      if (activezip.find((element) => element === state[1].geo_id)) {
        const stateName = state[1].geo_name
        filteredFeats.push({
          ...state[1],
          name: stateName,
          identifier: stateName,
        })
      }
    })
  } else {
    mappedStateData = stateDataForDisplayDate.map((state) => {
      const stateName = state[1].geo_name
      return { ...state[1], name: stateName, identifier: stateName }
    })
  }

  let filteredStateData =
    regionSelected === 'zip3' && zoomForZipState === 0
      ? filteredFeats
      : mappedStateData

  let noDataFlag = filteredStateData.length === 0 ? true : false

  const [getFilter, setfilter] = useState('down')
  const sortBy = (action) => {
    if (action === 'up') {
      setfilter('down')
      stateData.sort((a, b) => b.datavalue - a.datavalue)
    } else if (action === 'down') {
      setfilter('up')
      stateData.sort((a, b) => a.datavalue - b.datavalue)
    }
  }
  var sideTableHeader =
    regionSelected === 'metro'
      ? toTitleCase(regionSelected) + ' Area Incidence'
      : regionSelected === 'zip3'
      ? 'State & Zip 3 Incidence'
      : toTitleCase(regionSelected) + ' Incidence'
  var zip3SideTableContent = `Flu Rates represent the incidence of Flu within the population in a given week.`
  var siteTableContent = `Flu Rates represent the incidence of Flu within the population in
  a given week. Change indicates the difference in Flu incidence
  since the previous week.`
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: '16px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '90%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontFamily: 'Optum Sans',
                    fontSize: '21px',
                    fontWeight: '600',
                    color: '#002677',
                  }}
                >
                  {sideTableHeader}
                </h5>
              </span>
            </div>
          </div>
          <div
            style={{
              fontSize: '12px',
              display: 'flex',
              lineHeight: '18px',
              padding: '1rem 0rem 0',
            }}
          >
            <span> {getVectorImage('Info', '16px', '16px')}</span> &nbsp;&nbsp;
            {noDataFlag ? (
              <div
                style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}
                className={styles.sideTableHeader}
              >
                {' '}
                {geo_name} ZIP3 info is not available right now.
              </div>
            ) : (
              <div
                style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}
                className={styles.sideTableHeader}
              >
                {' '}
                {regionSelected !== 'zip3'
                  ? siteTableContent
                  : zip3SideTableContent}
              </div>
            )}
          </div>
        </div>
        {!noDataFlag && (
          <div
            style={{
              padding: '0rem 0rem',
              paddingBottom: '0.5rem',
              paddingTop: '0.5rem',
              borderTop: '1px solid #CBCCCD',
              borderBottom: '2px solid #002677',
              marginTop: '5px',
            }}
          >
            <div style={{ display: 'inline-flex', width: '54%' }}>
              <h4>{toTitleCase(regionSelected)}</h4>
            </div>
            <div
              style={{
                display: 'inline-flex',
                width: '20%',
                justifyContent: 'flex-middle',
                cursor: 'pointer',
              }}
            >
              <h4 onClick={() => sortBy(getFilter)}>
                Rate{' '}
                <span style={{ marginLeft: '4px' }}>
                  {getVectorImage(getFilter)}
                </span>
              </h4>
            </div>
            <div
              style={{
                display: 'inline-flex',
                width: '26%',
                justifyContent: 'flex-middle',
              }}
            >
              <h4>Change</h4>
            </div>
          </div>
        )}
      </div>

      <div className={styles.scroll}>
        {filteredStateData.map((state, index) => {
          return (
            <Databind
              state={state}
              key={state.statename + index}
              msaRef={msaRef}
              stateRef={stateRef}
              zip3Ref={zip3Ref}
            />
          )
        })}
      </div>
    </div>
  )
}

export default SideTable
