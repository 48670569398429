import React from 'react'
import AuthProvider from '../AuthProvider'
import MessageProvider from '../MessageProvider'
import ResponsiveMedia from '../ResponsiveMedia'
import Theme from '../Theme'
import Router from './Router'

const App = () => {
  const isIE = /*@cc_on!@*/ false || !!document.documentMode

  return (
    <>
      {isIE ? (
        <ieSupportWarning />
      ) : (
        <AuthProvider>
          <Theme>
            <ResponsiveMedia>
              <MessageProvider>
                <Router />
              </MessageProvider>
            </ResponsiveMedia>
          </Theme>
        </AuthProvider>
      )}
    </>
  )
}

export default App
