import React from 'react'

const Container = ({
  backgroundColor = null,
  children = null,
  className = null,
  // maxWidth = "1170px",
  padding = { xs: 2, md: 3, lg: 4, xl: 5 },
}) => {
  const style = {
    backgroundColor,
    boxSizing: 'border-box',
    //padding: useMedia(padding, transformPadding),
  }

  return (
    <div className={className} style={style}>
      <div style={{ margin: '0 auto' }}>{children}</div>
    </div>
  )
}

export default Container
