import React from 'react'
import { useGeoState } from '../GeoProvider'
import moment from 'moment'
import styles from './DisplayDateNew.module.scss'
import getEpiWeek from '../../utils/getEpiWeek'

export const DisplayDateFluNew = ({
  showWeek,
  diseaseType,
}) => {
  const {  displayDate, indicatorName } = useGeoState()

  // TODO : this const number should be moved out at the /Flu level to be used also for the <DisplayDate /> component


  // TODO: can break this into a common function for the display date and this component to use!

  return (
    <div className={styles.base}>
      <div className={styles.dateWeek}>
        {indicatorName.toUpperCase()==='ACTUALS'?
        <p className={styles.displayDateMargin}>
          {diseaseType} Levels - {indicatorName} for {' '}
          {showWeek ? ` Epiweek ${getEpiWeek(displayDate)}: ` : null}
          {moment(displayDate).format('MMMM D, YYYY')}{' '}
        </p>
        :<p className={styles.displayDateMargin}>
        {diseaseType} Levels - {' '}
        {showWeek ? ` Epiweek ${getEpiWeek(displayDate)}: ` : null}
        {moment(displayDate).format('MMMM D, YYYY')}{' '}
      </p>}
      </div>
    </div>
  )
}

export default DisplayDateFluNew
