import React from 'react'
import styles from './FluIndices.module.scss'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import GeoProvider from '../../components/GeoProvider'
import DashboardMap from './DashboardMap'
import dataDisplayOptions from './dataDisplayOptions'
import { startDate, endDate, displayDate } from './dates'
import Header from './Header'
import Gender from '../GenderAge'
import SeasonalPattern from '../SeasonalPattern'
import SystemNotification from './../Dashboard/SystemNotification'
const RSVDashboard = () => {
  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.body}>
            <GeoProvider
              startDate={startDate}
              endDate={endDate}
              displayDate={displayDate}
              mapOptions={dataDisplayOptions[0]['options'][0]}
              indicatorName={dataDisplayOptions[0]['options'][0].indicatorName}
              regionSelected={'state'}
            >
              <SystemNotification/>
              <Header headerType="NAT" headerNumber={1} />
              <DashboardMap />
              <Gender    geo_type="NAT" geo_id="NAT"  data_type="RSV"/>
              <SeasonalPattern geo_type="NAT" geo_id="NAT" data_type="RSV"/>
            </GeoProvider>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default RSVDashboard
