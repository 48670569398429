import React from "react";
import Item from "./Item";

import styles from "./HeaderLinks.module.scss";

const HeaderLinks = ({ children = null }) => (
  <ul className={styles.base}>{children}</ul>
);

HeaderLinks.Item = Item;

export default HeaderLinks;
