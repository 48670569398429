import { React, useState, useEffect } from 'react'
import styles from './SeasonalPattern.module.scss'
import Select from 'react-select'
import dataSeason from './Seasons'
import Spinner from '../../components/Loader/Spinner'
import { components } from 'react-select'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
import { Checkbox, FormField } from '@better-ui/react'
import ModelTabs from './ModelTabs'
const SeasonalPatternPanel = ({geo_parent, geo_type, geo_id, data_type }) => {  
  const { user } = useAuthState()
  const [selectedOptions, setSelectedOptions] = useState([dataSeason[0]])
  const [seasonalPattern, setSeasonalPatternData] = useState()
  const [seasonalPatternForecast, setSeasonalPatternDataForecast] = useState()
  const [errorCode, setErrorCode] = useState('401')
  const [errorMessage, setErrorMessage] = useState(
    'Unable to process the request.'
  )
  const [errorSubject, setErrorSubject] = useState(
    'Impotant Tips: clear browser cache, open dashboard in new window, check network connectivity.'
  )
  const [errorOccured, seterrorOccured] = useState(false)
  if(geo_type==='ZIP3')
  {
    var geotype= (geo_type==='ZIP3' && geo_id ===undefined) ? "STATE": 'ZIP3'
    geo_id = (geo_type==='ZIP3' && geo_id ===undefined) ? geo_parent:geo_id
    geo_type = geotype
  }
 
  
  var seasonendpoint =
    data_type === 'RSV' ? 'seasonaldetails' :  data_type + 'seasonaldetails'
  const { data: seasonalPatternData, error: seasonalPatternDataError } =
    useGeoFetch('', '', seasonendpoint, geo_type + '/' + geo_id)

  useEffect(() => {
    if (seasonalPatternData !== null && seasonalPatternData !== undefined) {
      if (seasonalPatternData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'SeasonalPattern success',
          user: user,
        })
        setSeasonalPatternData(seasonalPatternData.response_data)
      } else {
        setErrorCode(seasonalPatternData.response_code)
        setErrorMessage(seasonalPatternData.response_message)
        setErrorSubject(seasonalPatternData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [seasonalPatternData])

  const { data: seasonalPatternForecastData, error: seasonalPatternDataForecastError } =
    useGeoFetch('', '', seasonendpoint, geo_type + '/' + geo_id+'?data_type=FORECAST')

  useEffect(() => {
    if (seasonalPatternForecastData !== null && seasonalPatternForecastData !== undefined) {
      if (seasonalPatternForecastData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Seasonal Pattern Forecast Success',
          user: user,
        })
        setSeasonalPatternDataForecast(seasonalPatternForecastData.response_data)
      } else {
        setErrorCode(seasonalPatternForecastData.response_code)
        setErrorMessage(seasonalPatternForecastData.response_message)
        setErrorSubject(seasonalPatternForecastData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [seasonalPatternForecastData])

  //// Start here
  const Option = (props) => {  
    const text =
      'Select up to 4 to compare. Prediction Intervals will be hidden.'
    let disabled = false
    if (selectedOptions.length >= 4 && !props.isSelected) {
      disabled = true
    }
    return (
      <div>
        {props.label === dataSeason[0].label? (
          <div
            style={{ padding: '10px 10px', color: '#4B4D4F', fontSize: '14px' }}
          >
            {text}
          </div>
        ) : (
          ''
        )}

        <components.Option {...props}>
          <FormField disabled={disabled}>
            <Checkbox checked={props.isSelected}> {props.label}</Checkbox>
          </FormField>
        </components.Option>
      </div>
    )
  }
  ////End here

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '4px',
      border: '1px solid #4B4D4F',
      boxSizing: 'border-box',
      cursor: 'pointer',
    }),
    option: (base, state) => ({
      ...base,
      color: '#1e2022',
      backgroundColor: 'white',
      padding: '.5rem 3rem .5rem .5rem',
      cursor: 'pointer',
      fontWeight: state.isSelected ? 'bold' : '',
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: '#002677',
        color: 'white',
        borderRadius: '100px',
        cursor: 'pointer',
        padding: '0px 0px 0px 5px',
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'white',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition }
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: 'white',
      backgroundColor: '#002677',
      borderRadius: '100px',
      width: '32px',

      '&:hover': {
        backgroundColor: '#002677',
        color: 'white',
        borderRadius: '100px',
      },
    }),
  }

  function tabsChangeHandlerNew(e) {
    if (e.length <= 4) {
      setSelectedOptions(e)
    }
  }

  if (seasonalPatternDataError ||seasonalPatternDataForecastError || errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={''}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  return (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <span className={styles.captionFormat}>Seasonal Pattern</span>
          </div>

          <div
            style={{
              minWidth: '20%',
              maxWidth: '40%',
              marginLeft: '40%',
              marginBottom: '24px',
              borderRadius: '4px',
              //border: '1px solid #4B4D4F',
              boxSizing: 'border-box',
              width: '100%',
              marginRight: '1.5%',
              marginTop: '1px',
              cursor: 'pointer',
            }}
          >
            <Select
              isMulti
              options={dataSeason}
              onChange={tabsChangeHandlerNew}
              value={selectedOptions}
              placeholder={`Select Season(s)`}
              styles={colourStyles}
              components={{
                Option,
              }}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div>
          
          {seasonalPattern !== undefined && seasonalPattern !== null &&seasonalPatternForecast!==null && seasonalPatternForecast!==undefined ? (
           
            <ModelTabs data={seasonalPattern}
            dataForecast={seasonalPatternForecast}
            identifier={geo_id}
            selectedOptions={selectedOptions}></ModelTabs>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </div>
  )
}

export default SeasonalPatternPanel
