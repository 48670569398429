import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import DisplayDateFluNew from '../../components/DisplayDateFlu/DisplayDateFluNew'
import Spinner from '../../components/Loader/Spinner'
import EmptyMap from '../../components/Map/emptyMap'
import Timeline from '../../components/Timeline'
import Map from '../../components/Map'
import toolTipFormatter from './toolTipFormatter'
import MapLegend from './cdcMapLegendHorizontal'
import SideTable from './SideTable'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
import MSAApi from './MSAApi'
import Zip3Api from './Zip3Api'
import { lastweekEndDate } from './dates'

const DashboardMap = ({ zoomLocation }) => {

  const { user } = useAuthState()
  const chartRef = useRef(null)
  const slotTimeInSeconds = 604800000
  const latestPositionString = ''
  const slotString = 'week'
  const location = useLocation()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [getMapZip3, setMapZip3] = useState()
  const [activeCbsa, setActiveCbsa] = useState()
  const [activeZip3, setactiveZip3] = useState()
  const [metros, setMetros] = useState()
  const [getStats, setStats] = useState()
  const [getMSAStats, setMSAStats] = useState()
  const [getZIP3Stats, setZIP3Stats] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getStateJson, setStateJson] = useState()
  const [getCBSAJsonData, setCBSAJsonData] = useState()
  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
    displayDate
  } = useGeoState()
  var geo_parent = 'US'
  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }
  const { data: statesData, error: MapError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    'STATE/?geo_parent=US'
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Dashboard access success',
          user: user,
        })
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  //const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')
  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluStats',
    'US/'
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {
        setStats(stats.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [stats])


  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setCBSAJsonData(data)
      })
      .catch(function (err) {
        console.log(err, ' error file us_hrr_values ')
      })
  }, [1])

  const [getzip3Json, setzip3Json] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/three_dig_zips.json`
    fetch(url)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        setzip3Json(data);
      })
      .catch(function (err) {
        console.log(err, " error file load us_hrr_values ");
      });
  }, [1]);
  if (stateDataError || MapError || errorOccured ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }
  const zipMapData = (data) => {
    setMapZip3(data);
  };
  const zipStatsData = (data) => {
    setZIP3Stats(data);
  };
  const zipActiveLocationData = (data) => {
    setactiveZip3(data);
  };

  const msaMapData = (data) => {
    setMetros(data);
  };
  const msaStatsData = (data) => {
    setMSAStats(data);
  };
  const msaActiveLocationData = (data) => {
    setActiveCbsa(data);
  };
  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
        {/* <Zip3Api setMapData={zipMapData} setStatsData={zipStatsData} setActiveLocationData={zipActiveLocationData} /> */}
          {regionSelected === 'state' && (
            <>
              {getStats !== undefined ? (
                <SideTable stateData={getStats} chartRef={chartRef} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
          {regionSelected === 'metro' && (
            <>
            <MSAApi setMapData={msaMapData} setStatsData={msaStatsData} setActiveLocationData={msaActiveLocationData} />

              {getMSAStats !== undefined ? (
                <SideTable stateData={getMSAStats} chartRef={chartRef} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
          {regionSelected === 'zip3' && (
            <>
              {displayDate===lastweekEndDate && getMapZip3===undefined &&  <Zip3Api setMapData={zipMapData} setStatsData={zipStatsData} setActiveLocationData={zipActiveLocationData} />}
              
              {getZIP3Stats !== undefined ? (
                <SideTable stateData={getZIP3Stats} chartRef={chartRef} />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'state' && (
          <>
            {getMapStates !== undefined && getStateJson ? (
              <>
                <div className={`${styles.providerHeader}`}>
                  <DisplayDateFluNew
                    showWeek={true}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'Flu'}
                  />
                  <Timeline
                    statesData={getMapStates}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={7}
                    loadError={stateDataError}
                  />
                </div>
                <div className={styles.legendDiv}>
                <MapLegend />
                </div>
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={null}
                  statesData={getMapStates}
                  cbsaData={null}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="RSVMAP"
                  enableButtons={true}
                  chartRef={chartRef}
                  mapDataType={0}
                  stateJson={getStateJson}
                  dashboardType="FLU"
                  zip3Data={null}
                />
            
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
        {regionSelected === 'metro' && (
          <>
            {metros !== undefined &&
              getMapStates !== undefined &&
              activeCbsa !== undefined &&
              getCBSAJsonData ? (
              <>
                <div className={`${styles.providerHeader}`}>
                  <DisplayDateFluNew
                    showWeek={true}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'Flu'}
                  />
                  <Timeline
                    statesData={getMapStates}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={7}
                    loadError={stateDataError}
                  />
                </div>
                <div className={styles.legendDiv}>
                <MapLegend />
                </div>
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={activeCbsa}
                  statesData={getMapStates}
                  cbsaData={metros}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="RSVMAPMSA"
                  enableButtons={true}
                  zoomLocation={zoomLocation}
                  chartRef={chartRef}
                  mapDataType={1}
                  // mapDataType={geo_type === 'STATE' ? 0 : 1}
                  JsonData={getCBSAJsonData}
                  stateJson={getStateJson}
                  dashboardType="FLU"
                  zip3Data={null}
                />
              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
        {regionSelected === 'zip3' && (
          <>
            {getMapZip3 !== undefined && getzip3Json && activeZip3 && getMapStates 
             ? (
              <>
                <div className={`${styles.providerHeader}`}>
                  <DisplayDateFluNew
                    showWeek={true}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    diseaseType={'Flu'}
                  />
                  <Timeline
                    statesData={getMapZip3}
                    latestPositionString={latestPositionString}
                    slotString={slotString}
                    slotTimeInSeconds={slotTimeInSeconds}
                    markCounts={7}
                    loadError={stateDataError}
                  />
                </div>
                <div className={styles.legendDiv}>
                <MapLegend />
                </div>
                
                <Map
                  toolTipFormatter={toolTipFormatter}
                  activeCbsasData={null}
                  activeZip3Data={activeZip3}
                  statesData={getMapStates}
                  cbsaData={null}
                  mapOptions={mapOptions}
                  dynamicMapTitle={true}
                  mapType="ZIP3MAP"
                  enableButtons={true}
                  chartRef={chartRef}
                  mapDataType={0}
                  stateJson={getStateJson}
                  dashboardType="FLU"
                  zip3Json={getzip3Json}
                  zip3Data={getMapZip3}
                />


              </>
            ) : (
              <EmptyMap stateJson={getStateJson} />
            )}
          </>
        )}
      </div>
      
    </div>
  )
}

export default DashboardMap
