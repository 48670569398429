import React from 'react'

import Spinner from '../Loader/Spinner'
import BarChartDisplay from './ColumnChartDisplay'

const HorizontalColumnBarChart  = ({ data, chartName ,pointLine,locationName}) => {
  return data ? (
    <BarChartDisplay data={data} chartName={chartName} pointLine={pointLine} locationName={locationName} />
  ) : (
    <Spinner />
  )
}

export default HorizontalColumnBarChart
