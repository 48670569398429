import React from 'react'
import DropDownList from './../DropDownList'
import Search from './../Search'
import { useGeoState } from '../GeoProvider'
import styles from './MapOptions.module.scss'
import dayjs from 'dayjs'
const MapOptions = ({regionDisplayOptions,activeCbsa,entity, activeZip3,searchPlaceholder='Search State, City or Zip 3'}) => {    
  let displayDateWeekEnd = dayjs().day(13).format('YYYY-MM-DD')
  //let lastWeekEnd = dayjs().day().format('YYYY-MM-DD')
  const {
    regionSelected,
    setRegionSelected,
    setDisplayDate,
    displayDate
  } = useGeoState()
  const [searchCriteria, setSearchCriteria] = React.useState([])

  return (
    <div className={styles.base}>
   <div  className={styles.pageRegionList}> 
          <DropDownList
          id="regions"
          name="regions"
          onChange={(e) => {
            // history.push({ hash: null })
            setRegionSelected(e.target.value)            
            if (displayDate > displayDateWeekEnd && e.target.value!=="zip3" && entity!=='Vulnerability')
            {
              setDisplayDate(displayDateWeekEnd)
            }
            
          }}
          className={styles.dropDowns}
          defaultValue={regionSelected}
        >
          {regionDisplayOptions.map((regions, regionsIndex) => {
            return (
              <DropDownList.Item
                value={regions.value}
                key={regions.value}
                selected={regionSelected === regions.value}
              >
                {regions.label}
              </DropDownList.Item>
            )
          })}
        </DropDownList>
          </div>
          <div className={styles.pageHeaderSearch}>
         
         
            {activeCbsa !== undefined && activeZip3 !==undefined && entity ==='Flu' && (
              <Search
                criteria={searchCriteria}
                onChange={setSearchCriteria}
                entity={entity}
                activeMetros={activeCbsa}
        
                activeZip3={activeZip3}
              />
            )}

          {activeCbsa !== undefined  && entity !=='Flu' && (
            
              <Search
                criteria={searchCriteria}
                onChange={setSearchCriteria}
                placeholder={searchPlaceholder}
                entity={entity}
                activeMetros={activeCbsa}    
                activeZip3={null}            
              />
            )}
           
          </div>
    </div>
  )
}

export default MapOptions
