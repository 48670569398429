import { React } from 'react'
import styles from './EstimatesTable.module.scss'
import getCountingMethod from '../../../utils/getCountingMethod'

const EstimatesTable = ({data, multiColumn }) => {
  let fcount1=0
  return  (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        
          <table>
            <thead>
              <tr>
                <th>Variables</th>
                <th>Description</th>
                <th>Estimates{' '}&#177;{' '}MoE</th>
                <th>Percentage{' '}&#177;{' '}MoE<span style={{fontSize:'14px',fontWeight:400}}>{' '}(As Available)</span></th>
              </tr>
            </thead>
            <tbody style={{fontSize:'14px'}}>
            {
              data.length === 0 ? (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={5}>
                    No data to show
                  </td>
                </tr>
              ) : (
             (data!==null && data.length===1 && multiColumn===false)?
 
      data[0].map((data_val) => {
        fcount1++
    return(
    <tr style={{borderBottom:(fcount1===5||fcount1===10||fcount1===11)?'1px solid #ddd':''}}>
    <td>{data_val.x_axis} </td>
    <td>{data_val.descriptions}</td>
    <td>{getCountingMethod(data_val.data_estimates,'fixed')}{' '}&#177;{' '}{getCountingMethod(data_val.data_moe,'fixed')}</td>
    <td>{(data_val.data_estimates_perc.toFixed(2))}{' '}&#177;{' '}{(data_val.data_moe_perc.toFixed(2))}</td>
    </tr>
            )
    
   }): 
   data[0].map((data_val) => {
    var counter=1
    return(
    data[1].map((data_val2) =>  {
     if(data_val.x_axis===data_val2.x_axis)
     {
     
        return(
        <tr style={{borderBottom:(counter===5||counter===10||counter===11)?'1px solid #ddd':''}}>
        <td>{data_val.x_axis} </td>
        <td>{data_val.descriptions}</td>
        <td>{getCountingMethod(data_val.data_estimates,'fixed')}&#177;{getCountingMethod(data_val.data_moe,'fixed')}{' '} |{' '} {getCountingMethod(data_val2.data_estimates,'fixed')}&#177;{getCountingMethod(data_val2.data_moe,'fixed')}</td>
        <td>{(data_val.data_estimates_perc.toFixed(2))}&#177;{(data_val.data_moe_perc.toFixed(2))}{' '} | {' '}{data_val2.data_estimates_perc.toFixed(2)}&#177;{data_val2.data_moe_perc.toFixed(2)}</td>
        </tr>
        )
    }
    counter++
   })
  
    )
  })
  )
   }
            </tbody>
          </table>
        </div>

    </div>
    
  ) 
}

export default EstimatesTable
