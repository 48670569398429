export default function MapLegend(props) {
  return (
    <svg viewBox="0 0 250 25" height="25" width="250" align="center" {...props}>
      <text
        x="10"
        y="12"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Very High
      </text>
      <rect
        x="75"
        y="0"
        width="16"
        height="16"
        fill="#422C88"
        stroke="#FFFFFF"
        strokeWidth="1px"
      ></rect>
      <rect
        x="100"
        y="0"
        width="16"
        height="16"
        fill="#5163AE"
        stroke="#FFFFFF"
        strokeWidth="2px"
      ></rect>
      <rect
        x="125"
        y="0"
        width="16"
        height="16"
        fill="#7796CC"
        stroke="#0E456B"
        strokeWidth="1px"
      ></rect>
      <rect
        x="150"
        y="0"
        width="16"
        height="16"
        fill="#AAC9E4"
        stroke="#2D6EA3"
        strokeWidth="1px"
      ></rect>
      <rect
        x="175"
        y="0"
        width="16"
        height="16"
        fill="#E5FDF7"
        stroke="#2B9181"
        strokeWidth="1px"
      ></rect>

      <text
        x="200"
        y="12"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Minimal
      </text>
      
    </svg>
  )
}
