import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { USER_LOGIN_REDIRECT } from '../../utils/constants'

import PageNotFound from '../PageNotFound'
import Container from '../Container'
import Layout from '../Layout'

import { useAuthState } from './'

const requiresAuth = (Component, roles = ['Basic', 'Power User', 'Admin','External']) => (
  props
) => {
  const { isAuthenticated, user } = useAuthState()
  if (isAuthenticated) {
    const isAuthorized = roles.includes(user.role)
    if (isAuthorized) {
      return <Component {...props} />
    }
    return (
      <Layout>
        <Container>
          <PageNotFound />
        </Container>
      </Layout>
    )
  }

  const location = useLocation()
  window.localStorage.setItem(USER_LOGIN_REDIRECT, JSON.stringify({ location }))

  const history = useHistory()
  history.push('/')

  return null
}

export default requiresAuth
