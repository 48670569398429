import React, { useRef } from 'react'
import GeoProvider from '../../components/GeoProvider'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { displayDateReport } from '../FluDashboard/dates'
import Map from '../../components/Map'
import toolTipFormatter from '../FluDashboard/toolTipFormatter'
import dataDisplayOptions from '../FluDashboard/dataDisplayOptions'
import MapLegend from '../FluDashboard/cdcMapLegendHorizontal'
import EmptyMap from '../../components/Map/emptyMap'
import { capitalize } from '../../utils/capitise'
import styles from './StateRiskMap.module.scss'
import { useCBSADataFetch } from '../../hooks/useCBSADataFetch'
import { useMSADataFetch } from '../../hooks/useMSADataFetch'
import { useStateDataFetch } from '../../hooks/useStateDataFetch'
import { useStatesJson } from '../../hooks/useStatesJson'
import { useCBSAJson } from '../../hooks/useCBSAJson'
import ErrorMessage from '../../components/Layout/ErrorMessage'

const StateRiskMap = () => {
  //path ---> /riskmap/flu/NAT
  //path ---> /riskmap/flu/STATE/TX
  const location = useLocation()
  const locationValues = location?.pathname?.split('/')
  const diseaseType = locationValues.at(-2)
  const diseaseLabel = capitalize(diseaseType)
  const chartRef = useRef(null)
  const DEFAULT_VIEW = 'msa'
  const DEFAULT_MAP_EXPORT = false

  const queryParams = new URLSearchParams(location.search)
  const viewType = queryParams.get('view')
  const exportMap = queryParams.get('export')

  let view = viewType ?? DEFAULT_VIEW
  let enableMapExport = exportMap === 'true' ? true : DEFAULT_MAP_EXPORT

  const { data: stateJson } = useStatesJson()
  const { data: cbsaJSONData } = useCBSAJson()

  const {
    data: statesMapData,
    loading: statesLoading,
    error: statesMapDataError,
  } = useStateDataFetch(displayDateReport, displayDateReport, diseaseType)

  const {
    data: cbsaData,
    loading: cbsaLoading,
    error: cbsaError,
  } = useCBSADataFetch(displayDateReport, displayDateReport, diseaseType)

  const {
    data: msaMapData,
    loading: msaLoading,
    error: msaMapError,
  } = useMSADataFetch(displayDateReport, displayDateReport, diseaseType)

  if (statesMapDataError || cbsaError || msaMapError) {
    return (
      <ErrorMessage
        errorCode={'Error'}
        errorMessage={'Failed to load the map'}
        errorSubject={'Map load failed'}
        errorDetails={
          'Some error occurred. Please refresh your screen and try again.'
        }
      />
    )
  }

  const fetchMap = () => {
    if (view.toLowerCase() === 'state') {
      return (
        <Map
          toolTipFormatter={toolTipFormatter}
          activeCbsasData={null}
          statesData={statesMapData}
          cbsaData={null}
          mapOptions={dataDisplayOptions[0]['options'][0]}
          dynamicMapTitle={true}
          mapType="RSVMAP"
          enableButtons={false}
          chartRef={chartRef}
          mapDataType={0}
          stateJson={stateJson}
          dashboardType="FLU"
          zip3Data={null}
          mapHeight={600}
          mapWidth={1200}
          exportMap={enableMapExport}
        />
      )
    } else if (view.toLowerCase() === 'msa') {
      return (
        <Map
          toolTipFormatter={toolTipFormatter}
          activeCbsasData={cbsaData}
          statesData={statesMapData}
          cbsaData={msaMapData}
          mapOptions={dataDisplayOptions[0]['options'][0]}
          dynamicMapTitle={true}
          mapType="RSVMAPMSA"
          chartRef={chartRef}
          mapDataType={1}
          JsonData={cbsaJSONData}
          stateJson={stateJson}
          dashboardType="FLU"
          zip3Data={null}
          mapHeight={600}
          mapWidth={1200}
          exportMap={enableMapExport}
        />
      )
    }
  }

  return (
    <div className={styles.base}>
      {/* <h1>{`${diseaseLabel}Cast Weekly ${diseaseLabel} Levels`}</h1>
      <h3>{dayjs(displayDate).format('MMMM DD, YYYY.')}</h3> */}
      {(statesLoading || msaLoading || cbsaLoading) && (
        <EmptyMap stateJson={stateJson} />
      )}
      {statesMapData && msaMapData && cbsaData && stateJson && cbsaJSONData && (
        <div className={styles.map_container}>
          <MapLegend />
          <GeoProvider displayDate={displayDateReport}>{fetchMap()}</GeoProvider>
        </div>
      )}
    </div>
  )
}

export default StateRiskMap
