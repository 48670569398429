import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import RouteProvider from '../RouteProvider'
import pages from '../../pages'

const Router = () => (
  <BrowserRouter>
    <RouteProvider value={pages}>
      {(context) => (
        <Switch>
          {context.routes.map((route) => (
            <Route key={route} exact {...route} />
          ))}
        </Switch>
      )}
    </RouteProvider>
  </BrowserRouter>
)

export default Router
