import React from 'react'
import Container from '../Container'
import Item from './Item'

import styles from './Navigation.module.scss'

const Navigation = ({ children = null }) => (
  <nav className={styles.base}>
    <Container className={styles.containerPadding}>
      <ul className={styles.items}>{children}</ul>
    </Container>
  </nav>
)

Navigation.Item = Item

export default Navigation
