import React from 'react'
import Spinner from '../Loader/Spinner'
import BarChartDisplay from './ColumnChartDisplay'

const DrillDownChart = ({ data, chartName ,pointLine,locationName,dataSeries, type,displayDate,drillDownState}) => {

  return data ? (
    <BarChartDisplay data={data} 
    chartName={chartName} 
    pointLine={pointLine} 
    locationName={locationName} 
    dataSeries={dataSeries}
    type={type}
    displayDate={displayDate}
    drillDownState={drillDownState} />
  ) : (
    <Spinner />
  )
}

export default DrillDownChart
