const fetchAdUser = async (id_token, client_info, session_state, state) => {
  const baseApiUri = process.env.REACT_APP_BACKEND_BASE_URL
  const ttlMinutes = 2 * 60 // two hours

  const response = await fetch(`${baseApiUri}/msid/oauth2/login-redirect`, {
    method: 'POST',
    body: JSON.stringify({
      idToken: id_token,
      client_info,
      session_state,
      state,
    }),
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  })

  const user = await response.json()

  return {
    username: user.username,
    firstName: user.givenName,
    lastName: user.familyName,
    email: user.email,
    phone: user.phone,
    role: user.role,
    preferences: {
      timeZone: 'America/Chicago',
      timeZoneDisplay: 'DD/MM/YYYY, h:mm:ss A z',
    },
    expiry: new Date().getTime() + ttlMinutes * 60 * 1000,
  }
}

export default fetchAdUser
