import React, { useEffect } from 'react'
import {  useGeoFetch } from '../../../components/GeoProvider'
import { useAuthState } from '../../../components/AuthProvider'
import ErrorLogs from '../../../components/ErrorBoundary/errorlogs'
const ApiAgeGender = ({  geo_type, geo_id,setGenderAge1 }) => {    
  const { user } = useAuthState()
  const { data: genderAgeData, error: genderAgeDataError } = useGeoFetch(
    '',
    '',
    'vulnerability/getagegender',
    geo_type + '/' + geo_id
  )

  useEffect(() => {
    if (genderAgeData !== null && genderAgeData !== undefined) {
      if (genderAgeData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'GenderAge success',
          user: user,
        })
        setGenderAge1(genderAgeData.response_data)

      } else {
        // setErrorCode(genderAgeData.response_code)
        // setErrorMessage(genderAgeData.response_message)
        // setErrorSubject(genderAgeData.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [genderAgeData])


  return (
    <>
    
    </>
  )
}

export default ApiAgeGender


