import React from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'
import Spinner from '../Loader/Spinner'
import getColumnChartOptions from './getColumnChartOptions'
import styles from './ColumnChartDisplay.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const BarChartDisplay = ({ data, chartName ,pointLine,locationName,dataSeries,type,displayDate,drillDownState}) => {
  let titleText="Count"
  let finalData=[]
  data!==undefined && data!==null&&
  data.map((data_val) => {
    finalData.push({"name":data_val.drilldown_category,"y":data_val.drilldown_count,"drilldown":data_val.drilldown_category})
   
  })

  //dummy data
  const chartOptions = getColumnChartOptions(finalData,pointLine,titleText,locationName,dataSeries,type,displayDate)

  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default BarChartDisplay
