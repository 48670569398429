import React from 'react'
import BackToHomeLink from '../BackToHomeLink'
import Container from '../Container'
import Content from '../Content'
import { SUPPORT_EMAIL } from '../../utils/constants'
import ErrorLogs from '../ErrorBoundary/errorlogs'
import { useAuthState } from '../AuthProvider'

const ErrorMessage = ({
  errorCode = 401,
  errorSubject = 'Impotant Tips: clear browser cache, open dashboard in new window, check network connectivity.',
  errorMessage = 'Impotant Tips: clear browser cache, open dashboard in new window, check network connectivity.',
  errorDetails = 'Unable to process the request.',
}) => {
  const { user } = useAuthState()
  ErrorLogs({
    message_type: 'Error',
    error_details: `${errorSubject}, ${errorMessage}, ${errorDetails}`,
    user: user,
  })
  if (errorCode !== 404) {
    return (
      <>
        <Container>
          <div style={{padding:'16px'}}>
            <h3>
              {errorCode}: {errorSubject}
            </h3>
            <p>
              {errorMessage}
              <br />
              <br />
              Error Details : {errorDetails}
            </p>
            <p>
              If you see this message again, contact us at{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
            </p>
            <p>
              <BackToHomeLink />
            </p>
          </div>
        </Container>
      </>
    )
  } else {
    return (
      <>
        <br></br>
        <Container>  
        <div style={{padding:'16px'}}>       
            <h3>{errorCode}: {errorSubject}</h3>
            <p>
              {errorMessage} <br/><br/>
              Error Details : {errorDetails}
            </p>   
        </div>       
        </Container>
        <br></br>
      </>
    )
  }
}

export default ErrorMessage
