import React from "react";
import { useLocation } from "react-router-dom";
import { generateAppRoutes, pathTo } from "./utils";

export const RouteContext = React.createContext({
  breadcrumbs: [],
  currentRoute: null,
  rootRoute: null,
  routes: []
});

const RouteProvider = ({ children = null, value }) => {
  const location = useLocation();

  const routes = generateAppRoutes(value);
  const currentRoute = routes.find(route => route.path === location.pathname);
  const rootRoute = routes.find(route => !route.parent);

  const breadcrumbs = currentRoute ? pathTo(currentRoute) : [];
  const context = { breadcrumbs, currentRoute, rootRoute, routes };

  return (
    <RouteContext.Provider value={context}>
      {typeof children === "function" ? children(context) : children}
    </RouteContext.Provider>
  );
};

export default RouteProvider;
