import React from 'react'
import styles from './DisplayDateProvider.module.scss'
import moment from 'moment'
import getEpiWeek from '../../utils/getEpiWeek'

export const DisplayDateProviderNew = ({textShow, showWeek, lastweekEndDate }) => {
  // const lastweekEndWeek = moment(lastweekEndDate, 'yyyy-MM-DD').week()
  // TODO : this const number should be moved out at the /Flu level to be used also for the <DisplayDate /> component

  return (
    <div className={styles.base}>
      <div className={styles.dateWeek}>
        <p className={styles.displayDateMargin}>
        {/* {textShow} {lastweekEndDate} */}

        {showWeek ? ` ${textShow} ${getEpiWeek(lastweekEndDate)}: ` : null}
          {moment(lastweekEndDate).format('MMMM D, YYYY')}{' '}
        
         
        </p>
      </div>
    </div>
  )
}

export default DisplayDateProviderNew
