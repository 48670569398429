import React from 'react'
import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@reach/tabs'

import '@reach/tabs/styles.css'
import styles from './Tabs.module.scss'
import Icon from '../Icon'

const TabComponent = ({
  children = null,
  className,
  defaultSelectedIndex,
  horizontal: isHorizontal,
  onChange,
  selectedIndex,
}) => (
  <Tabs
    className={`${styles.base} ${
      isHorizontal ? styles.horizontal : styles.vertical
    } ${className ? className : ''}`}
    defaultIndex={defaultSelectedIndex}
    index={selectedIndex}
    onChange={onChange}
  >
    <TabList>
      {React.Children.map(children, (child) => (
        <Tab key={child.props.label}>
          <span className={styles.tabLabelIcon}>
            {child.props.icon && <Icon type={child.props.icon} />}
          </span>
          {child.props.label}
        </Tab>
      ))}
    </TabList>
    <TabPanels>{children}</TabPanels>
  </Tabs>
)

TabComponent.Panel = TabPanel

export default TabComponent
