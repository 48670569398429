import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import styles from './SideTable.module.scss'
import getVectorImage from '../../../utils/getVectorImage'
const BindItems = ({ subItems, sendDataMenuToParent, setDefaultMenu }) => {
  const history = useHistory()

  const handleClick = (subItems) => {    
    debugger 
    history.push(`#${subItems.combined_filter}`)  
    sendDataMenuToParent(`${subItems.primary_location_sub_type}`)
    setDefaultMenu(`${subItems.primary_location_type}`)
   }
  return (
    <>
      <MenuItem
        className={styles.menuItemClassName}
        key={subItems.primary_location_sub_type}        
        onClick={event => handleClick(subItems)}
      >
        {subItems.primary_location_sub_type}{' '}
      </MenuItem>
    </>
  )
}
const Databind = ({ primaryItems, setDefaultMenu, sendDataMenuToParent }) => {
  const history = useHistory()
  const [display] = useState('arrow')
  const [align] = useState('start')
  const [position] = useState('initial')
  const [viewScroll] = useState('initial')
  const stateDataForDisplayDate = Object.entries(primaryItems[1])
  const mappedStateData = stateDataForDisplayDate.map((state) => {
    const type = state[0]
    return { ...state[1], name: type, identifier: type }
  })

  const handleClick = () => {     
    history.push(`#Others`)  
    sendDataMenuToParent('')
    setDefaultMenu('Others')
   }
  return (
    <>
      {primaryItems[0] === 'Other' ? (
        <MenuItem
          key={primaryItems[0]}          
          onClick={event => handleClick()}
        >
          {primaryItems[0]}{' '}
        </MenuItem>
      ) : (
        <SubMenu
          className={styles.menuItemClassName}
          label={primaryItems[0]}
          align={align}
          position={position}
          viewScroll={viewScroll}
          arrow={display === 'arrow'}
          offsetX={-5}
          offsetY={-5}
        >
          {mappedStateData.map((state) => {
            return <BindItems setDefaultMenu ={setDefaultMenu} sendDataMenuToParent={sendDataMenuToParent}subItems={state} key={state.identifier} />
          })}
        </SubMenu>
      )}
    </>
  )
}

const DropdownMenu = ({ dropdownData, sendDataMenuToParent }) => {

  const stateDataForDisplayDate = Object.entries(dropdownData)
  const [display] = useState('default')
  const [align] = useState('start')
  const [position] = useState('anchor')
  const [viewScroll] = useState('auto')
  const history = useHistory()
  const [getDefaultMenu, setDefaultMenu] = useState('All Groups')
  const [showMore, setShowMore] = useState(false)

  const handleClick = (valid) => {     
    setShowMore(!valid)
   }
   const handleClick2 = () => {     
    history.push(`#All`)
    setDefaultMenu('All Groups')
    sendDataMenuToParent('')
   }
  return (
    <>
      <Menu
        menuButton={
          <MenuButton
            onClick={event => handleClick(showMore)}           
            className={styles.ddl}
          >
            {getDefaultMenu}
            {showMore
              ? getVectorImage('expandmore', '6px', '11px')
              : getVectorImage('expandless', '6px', '11px')}{' '}
            {}
          </MenuButton>
        }
        align={align}
        position={position}
        viewScroll={viewScroll}
        arrow={display === 'arrow'}
        offsetX={0}
        offsetY={0}
      >
        <MenuItem key="all"       
        onClick={event => handleClick2()}   
        >
          All Groups{' '}
         
        </MenuItem>
        {stateDataForDisplayDate
          // .sort(function(a, b) {
         
          .map((state) => {
            return <Databind setDefaultMenu ={setDefaultMenu} sendDataMenuToParent={sendDataMenuToParent} primaryItems={state} key={state[0]} />
          })}
      </Menu>
    </>
  )
}
export default DropdownMenu
