import React, { useEffect } from 'react'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import { useAuthState } from '../../components/AuthProvider'
import dayjs from 'dayjs'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { lastweekEndDate } from './dates'
const Zip3Api = ({  setMapData, setStatsData, setActiveLocationData }) => {    
  const {
    dataStartDate,
    dataEndDate,
    setDisplayDate
  } = useGeoState()  
  var geo_parent = 'US'
  const { user } = useAuthState()
  //const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  const { data: msaData, error: MapmsaError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    'MSA'
  )

  useEffect(() => {
    if (msaData !== null && msaData !== undefined) {
      if (msaData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Provider Dashboard success',
          user: user,
        })
        setMapData(msaData.response_data)
      } else if (msaData.response_code === 404) {
        setMapData(msaData.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${msaData.response_subject}, ${msaData.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        // setErrorCode(msaData.response_code)
        // setErrorMessage(msaData.response_message)
        // setErrorSubject(msaData.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [msaData])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluLocations',
    'activelocations?geo_type=MSA'
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveLocationData(activeCbsasData)
    }
  }, [activeCbsasData])
  const { data: msaStats, error: msaDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluStats',
    'MSA/'
  )

  useEffect(() => {
    if (msaStats !== null && msaStats !== undefined) {
      if (msaStats.response_code === 200) {
        setStatsData(msaStats.response_data)
      } else if (msaStats.response_code === 404) {
        setStatsData(msaStats.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${msaStats.response_subject}, ${msaStats.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        // setErrorCode(msaStats.response_code)
        // setErrorMessage(msaStats.response_message)
        // setErrorSubject(msaStats.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [msaStats])
  return (
    <>
    
    </>
  )
}

export default Zip3Api