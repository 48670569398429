import { useState, useEffect } from 'react'
import { useGeoFetch } from '../components/GeoProvider'

export const useStateDataFetch = (startDate, endDate, diseaseType) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState()

  const { data: statesData, error: statesDataError } = useGeoFetch(
    startDate,
    endDate,
    `${diseaseType}Map`,
    'STATE/?geo_parent=US'
  )

  useEffect(() => {
    setLoading(true)

    if (statesData?.response_code === 200) {
      setData(statesData?.response_data)
      setLoading(false)
    } else if (statesData?.response_code >= 400) {
      setError(true)
      setLoading(false)
    }

    if (statesDataError) {
      setError(true)
      setLoading(false)
    }
  }, [statesData, statesDataError])

  return { data, error, loading }
}
