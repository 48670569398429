import Highcharts from 'highcharts'
import HC_patternFill from 'highcharts-pattern-fill'
import HC_rounded from 'highcharts-rounded-corners'
import imgs from  '../../assets/images/icon/image-line-png.png'
import CountingMethod from './../../utils/getCountingMethod'
// import age_groups from './AgeGroupsJson'
HC_rounded(Highcharts)
HC_patternFill(Highcharts)
// function hexToRgb(hex) {
//   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//   return result
//     ? {
//         r: parseInt(result[1], 16),
//         g: parseInt(result[2], 16),
//         b: parseInt(result[3], 16),
//       }
//     : null;
// }
// function getSplitByTwoCharacter(string,char1,char2)
// {
//   if(string.length!==undefined)
//   {
//   return string.slice(
//   string.indexOf(char1) + 1,
//   string.lastIndexOf(char2),
// )
// }
// else{return "NA"}
// } 
function getAgeGenderColumnChartOptions(finalData,renderType,chartName,multiColumn,locationName,searchLocationText,displayDate) {
  var titleText=""
  switch (chartName) {
    case 'genderAgechart':
      titleText="Count"
      break
    
    default:
      titleText=""
      break
  }
 
  return {
    chart: {
      type: 'column',
      height: 300,
      
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'bottom',
      margin: 50,
      floating: true,
    },
    yAxis: {
      title: {
        text: chartName==="cdcSVIColumnChart2"?'Amount':titleText,
       
        style: {
          color: Highcharts.getOptions().colors[1],
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          fontSize: '16px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          return this.total + '%'
        },
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: finalData[0].data,
      crosshair:{color:'#E5E5E6'},
      labels: {
        style:{
          fontSize: '12px',
          fontWeight: '400',
          color: '#4B4D4F',
        }
      },
      
 
    },
    tooltip: {
      outside:true,
    enabled: true, //Added to disable tooltip
    borderRadius: 10,
    backgroundColor: null,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    style: {
      padding: 0,
    },
    
    formatter:multiColumn? function () {
      
      if(this.key.length!==undefined){
      return (
        `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
        border-radius: 4px; font-family: 'Optum Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        ">
        
          <div >${this.key}&nbsp;(${this.series.name})</div>
       
          <div style='display:flex;gap:20px;padding-top:10px'>
          <div style='border-right:1px solid grey;padding-right:20px'> 
          <div style="color:#002677">${locationName}</div>
          <div style='margin-top:10px' >${CountingMethod(this.point.count1,'fixed')} </div>
          </div>
          <div style=''> 
          <div style="color:#002677">${searchLocationText}</div>
          <div style='margin-top:10px' >${CountingMethod(this.point.count2,'fixed')} </div>
          </div>
          </div>
        
        </div>`
      )
      }
      else{return (
        `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
        border-radius: 4px; font-family: 'Optum Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        ">
        
          <div >${this.point.data_series}&nbsp;(${this.series.name})</div>
       
          <div style='display:flex;gap:20px;padding-top:10px'>
          <div style='border-right:1px solid grey;padding-right:20px'> 
          <div style="color:#002677">${locationName}</div>
          <div style='margin-top:10px' >${CountingMethod(this.point.count1,'fixed')} </div>
          </div>
          <div style=''> 
          <div style="color:#002677">${searchLocationText}</div>
          <div style='margin-top:10px' >${CountingMethod(this.point.count2,'fixed')} </div>
          </div>
          </div>
        
        </div>`
      )}
      // if(this.key.length!==undefined){
      // return (
      //   `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
      //   border-radius: 4px; font-family: 'Optum Sans', sans-serif;
      //   font-style: normal;
      //   font-weight: 700;
      //   font-size: 14px;
        
      //   ">
      //   <div >${displayDate}</div>
      //     <div style='padding-top:10px;display:flex'>${this.key}&nbsp;(${this.series.name})</div>
      //     <div style='display:flex;gap:20px;padding-top:10px'>
      //     <div > 
      //     <div style="color:#002677">${locationName}</div>
      //     <div style='margin-top:10px' >${CountingMethod(getSplitByTwoCharacter(this.key,'^','^').split('|')[0])}</div>
      //     </div>
        
      //     </div>
        
      //   </div>`
      // )}
      // else{
      //   var age_group=parseInt(this.key.toString().split('.')[1])===85?
      //   age_groups[parseInt(this.key+.15)]:
      //   age_groups[parseInt(this.key-.15)]
      
      //   return `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
      //   border-radius: 4px; font-family: 'Optum Sans', sans-serif;
      //   font-style: normal;
      //   font-weight: 700;
      //   font-size: 14px;
      //   ">
      //   <div >${displayDate}</div>
      //     <div style='padding-top:10px;display:flex'>${age_group} (${this.series.name})</div>
      //     <div style='display:flex;gap:20px;padding-top:10px'>
      //     <div> 
      //     <div style="color:#002677">${searchLocationText}</div>
      //     <div style='margin-top:10px' >${CountingMethod(this.y)}</div>
      //     </div>
         
      //     </div>
        
      //   </div>`
      // }
    }:function () {
      return (
        `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
        border-radius: 4px; font-family: 'Optum Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        ">
        <div >${displayDate}</div>
          <div  style='padding-top:10px'>${this.key} (${this.series.name})</div>
          <div style='display:flex;gap:20px;padding-top:10px'>
          <div style='padding-right:20px'> 
          <div style="color:#002677">${locationName}</div>
          <div style='margin-top:10px' >${CountingMethod(this.y,'fixed')}</div>
          </div>
         
          </div>
        
        </div>`
      )
    },
    },
 
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false,
          formatter: function () {
            return this.y + ' %'
          },
        },
        enableMouseTracking: true,
      },
      
      series: {
        borderRadiusTopLeft: '20%',
        borderRadiusTopRight: '20%',
        pointWidth:24
      },
    },
    legend: {
      // enabled:false,
      symbolHeight: .001,
   symbolWidth: .001,
   symbolRadius: .001,
     
      useHTML: true,
   
     
  },
    defs: {
      patterns: [
        {
          id: 'custom-pattern',
          path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            stroke: 'white',
            strokeWidth: 1.5,
            fill: '#c71e83',
          },
        },
      ],
    },
    series:multiColumn? [
   
      {
        id:finalData[1].name,
        name: finalData[1].name,
        data: finalData[1].data,
        showInLegend: true,
        color: '#15A796',
        type: 'column',
        // enableMouseTracking: false,
      },
      {
        linkedTo: finalData[3].name,
        name: finalData[3].name,
        data: finalData[3].data,
        showInLegend: false,
        type:'scatter',
        marker: {
          symbol: 'url('+imgs+')',
      },
        borderColor:'#7D7F81',
         enableMouseTracking: true,
      },
      {
        id:finalData[2].name,
        name: finalData[2].name,
        data: finalData[2].data,
        showInLegend: true,
        type: 'column',
        color: '#AEE2D5',
        borderColor:'#15A796',
        // enableMouseTracking: false,
      },
      
      {
        linkedTo: finalData[4].name,
        name: finalData[4].name,
        data: finalData[4].data,
        showInLegend: false,
        type:'scatter',
        marker: {
          symbol: 'url('+imgs+')',
          // width: 30,
          // height: 8
      },
        
        //chartName==="cdcSVIColumnChart2"?'#E5E5E6':'url(#custom-pattern)',
        borderColor:'#7D7F81',
       enableMouseTracking: true,
      },
    ]:[
      {
        name: finalData[1].name,
        data: finalData[1].data,
        showInLegend: true,
        color: '#15A796',
        enableMouseTracking: true,
      
      },
      {
        name: finalData[2].name,
        data: finalData[2].data,
        showInLegend: true,
        type: 'column',
        color: '#AEE2D5',
        borderColor:'#15A796',
        enableMouseTracking: true,
      
      },
    ],
    responsive: {
      rules: [
        {
          condition: {            
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              itemStyle: {
                fontSize: '13px',
             },
              labelFormatter: function () {
                if(this.name==='Male')
                return '<div style="display:flex"><div style="height:18px;width:18px;background-color:#15A796;border-radius:4px">'+
                '</div><div style="padding-left:5px">'+this.name+'</div><div>' 
                else
                return '<div style="display:flex"><div style="height:17px;width:17px;background-color:#AEE2D5;'+
                'border:1px solid #15A796;border-radius:4px"></div><div style="padding-left:5px">'+this.name+'</div><div>' 
              },
            },
          },
        },
      ],
    },
  }
}

export default getAgeGenderColumnChartOptions
