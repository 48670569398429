const transformPadding = (value) => {
  const transform = (_value) =>
    typeof _value === 'string' ? _value : `${_value * 0.5}rem`

  return Array.isArray(value)
    ? value.map(transform).join(' ')
    : transform(value)
}

export default transformPadding
