import Highcharts from 'highcharts'
import HC_patternFill from 'highcharts-pattern-fill'
import HC_rounded from 'highcharts-rounded-corners'
HC_rounded(Highcharts)
HC_patternFill(Highcharts)

function getColumnChartOptions(finalData,pointLine,titleText,drillSeleted,setDrillSeleted,setDrillValueSeleted) {
  
  return {
    chart: {
      type: 'column',
      height: 300,
    },
    title: {
      text: 'Race',
      style: {
        color: Highcharts.getOptions().colors[1],
        fontSize: '14px',
      },
    },
    yAxis: {
      title: {
        text: titleText,
        style: {
          color: Highcharts.getOptions().colors[1],
          fontSize: '14px',
        },
      },
     
      labels: {
        style: {
          fontSize: '16px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          return this.total 
        },
      },
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: true,
    },
    xAxis: {
      categories: finalData[0].data,
      crosshair: true,
      labels: {
        style: {
          fontSize: pointLine?'12px':'16px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
      },
      plotLines:pointLine? [
        {
        color: '#fff',
        width: 2,
         value: .1,
        zIndex: 5,
        label: {
           x: -30,
          rotation: 0,
          text: 'Socioeconomic Status' ,
          style: { color: '#4B4D4F', fontSize: '12px' },
        },
    },
    {
      color: '#CBCCCD',
      width: 2,
      value: 4.5,
      zIndex: 5,
      label: {
        //  x: -300,
        rotation: 0,
        text: 'Household Characteristics' ,
        style: { color: '#4B4D4F', fontSize: '12px' },
      },
  },
  {
    color: '#CBCCCD',
    width: 2,
    value: 9.5,
    zIndex: 5,
    label: {
      // x: -75,
      rotation: 0,
      text: 'Racial &...' ,
      style: { color: '#4B4D4F', fontSize: '12px' },
    },
},
{
   color: '#CBCCCD',
  width: 2,
  value: 10.5,
  zIndex: 5,
  label: {
    // x: -300,
    rotation: 0,
    text: 'Household Characteristics' ,
    style: { color: '#4B4D4F', fontSize: '12px' },
  },
}]:[],
      
    },
    tooltip: {
      enabled: false, //Added to disable tooltip
      formatter: function () {
        return (
          '<b>' +
          this.x +
          '</b><br/>' +
          this.series.name +
          ': ' +
          this.y +
          '<br/>'
        )
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: false,
          formatter: function () {
            return this.y 
          },
        },
        enableMouseTracking: true,
      },
      series: {
        borderRadiusTopLeft: '30%',
        borderRadiusTopRight: '30%',
        cursor: 'pointer',
        events: {
            click: function (event) {
              
             
              var str=event.point.category
              if(drillSeleted<3)
              { 
              setDrillSeleted(drillSeleted+1)
              setDrillValueSeleted(str.substring(3,str.indexOf('</b>')))
              }
       
            }
        }
      },
    },
    defs: {
      patterns: [
        {
          id: 'custom-pattern',
          path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            stroke: 'white',
            strokeWidth: 1.5,
            fill: '#c71e83',
          },
        },
      ],
    },
    series: [
      {
        name: finalData[1].name,
        data: finalData[1].data,
        showInLegend: false,
        color: '#15A796',
        enableMouseTracking: true,
        pointWidth: 20,
      },
    ],
    responsive: {
      rules: [
        {
          condition: {},
          chartOptions: {
            legend: {
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'right',
              labelFormatter: function () {
                return this.name + ': ' + this.y 
              },
            },
          },
        },
      ],
    },
  }
}

export default getColumnChartOptions
