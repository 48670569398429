const drilldownFilterOptions = [
  {
    label:"disease",
    options:[ { value: '0', label: 'Respiratory Conditions    >    Acuity    >    Comorbidity' },
    { value: '1', label: 'Respiratory Conditions    >    Comorbidity    >    Acuity' }]
  },
  {
    label:"acuity",
    options:[ { value: '2', label: 'Acuity   >   Respiratory Conditions   >   Comorbidity' },
    { value: '3', label: 'Acuity   >   Comorbidity   >   Respiratory Conditions' }]
  },
  {
    label:"comorbidity",
    options:[ { value: '4', label: 'Comorbidity   >   Respiratory Conditions   >   Acuity' },
    { value: '5', label: 'Comorbidity   >   Acuity   >   Respiratory Conditions' }]
  }
  ]
  export default drilldownFilterOptions
  