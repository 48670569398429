import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useGeoState, useGeoFetch } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import DisplayDateFluNew from '../../../components/DisplayDateFlu/DisplayDateFluNew'
import Spinner from '../../../components/Loader/Spinner'
import EmptyMap from '../../../components/Map/emptyMap'
import Timeline from '../../../components/Timeline'
import Map from '../../../components/Map'
import toolTipFormatter from '../toolTipFormatter'
import MapLegend from '../cdcMapLegendHorizontal'
import SideTable from './SideTable'
import SideTableMain from '../SideTable'
import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import ErrorLogs from '../../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../../components/AuthProvider'
import { object } from 'yup'
const DashboardMap = ({ geo_type, geo_parent, zoomLocation, sidetableData }) => {
  const { user } = useAuthState()
  const chartRef = useRef(null)
  const slotTimeInSeconds = 604800000
  const latestPositionString = ''
  const slotString = 'week'
  const location = useLocation()
  const { loc, area } = useParams()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [metros, setMetros] = useState()
  const [activeCbsa, setActiveCbsa] = useState()
  const [getZIP3Stats, setZIP3Stats] = useState()


  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)

  const [getStateJson, setStateJson] = useState()
  const {
    dataStartDate,
    dataEndDate,
    mapOptions,
    regionSelected,
    setRegionSelected,
    showProvidersMap,
  } = useGeoState()
  const [getProvider, setProvider] = useState()
  if (area === 'ZIP3') {
    setRegionSelected(area.toLowerCase())
  }
  const { data: statesData, error: MapError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    'STATE/?geo_parent=US'
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  const { data: msaData, error: MapmsaError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluMap',
    'ZIP3/?geo_parent=' + geo_parent
  )

  useEffect(() => {
    if (msaData !== null && msaData !== undefined) {
      if (msaData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Msa Dashboard success',
          user: user,
        })
        
        setMetros(msaData.response_data)
      }
      else if (msaData.response_code === 404) {
        
        setMetros(msaData.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${msaData.response_subject}, ${msaData.response_message},for ${geo_parent}`,
          user: user,
        })
      }
      else {
        setErrorCode(msaData.response_code)
        setErrorMessage(msaData.response_message)
        setErrorSubject(msaData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaData])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluLocations',
    'activelocations?geo_type=ZIP3&geo_parent_code=' + geo_parent
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')

  let type = hash.length > 0 ? hash.replace('#', '') : ''


  const { data: zip3Stats, error: zip3DataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluStats',
    'ZIP3/'
  )

  useEffect(() => {
    if (zip3Stats !== null && zip3Stats !== undefined) {
      if (zip3Stats.response_code === 200) {
        setZIP3Stats(zip3Stats.response_data)
      } else if (zip3Stats.response_code === 404) {
        setZIP3Stats(zip3Stats.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${zip3Stats.response_subject}, ${zip3Stats.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        // setErrorCode(zip3Stats.response_code)
        // setErrorMessage(zip3Stats.response_message)
        // setErrorSubject(zip3Stats.response_subject)
        // seterrorOccured(true)
      }
    }
  }, [zip3Stats])


  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])

  const [getzip3Json, setzip3Json] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/three_dig_zips.json`
    fetch(url)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        setzip3Json(data);
      })
      .catch(function (err) {
        console.log(err, " error file load us_hrr_values ");
      });
  }, [1]);
  if (

    MapError ||
    errorOccured ||
    MapmsaError ||
    activeCbsasDataError
  ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }
  var checkUndefine=zoomLocation
  var zoomForZipState = (zoomLocation === undefined && regionSelected === 'zip3') ? 0 : 2
  zoomLocation = zoomLocation === undefined ? geo_parent : zoomLocation

  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'zip3' &&(
            <>
              {getZIP3Stats !== undefined && activeCbsasData !== undefined && checkUndefine===undefined? (
                <SideTableMain geo_id={zoomLocation}  zoomForZipState={zoomForZipState} stateData={getZIP3Stats} chartRef={chartRef} activezip={activeCbsasData} />
              ) : (
                sidetableData && <SideTable stateData={sidetableData} chartRef={chartRef} />
              )}

              
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'zip3' && (
          <>
            {metros !== undefined &&
              getMapStates !== undefined &&
              activeCbsa !== undefined ? (
              <>

                {getzip3Json && (
                  <>
                    <div className={`${styles.providerHeader}`}>
                      <DisplayDateFluNew
                        showWeek={true}
                        latestPositionString={latestPositionString}
                        slotString={slotString}
                        slotTimeInSeconds={slotTimeInSeconds}
                        diseaseType={'Flu'}
                      />                      
                      <Timeline
                     
                        statesData={Object.keys(metros).length === 0 ?getMapStates: metros}
                        latestPositionString={latestPositionString}
                        slotString={slotString}
                        slotTimeInSeconds={slotTimeInSeconds}
                        markCounts={7}
                      />
                    </div>
                    <div className={styles.legendDiv}>
                <MapLegend />
                </div>
                    <Map
                      toolTipFormatter={toolTipFormatter}
                      activeZip3Data={activeCbsa}
                      statesData={getMapStates}
                      cbsaData={null}
                      mapOptions={mapOptions}
                      dynamicMapTitle={true}
                      showCbsaLabels={true}
                      mapType="ZIP3MAP"
                      enableButtons={true}
                      zoomLocation={zoomLocation}
                      chartRef={chartRef}

                      mapDataType={zoomForZipState}
                      stateJson={getStateJson}
                      dashboardType="FLU"
                      zip3Json={getzip3Json}
                      zip3Data={metros}
                    />

                  </>
                )}

              </>
            ) : (
              <EmptyMap />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardMap
