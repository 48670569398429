import React from "react";
import Layout from '../../components/Layout'
import styles from './AccessAbort.module.scss'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
const AccessAbort = () => {

  const { user } = useAuthState()

  ErrorLogs({message_type: 'Activity',error_details: 'UnAuthorized access (AccessAbort)', user: user})

  return (
    <Layout>    
    <ErrorMessage
        errorCode={'401'}
        errorMessage={''}
        errorSubject={'UnAuthorized Access'}
        errorDetails={'Requsted email id not authorized to access.'}
      />    
</Layout>
  )

  }

export default AccessAbort;
