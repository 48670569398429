import React, { useState, useEffect } from 'react'
import styles from './Common.module.scss'
import closeIcon from '../../assets/images/icon/close_24dp.png'
import Modal from '../../components/ModalPopupProfile'
import { useAuthState } from '../../components/AuthProvider'
import Spinner from '../../components/Loader/Spinner'
import FileBase64 from 'react-file-base64';
import asterisk from '../../assets/images/Asterisk.png'
import styles1 from './IncidentRequest.module.scss'
import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomTextForForm from './formContent'
import Priority from './priority'
import Issuetype from './issuetype'
const IncidentRequest = ({ istrue, sendDataToParent }) => {

    const { user } = useAuthState()
    var name, email, phone
    if (user != undefined && user !== null) {
        name = `${user.firstName}${' '}${user.lastName}`
        email = user.email
        phone = user.phone

    }
    const intialValues = { requester: name, email: email, phone: phone, issuetype: "", company: "", description: "", priority: "", file: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [getIsTrue, setIsTrue] = useState(false);
    const [getFileSize, setFileSize] = useState(false);
    const [getFileExtension, setFileExtension] = useState(false);
    const [loading, setloading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [getIsValueChange, setIsValueChange] = useState(false);
    const [getIsFileValueChange, setIsFileValueChange] = useState(false);
    var MB
    const submit = () => {
        setloading(true)
        setDisabled(true);
        const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/createincident/`
        fetchText(apiUrl)
    };
    async function fetchText(apiUrl) {
        
        let response = await fetch(apiUrl, {
            method: 'post',
            body: JSON.stringify(formValues),
            headers: { 'Content-Type': 'application/json' },
        })
        if (response.status === 200) {
            let data = await response.text();
            data = JSON.parse(data);
            //   data = `{
            //     "transform_map": "incident",
            //     "table": "incident",
            //     "display_name": "number",
            //     "display_value": "INC34192514",
            //     "record_link": "https://optumstage.service-now.com/api/now/table/incident/e38c1b5c1bf971105a757591dd4bcbfe",
            //     "status": "inserted",
            //     "sys_id": "e38c1b5c1bf971105a757591dd4bcbfe"
            // }`

            setIsTrue(true)
            setFormValues({ company: '', phone: phone, description: '', email: email, issuetype: '', requester: name, priority: '', file: "" });
            setloading(false)
            //scrollToTop()
            setDisabled(false);
            toast.success(CustomTextForForm.success + data.display_value, {
                toastId: 'success1',
            })
        }
    }

    // const scrollToTop = () => {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth'
    //   });
    // };

    //input change handler
    const handleChange = (evt) => {
        
        setIsValueChange(true)
        var { name, value } = evt.target;
        setFormValues({ ...formValues, [name]: value });
        // setFormErrors(validate(formValues));
        // setIsSubmitting(false);


    };
    const handleInput = (e) => {
        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = '(' + x[1] + ') ' + x[2] + '-' + x[3]
        var { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    //form submission handler
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
        setIsFileValueChange(true)
    }
    const fileSize = true; 
    const fileExtension = true; 

    const getFiles = (files) => {
        
        setIsFileValueChange(true)
        setIsSubmitting(false);
      
        var base64String = files.base64.split(',')[1];
        var sizeInBytes = 4 * Math.ceil((base64String.length / 3)) * 0.5624896334383812;
        var sizeInMB = (sizeInBytes / 1024) / 1024;
         MB = Math.round(sizeInMB);
        if (MB > 4) {
            setFileSize(true)
            setFormErrors(validateFile(formValues, fileSize, false));
        }
        else if (isValid(files.name)) {
            setFileExtension(true)
            setFormErrors(validateFile(formValues, false, fileExtension));      
        }
        else {
           
            setFormErrors(validateFile(formValues, false, false));
            setFormValues({ ...formValues, ["file"]: files });
            // setFileSize(false)
            // setFileExtension(false)
        }

    }
    function getExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }
    function isValid(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'png':
            case 'json':
            case 'txt':
            case 'docx':
            case 'html':
                //etc
                return false;
        }
        return true;
    }

    //form validation handler
    const validateFile = (values, istrue, isExtension) => {
        let errors = {};
        if (istrue && values) {
            errors.file = "file size can not be more than 5 MB";
            return errors
        }
        else if (isExtension && values) {
            errors.file = " .jpg, .png, .docs, .html, .json, .txt only allow.";
            return errors
        }
        return errors;
    }

    const validate = (values, istrue) => {
        
        let errors = {};
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{3,3}$/g;
        const regexUSNumber = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

        if (!values.requester) {
            errors.requester = CustomTextForForm.requester.empty_msg;
            return errors;
        } else if (values.requester.length < CustomTextForForm.requester.min) {
            errors.requester = CustomTextForForm.requester.length_msg_min;
            return errors;
        }
        else if (values.requester.length > CustomTextForForm.requester.max) {
            errors.requester = CustomTextForForm.requester.length_msg_max;
            return errors;
        }
        if (!values.email) {
            errors.email = CustomTextForForm.email.emailBlankMsg;
            return errors;
        } else if (!regex.test(values.email)) {
            errors.email = CustomTextForForm.email.emailInvalidMsg;
            return errors;
        }

        if (values.phone != undefined && (values.phone.length > CustomTextForForm.phone.min && !regexUSNumber.test(values.phone))) {
            errors.phone = CustomTextForForm.phone.invalidFormat;
            return errors;
        }
        if (!values.priority || values.priority == "-1") {
            errors.priority = CustomTextForForm.priority.empty_msg;
            return errors;
        } else if (values.priority.length <= CustomTextForForm.priority.min) {
            errors.priority = CustomTextForForm.priority.empty_msg
            return errors;
        }
        if (!values.issuetype || values.issuetype == "-1") {
            errors.issuetype = CustomTextForForm.issuetype.empty_msg;
            return errors;
        } else if (values.issuetype.length <= CustomTextForForm.issuetype.min) {
            errors.issuetype = CustomTextForForm.issuetype.empty_msg;
            return errors;
        }

        if (!values.company) {
            errors.company = CustomTextForForm.company.empty_msg
            return errors;
        } else if (values.company.length < CustomTextForForm.company.min) {
            errors.company = CustomTextForForm.company.length_msg_min
            return errors;
        }
        else if (values.company.length > CustomTextForForm.company.max) {
            errors.company = CustomTextForForm.company.length_msg_max
            return errors;
        }
        if (!values.description) {
            errors.description = CustomTextForForm.description.empty_msg
            return errors;
        } else if (values.description.length < CustomTextForForm.description.min) {
            errors.description = CustomTextForForm.description.length_msg_min
            return errors;
        }
        else if (values.description.length > CustomTextForForm.description.max) {
            errors.description = CustomTextForForm.description.length_msg_max
            return errors;
        }
        if (istrue) {
            errors.file = CustomTextForForm.file.size_msg;
            return errors;
        }
        return errors;
    };

    useEffect(() => {
        var istrue
        if(getFileSize)
        {
            setFormErrors(validateFile(formValues, true, false));
            //setFileSize(false)
            istrue=true;
        }        
        if(getFileExtension)
        {
            setFormErrors(validateFile(formValues, false, true));
            //setFileExtension(false)
        }
        // if (getIsFileValueChange && MB > 4) {
        //     setFormErrors(validateFile(formValues, fileSize, false));
        //     setIsSubmitting(false);
        //     setIsFileValueChange(false);
        // }

        if (getIsValueChange) {
            setFormErrors(validate(formValues));
            setIsSubmitting(false);
            setIsValueChange(false);
        }
        if (Object.keys(formErrors).length === 0 && isSubmitting && (!getFileSize && !getFileExtension)) {
            submit();
            setIsSubmitting(false);
        }
    }, [  formErrors, formValues,getFileSize ,getFileExtension, istrue]);

    return (
        <>
            {/* onClose={sendDataToParent} */}
            <Modal open={istrue} >
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '19%' }}>
                        <span className={styles.captionFormat}>{CustomTextForForm.header}</span>
                    </div>

                    <div>
                        <img
                            className={styles.closePopup}
                            src={closeIcon}
                            alt="Close"
                            onClick={sendDataToParent}
                        ></img>
                    </div>
                </div>

                <div style={{ marginBottom: '1rem' }}>
                    <div className={styles1.modal}>
                        <div className={styles1.base}>
                            <div className={styles1.maindivFormat}>

                                <div style={{ marginTop: '0px' }}>
                                    <div className={styles1.container}>
                                        <form noValidate>
                                            <div className={styles1.formrow}>
                                                <label htmlFor="requester">{CustomTextForForm.requester.labeltxt}<img
                                                    src={asterisk}
                                                    alt="arrow-down"
                                                    style={{
                                                        height: '10px',
                                                        width: '8px',
                                                        marginLeft: '3px',
                                                    }}
                                                ></img>&nbsp;&nbsp;</label>

                                                <div>
                                                    <input
                                                        type="requester"
                                                        name="requester"
                                                        id="requester"
                                                        maxLength="40"
                                                        value={formValues.requester}
                                                        onChange={handleChange}
                                                       
                                                        className={formErrors.requester && styles1.inputerror}
                                                    />
                                                    <br />
                                                    {formErrors.requester && (
                                                        <span className={styles1.error}>{formErrors.requester}</span>
                                                    )}
                                                </div>

                                            </div>

                                            <div className={styles1.formrow}>
                                                <label htmlFor="email">{CustomTextForForm.email.labeltxt}<img
                                                    src={asterisk}
                                                    alt="arrow-down"
                                                    style={{
                                                        height: '10px',
                                                        width: '8px',
                                                        marginLeft: '3px',
                                                    }}
                                                ></img>&nbsp;&nbsp;</label>
                                                <div>  <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    maxLength="50"
                                                    value={formValues.email}
                                                    onChange={handleChange}
                                                    className={formErrors.email && styles1.inputerror}
                                                />
                                                    <br />
                                                    {formErrors.email && (
                                                        <span className={styles1.error}>{formErrors.email}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles1.formrow}>
                                                <label htmlFor="phone">{CustomTextForForm.phone.labeltxt}</label>
                                                <div><input
                                                    type="phone"
                                                    name="phone"
                                                    id="phone"
                                                    maxLength="15"
                                                    placeholder="(555) 555-1234"
                                                    value={formValues.phone}
                                                    onChange={handleInput}

                                                    className={formErrors.phone && styles1.inputerror}
                                                />
                                                    <br />
                                                    {formErrors.phone && (
                                                        <span className={styles1.error}>{formErrors.phone}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles1.formrow}>
                                                <label htmlFor="priority">{CustomTextForForm.priority.labeltxt}<img
                                                    src={asterisk}
                                                    alt="arrow-down"
                                                    style={{
                                                        height: '10px',
                                                        width: '8px',
                                                        marginLeft: '3px',
                                                    }}
                                                ></img>&nbsp;&nbsp;</label>
                                                <div>
                                                    <select  name="priority" id="priority"
                                                        value={formValues.priority}
                                                        onChange={handleChange}
                                                        className={formErrors.priority && styles1.inputerror}>
                                                        <option value="-1">Select</option>
                                                        {
                                                            Priority.map((h, i) =>
                                                                (<option key={i} value={h.value}>{h.text}</option>))
                                                        }
                                                    </select>
                                                    <br />
                                                    {formErrors.priority && (
                                                        <span className={styles1.error}>{formErrors.priority}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className={styles1.formrow}>
                                                <label htmlFor="issuetype">{CustomTextForForm.issuetype.labeltxt}<img
                                                    src={asterisk}
                                                    alt="arrow-down"
                                                    style={{
                                                        height: '10px',
                                                        width: '8px',
                                                        marginLeft: '3px',
                                                    }}
                                                ></img>&nbsp;&nbsp;</label>
                                                <div>
                                                    <select name="issuetype" id="issuetype"
                                                        value={formValues.issuetype}
                                                        onChange={handleChange}
                                                        className={formErrors.issuetype && styles1.inputerror}>
                                                        <option value="-1">Select</option>
                                                        {
                                                            Issuetype.map((h, i) =>
                                                                (<option key={i} value={h.value}>{h.text}</option>))
                                                        }
                                                    </select>
                                                    <br />
                                                    {formErrors.issuetype && (
                                                        <span className={styles1.error}>{formErrors.issuetype}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className={styles1.formrow}>
                                                <label htmlFor="company">{CustomTextForForm.company.labeltxt}<img
                                                    src={asterisk}
                                                    alt="arrow-down"
                                                    style={{
                                                        height: '10px',
                                                        width: '8px',
                                                        marginLeft: '3px',
                                                    }}
                                                ></img>&nbsp;&nbsp;</label>
                                                <div> <input
                                                    type="company"
                                                    name="company"
                                                    id="company"
                                                    maxLength="50"
                                                    value={formValues.company}
                                                    onChange={handleChange}
                                                    className={formErrors.company && styles1.inputerror}
                                                />
                                                    <br />
                                                    {formErrors.company && (
                                                        <span className={styles1.error}>{formErrors.company}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles1.formrow}>
                                                <label htmlFor="description">{CustomTextForForm.description.labeltxt}<img
                                                    src={asterisk}
                                                    alt="arrow-down"
                                                    style={{
                                                        height: '10px',
                                                        width: '8px',
                                                        marginLeft: '3px',
                                                    }}
                                                ></img>&nbsp;&nbsp;</label>
                                                <div> <textarea
                                                    rows="7"
                                                    cols="65"
                                                    type="description"
                                                    name="description"
                                                    id="description"
                                                    maxLength="2000"
                                                    value={formValues.description}
                                                    onChange={handleChange}
                                                    className={formErrors.description && styles1.inputerror}
                                                />
                                                    <br />
                                                    {formErrors.description && (
                                                        <span className={styles1.error}>{formErrors.description}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles1.formrow}>
                                                <label htmlFor="Attachments">{CustomTextForForm.file.labeltxt}</label>
                                                <div className={styles1.inputfile}>
                                                    <FileBase64
                                                        name="file" id="file"
                                                        onDone={getFiles.bind(this)} />
                                                    <br />
                                                    {formErrors.file && (
                                                        <span className={styles1.error}>{formErrors.file}</span>
                                                    )}
                                                </div>
                                            </div>

                                            <div style={{ paddingTop: '60px' }}>
                                                <> {
                                                    loading &&
                                                    <Spinner />}
                                                    <div>
                                                        <button onClick={sendDataToParent} disabled={disabled} className={styles1.cancel} type="button">Cancel</button>
                                                        <button onClick={handleSubmit} disabled={disabled} className={styles1.submit} type="submit">Submit</button>
                                                    </div>
                                                </>
                                            </div>
                                            <div>
                                                <ToastContainer
                                                    position="bottom-right"
                                                    autoClose={200000}
                                                    hideProgressBar={true}
                                                    newestOnTop={false}
                                                    closeOnClick
                                                    rtl={false}
                                                    pauseOnFocusLoss
                                                    draggable
                                                    pauseOnHover
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default IncidentRequest