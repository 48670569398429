import LogoRx from '../../assets/images/Optumrx.svg'
const Logo = () => {
  return (
    <div>
      <img src={LogoRx} alt="Optum Rx" style={{ height: '20px', width: '100px' }}></img>
    </div>
  )  
}

export default Logo
