import React from "react";
import { useMedia } from "../ResponsiveMedia";
import transformGutter from "./transformGutter";
import FlexContext from "./FlexContext";
import Item from "./Item";

const Flex = ({ children = null, gutter }) => {
  const margin = useMedia(gutter, value => transformGutter(value, true));

  return (
    <FlexContext.Provider value={{ gutter }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          margin
        }}
      >
        {children}
      </div>
    </FlexContext.Provider>
  );
};

Flex.Item = Item;

export default Flex;
