import { useState } from 'react'
import getThresholdValue from '../../utils/getThresholdValue'
export default function MapLegend(props) {
  const provider = props.provider 
  const geojson = props.geojson
  const thresholdVal = props.thresholdVal
  const[getistrue, setistrue]=useState(true)
  if (provider)
  {
    const removelayercolor = () => {
      debugger
       setistrue(!getistrue)
      //const refMap = msaRef
      const refStateMap = geojson
      //const mapLayer   =  regionSelected === 'metro' ? msaRef : regionSelected === 'zip3' ? zip3Ref: stateRef
      refStateMap.current.eachLayer(function (layer) {   
        debugger   
        layer.setStyle({
        fillColor: getistrue ? 'none': getThresholdValue(thresholdVal).color,
        weight: 1,
        opacity: 1,
        color: getThresholdValue(thresholdVal).OutlineColor,
        dashArray: '1.5',
        fillOpacity: 0.7,
      })
      })
    }
       
    const svgstyle = {
      viewBox:"0 0 90 150",
      height: "19",
      width: (thresholdVal===5 || thresholdVal===1) ? "85": thresholdVal===3 ? "80": "55",
      align: "center",
      MarginTop:"10",
    };
    const rectstyle = {
      x:"1",
      y:"1",
      width:"16",
      height:"16",
      fill:getThresholdValue(thresholdVal).color,
      stroke:getThresholdValue(thresholdVal).OutlineColor,
      strokeWidth:"1px",
    };
    return (
       <svg
       style={svgstyle}
      {...props}
    >
        <rect
        
          style={rectstyle}
      ></rect> 

      <text
       
        x="25"
        y="13"
        style={{
          width: '57px',
          height: '16px',
         fontWeight:'400',
          fontSize: '12.64px',
          fill: '#0c55b8',         
          cursor:'pointer',
          textDecoration: 'underline'
        }}
      >
      <a  onClick={removelayercolor} >{ getThresholdValue(thresholdVal).threshold}</a>  
      </text>  
      </svg>)
  }
  else {
  return (
    <svg
      viewBox="0 0 90 150"
      height="150"
      width="90"
      align="center"
      margin-top="0"
      {...props}
    >
        <rect
        x="1"
        y="0"
        width="16"
        height="16"
        fill="#C40000"
        stroke="#FFFFFF"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="25"
        width="16"
        height="16"
        fill="#FB8D7B"
        stroke="#990000"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="50"
        width="16"
        height="16"
        fill="#FFFFF0"
        stroke="#AD8D01"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="75"
        width="16"
        height="16"
        fill="#6CB2A2"
        stroke="#0D5E4F"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="100"
        width="16"
        height="16"
        fill="#218371"
        stroke="#FFFFFF"
        strokeWidth="1px"
      ></rect>
      <rect
        x="1"
        y="130"
        width="16"
        height="16"
        fill="#6D6F70"
        stroke="#6D6F70"
        strokeWidth="1px"
      ></rect>

      <text
        x="25"
        y="13"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Very High
      </text>
      <text
        x="25"
        y="39"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        High
      </text>
      <text
        x="25"
        y="63"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Medium
      </text>
      <text
        x="25"
        y="87"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Low
      </text>
      <text
        x="25"
        y="113"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Minimal
      </text>

      <path stroke="#CBCCCD" d="M 0 123 L 18 123" fill="#CBCCCD"></path>

      <text
        x="25"
        y="142"
        style={{
          width: '57px',
          height: '16px',
          fontw: 400,
          fontSize: '12.64px',
        }}
      >
        No Data
      </text>
    </svg>
  )
      }
}