import React from "react";
import Menu from "../Menu";

import styles from "./Item.module.scss";

const Item = ({ active: isActive, ...rest }) => (    
  <Menu.Item
    className={`${styles.base} ${isActive ? styles.active : ""}`}
    {...rest}
  />  
);

export default Item;
