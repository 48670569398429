import React,{useState} from 'react'
import styles from './FluIndices.module.scss'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import GeoProvider from '../../components/GeoProvider'
import DashboardMap from './DashboardMap'
import dataDisplayOptions from './dataDisplayOptions'
import { startDate, endDate, displayDate } from './dates'
import Header from './Header'
import Gender from '../GenderAge'
import SeasonalPattern from '../SeasonalPattern'
import { useLocation } from "react-router-dom";
import AllDashboard from './AllDashboard'
const Index = () => {
  
  var getRegionType = 'state'
  const location = useLocation()
  const hash = location.hash
  if (hash) {
    if (hash.length > 1 && hash === '#zip3') {
      getRegionType = 'zip3'
    }
  }

  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.body}>
            <GeoProvider            
              startDate={startDate}
              endDate={endDate}
              displayDate={displayDate}
              mapOptions={dataDisplayOptions[0]['options'][0]}
              indicatorName={dataDisplayOptions[0]['options'][0].indicatorName}
              regionSelected={getRegionType}
             
              geoid={'NAT'}
              geotype={'NAT'}
            >
              <AllDashboard/>
            </GeoProvider>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Index
