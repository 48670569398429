import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
// import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu'
// import '@szhsin/react-menu/dist/index.css'
import styles from './index.module.scss'
import HeaderLinks from '../../components/HeaderLinks'
import Menu from '../../components/Menu'
import getVectorImage from '../../utils/getVectorImage'
import { MenuItem } from '@szhsin/react-menu'
import {useAuthState} from '../../components/AuthProvider'
import typeDisplayOptions from '../../pages/Dashboard/typeDisplayOptions'
const SelectMenuList = ({ dropdownData, defaultValue, defaultLabel ,selectType,setDrillDownSelectedValueMethod}) => {

  const {  user } = useAuthState()
  
  const [display] = useState('default')
  const [align] = useState('start')
  const [position] = useState('anchor')
  const [viewScroll] = useState('auto')
  const history = useHistory()
  const [showMore, setShowMore] = useState(false)
  const [selectedValue, setSelectedValue] = useState(defaultValue)
  const [selectedLabel, setSelectedLabel] = useState(defaultLabel)

  const handleClick = (valid) => {
    setShowMore(!valid)
  }
  const handleClick2 = (value, label) => {
    
    if(selectType==='drilldown')
    setDrillDownSelectedValueMethod(value)
    setSelectedValue(value)
    setSelectedLabel(label)
    switch (value) {
      case 'home':
        history.push('/Dashboard')
        break
      case 'rsv':
        history.push('/RSVDashboard')
        break
      case 'flu':
        history.push('/FluDashboard')
        break
      case 'covid':
        history.push('/CovidDashboard')
        break
        case 'vulnerability':
        history.push('/VulnerabilityDashboard')
        break
        case 'flunew':
          history.push('/flunew')
          break         
        
      default:
        break
    }
  }

  dropdownData = user.role==='External'? typeDisplayOptions[1]: typeDisplayOptions[0]
  return (
    <>
       <HeaderLinks >
            <>
              <HeaderLinks.Item label={selectedLabel} menuType='other' selectLabel={selectedLabel} >
              <Menu 
        >
          {dropdownData.map((option) => {
              
            return (
              <Menu.Item
                label={option.label}
                key={option.value}
                onClick={(event) => handleClick2(option.value, option.label)}
                menuType='other'
                selectLabel={selectedLabel} 
              />
             
            )
          })}
        </Menu>
              </HeaderLinks.Item>
            </>
          
        </HeaderLinks>
    </>
  )
}
export default SelectMenuList
