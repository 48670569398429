import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './Dashboard.module.scss'
import GeoProvider from '../../components/GeoProvider'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import ProductLinks from './ProductLinks'
import dataDisplayOptions from './dataDisplayOptions'
import Gender from './GenderAge'
import RaceEthnicityGraph from '../RaceEthnicity'
import RSVMap from './RSVMap'
import FluMap from './FluMap'
import CovidMap from './CovidMap'
import VulnerabilityMap from './VulnerabilityMap'
import { startDate, endDate, displayDate } from './dates'
import SelectList from '../../components/SelectMenuList'
import typeDisplayOptions from './typeDisplayOptions'
import {useAuthState} from '../../components/AuthProvider'
import SystemNotification from './SystemNotification'
const Dashboard = () => {
  const {  user } = useAuthState()
  const history = useHistory()
  return (
    <Layout>
      <Content>
      <GeoProvider >
        <SystemNotification/>
      </GeoProvider>
      
        <div className={styles.base}>
        
          <div className={styles.body}>
          
            <div className={styles.pageHeader}>
              <div className={styles.pageHeaderText}>
                <SelectList
                  dropdownData={typeDisplayOptions}
                  defaultValue={'home'}
                  defaultLabel={'My Dashboard'}
                />
              </div>
            </div>
            <div className={styles.pageMaps}>
              <div className={styles.rsvPanel}>
                <a
                  href=""
                  onClick={() => {
                    history.push('/RSVDashboard')
                  }}
                >
                  <div className={styles.dashboardCard}>
                    <div className={styles.dashboardCardHeader}>
                      <div className={styles.mapHeaderDiv}>
                        <span className={styles.mapName}>RSV Incidence</span>
                      </div>
                    </div>

                    <GeoProvider
                      startDate={startDate}
                      endDate={endDate}
                      displayDate={displayDate}
                      mapOptions={dataDisplayOptions[0]['options'][0]}
                      regionSelected={'state'}
                    >
                      {' '}
                      <RSVMap />
                    </GeoProvider>
                  </div>
                </a>
              </div>
              <div className={styles.fluPanel}>
                <a
                  href=""
                  onClick={() => {
                    history.push('/FluDashboard')
                  }}
                >
                  <div className={styles.dashboardCard}>
                    <div className={styles.dashboardCardHeader}>
                      <div className={styles.mapHeaderDiv}>
                        <span className={styles.mapName}>Flu Incidence</span>
                      </div>
                    </div>

                    <GeoProvider
                      startDate={startDate}
                      endDate={endDate}
                      displayDate={displayDate}
                      mapOptions={dataDisplayOptions[0]['options'][0]}
                      regionSelected={'state'}
                    >
                      {' '}
                      <FluMap />
                    </GeoProvider>
                  </div>
                </a>
              </div>
              <div className={styles.covidPanel}>
                <a  href=""
                  onClick={() => {
                    history.push('/CovidDashboard')
                  }}>
                  <div className={styles.dashboardCard}>
                    <div className={styles.dashboardCardHeader}>
                      <div className={styles.mapHeaderDiv}>
                        <span>COVID-19 Incidence</span>
                      </div>
                    </div>
                    <GeoProvider
                      startDate={startDate}
                      endDate={endDate}
                      displayDate={displayDate}
                      mapOptions={dataDisplayOptions[0]['options'][0]}
                      regionSelected={'state'}
                    >
                      {' '}
                      <CovidMap />
                    </GeoProvider>
                  </div>
                </a>
              </div>
            </div>
            <div className={styles.pageMaps}>
              <div className={styles.vulPanel} 
              style={{display:user.role==='External'?'none':'block'}}>
                <a
                  href=""
                  onClick={() => {
                    history.push('/VulnerabilityDashboard')
                  }}
                >
                  <div className={styles.dashboardCard}>
                    <div className={styles.dashboardCardHeader}>
                      <div className={styles.mapHeaderDiv}>
                        <span className={styles.mapName}>Vulnerability</span>
                      </div>
                    </div>

                    <GeoProvider
                      startDate={startDate}
                      endDate={endDate}
                      displayDate={"October 2023"}
                      mapOptions={dataDisplayOptions[0]['options'][1]}
                      regionSelected={'state'}
                    >
                      {' '}
                      <VulnerabilityMap />
                    </GeoProvider>
                  </div>
                </a>
              </div>
              
            </div>
            <div className={styles.productLinks}>
              <ProductLinks />
            </div>
            <div className={styles.ageGenderGraph}>
              <span className={styles.heading}>United States Census Bureau Population Data</span>
              <div className={styles.graphPanel}>
                <Gender geo_type="NAT" geo_id="NAT" />
              </div>
            </div>
            <div className={styles.raceEthnicity}>
              <RaceEthnicityGraph geo_type="NAT" geo_id="NAT" />
            </div>

          
           
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Dashboard
