import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMap from 'highcharts/modules/map'
import * as Exporting from 'highcharts/modules/exporting'
import * as OfflineExport from 'highcharts/modules/offline-exporting'
import { useHistory } from 'react-router-dom'
import { useGeoState } from '../GeoProvider'
import Spinner from '../Loader/Spinner'
import getMapOptions from './getMapOptions'
import MapBordercolor from '../../utils/MapBordercolor'
import usStateDummy from './dummyStats'
function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}'
}
const Map = ({
  toolTipFormatter,
  activeCbsasData,
  statesData,
  cbsaData,
  mapOptions,
  showStateLabels,
  showCbsaLabels,
  enableTooltip,
  enableMapNavigation,
  enableMouseTracking,
  mapHeight = '70vh',
  mapWidth = '',
  mapTitleDisable,
  mapType,
  enableButtons = false,
  zoomLocation = '',
  chartRef,
  mapDataType,
  JsonData,
  stateJson,
  countyGeoJson,
  dashboardType,
  zip3Json,
  zip3Data,
  countyData,
  activeZip3Data,
  highlightCBSA = false,
  exportMap = false,
}) => {
  if (typeof Highcharts === 'object') {
    HighchartsMap(Highcharts)
  }
  const { displayDate, regionSelected } = useGeoState()

  if (exportMap) {
    Exporting(Highcharts)
    OfflineExport(Highcharts)
  }

  const history = useHistory()
  let mapActiveCbsaData = null
  let mapActiveZIP3Data = null
  let mapStatesData = null
  let mapCbsaData = null
  let mapMsaData = null
  let zip3DataFiltered = null
  let statesDataForDisplayDate = null
  if (
    mapType === 'RSVMAP' ||
    mapType === 'RSVMAPMSA' ||
    mapType === 'RSVMAPCDO' ||
    mapType === 'ZIP3MAP'
  ) {
    if (mapType === 'ZIP3MAP') {
      var datacount = Object.keys(statesData).length - 1
      let lastData = Object.keys(statesData)[datacount]
      if (displayDate > lastData) {
        statesDataForDisplayDate = Object.entries(usStateDummy)
      } else {
        statesDataForDisplayDate = Object.entries(statesData[displayDate])
      }
    } else {
      statesDataForDisplayDate = Object.entries(statesData[displayDate])
    }

    mapStatesData = statesDataForDisplayDate.map((state) => {
      return {
        value: state[1][mapOptions.value],
        state: state[0],
        borderColor: MapBordercolor(state[1][mapOptions.value], dashboardType),
        ...state[1],
      }
    })
  }
  if (mapType === 'ZIP3MAP' && zip3Data && !isEmptyObject(zip3Data)) {
    const zip3DataForDisplayDate = Object.entries(zip3Data[displayDate])
    zip3DataFiltered = zip3DataForDisplayDate.map((state) => {
      return {
        value: state[1][mapOptions.value],
        state: state[0],
        borderColor: MapBordercolor(state[1]['forecast_level'], dashboardType),
        ...state[1],
      }
    })
    mapActiveZIP3Data = activeZip3Data.map((zip3) => {
      return zip3.toString()
    })
  }
  if (
    (mapType === 'RSVMAPMSA' && cbsaData && !isEmptyObject(cbsaData)) ||
    (mapType === 'RSVMAPCDO' && cbsaData && !isEmptyObject(cbsaData))
  ) {
    const cbsaDataForDisplayDate = Object.entries(cbsaData[displayDate])
    mapCbsaData = cbsaDataForDisplayDate.map((cbsa) => {
      return {
        value: cbsa[1][mapOptions.value],
        state: cbsa[0].toString(),
        borderColor: MapBordercolor(cbsa[1][mapOptions.value], dashboardType),
        ...cbsa[1],
      }
    })
    mapActiveCbsaData = activeCbsasData.map((cbsa) => {
      return cbsa.toString()
    })
  }

  let title = mapOptions.mapTitle
  if (!(title === null || title === undefined)) {
    title = ''
  }

  const graphOptions = getMapOptions(
    mapOptions.colorAxis,
    mapOptions.legend,
    toolTipFormatter,
    mapActiveCbsaData,
    mapCbsaData,
    mapStatesData,
    mapMsaData,
    history,
    mapType,
    enableButtons,
    zoomLocation,
    mapDataType,
    JsonData,
    stateJson,
    countyData,
    countyGeoJson,
    dashboardType,
    title,
    mapOptions.mapSubTitle,
    showStateLabels,
    showCbsaLabels,
    enableTooltip,
    enableMapNavigation,
    enableMouseTracking,
    zip3Json,
    zip3DataFiltered,
    mapActiveZIP3Data,
    highlightCBSA,
    exportMap
  )

  return mapOptions ? (
    <>
      <HighchartsReact
        containerProps={{
          style: {
            height: mapHeight,
            width: mapWidth,
            margin: '0 auto',
            position: 'relative',
            display: 'flex',
          },
        }}
        constructorType="mapChart"
        highcharts={Highcharts}
        options={graphOptions}
        allowChartUpdate
        updateArgs={[true, true, false]}
        ref={chartRef}
      />
    </>
  ) : (
    <Spinner />
  )
}

export default Map
