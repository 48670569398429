import React,{useState} from 'react'
import { useHistory } from 'react-router-dom'
import Links from './Links'
import FooterContent from './Content'
import Link from './Link'
import Logo from '../Logo'
import LinkIcon from './../../assets/images/Icon-Link-External-Site.png'
import IncidentRequest from '../../pages/ServiceNow'
// eslint-disable-next-line no-unused-vars
import { PRIVACY_POLICY, TERMS_OF_USE } from './constants'

import styles from './Footer.module.scss'



const Footer = ({ appName, logoUrl, children = null }) => {  
  const history = useHistory()
  const [showInfoDialog, setShowInfoDialog] = useState(false)
  function formOpen() {   
    setShowInfoDialog(current => !current);
  }
  const sendDataToParent = () => {    
    setShowInfoDialog(current => !current);
  };
  return (
    <>

      <footer className={styles.base}>
        <div className={styles.footerDiv}>
          <div className={styles.copyright}>
            <div>
              <a className={`${styles.logo} ${styles.pointer}`} href={logoUrl}>
                <Logo color={'#FFFFFF'} />
              </a>
            </div>
          </div>
          <div className={styles.copyrightdes}>
            &copy; 2023 Optum Inc. All rights reserved.
          </div>
        </div>
        <div className={styles.disclaimer}>
          <div className={styles.linksDiv}>
            <ul>
              <li>
                <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                  <img src={LinkIcon} className={styles.iconImage}></img>
                </a>
              </li>
              <li>
                <a href={TERMS_OF_USE} target="_blank" rel="noopener noreferrer">
                  Terms of Use
                  <img src={LinkIcon} className={styles.iconImage}></img>
                </a>
              </li>

              <li>
                {/* <a href={'/incident'} target="_self" rel="noopener noreferrer">
             Report an Issue
              <img src={LinkIcon} className={styles.iconImage}></img>
            </a> */}

                <a onClick={formOpen} style={{ cursor: 'pointer' }} target="_self" rel="noopener noreferrer">
                  Report an Issue
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.DisclaimerDiv}>
            <p className={styles.contentFormat}>
              The content, forecasts, and predictions offered are for informational purposes only, and nothing in the forecasts, predictions and related information should be considered, or used as a substitute for, medical advice, diagnosis or treatment.   Users should seek the advice of a physician or other qualified health care provider with any questions regarding personal health or medical conditions. Optum and its affiliates and licensors make no representations or warranties of any kind, express or implied, with respect to these forecasts, predictions, any related data, and/or the techniques used to create such forecasts and predictions, including, but not limited to, any warranties of performance, merchantability, fitness for a particular purpose, title/non-infringement, and quality, accuracy and/or completeness of these forecasts, predictions, and related information. Any interpretation or use of these forecasts, predictions, and related information is solely and exclusively at your own discretion and risk, and Optum and its affiliates and licensors will have no liability for any damages or claims relating to such interpretation or use.
            </p>
          </div>
        </div>
      </footer>
      {showInfoDialog &&  <IncidentRequest  istrue={showInfoDialog} sendDataToParent={sendDataToParent}/>}
    </>

  )
}

Footer.Links = Links
Footer.Content = FooterContent
Footer.Link = Link

export default Footer
