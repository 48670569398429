import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import EmptyMap from '../../components/Map/emptyMap'
import Map from '../../components/Map'
import toolTipFormatter from './toolTipFormatter'
import MapLegend from './MapLegendHorizontal'
import styles from './RSVMap.module.scss'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
import { displayDate } from './dates'
const FluMap = ({ zoomLocation }) => {
  const { user } = useAuthState()
  const chartRef = useRef(null)
  const location = useLocation()
  const hash = location.hash
  const [getMapStates, setMapStates] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getStateJson, setStateJson] = useState()
  const { mapOptions, regionSelected, setRegionSelected } = useGeoState()

  if (hash) {
    if (hash.split('-').length > 1) {
      setRegionSelected(hash.split('-')[0].replace('#', ''))
    }
  }
  const { data: statesData, error: MapError } = useGeoFetch(
    displayDate,
    displayDate,
    'fluMap',
    'STATE/?geo_parent=US'
  )

  useEffect(() => {
    if (statesData !== null && statesData !== undefined) {
      if (statesData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Dashboard access success',
          user: user,
        })
        setMapStates(statesData.response_data)
      } else {
        setErrorCode(statesData.response_code)
        setErrorMessage(statesData.response_message)
        setErrorSubject(statesData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [statesData])

  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])

  if (MapError || errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  return (
    <div className={`${styles.base}`}>
      {regionSelected === 'state' && (
        <>
          {getMapStates !== undefined && getStateJson ? (
            <>
              <Map
                toolTipFormatter={toolTipFormatter}
                activeCbsasData={null}
                statesData={getMapStates}
                cbsaData={null}
                mapHeight="35vh"
                mapWidth="50vh"
                mapOptions={mapOptions}
                dynamicMapTitle={true}
                mapType="RSVMAP"
                enableButtons={false}
                enableTooltip={false}
                chartRef={chartRef}
                mapDataType={0}
                stateJson={getStateJson}
                enableMouseTracking={false}
                dashboardType="Flu"
              />
              <MapLegend className={styles.mapLegend} />
            </>
          ) : (
            <EmptyMap
              mapHeight="30vh"
              mapWidth="60vh"
              stateJson={getStateJson}
            />
          )}
        </>
      )}
    </div>
  )
}

export default FluMap
