import React,{useState} from 'react'
import { useParams } from 'react-router-dom'
import styles from './GeoHeader.module.scss'
import Content from '../../../components/Content'

import Layout from '../../../components/Layout'
import GeoProvider from '../../../components/GeoProvider'
import DashboardMap from './DashboardMap'
import dataDisplayOptions from '../dataDisplayOptions'
import { startDate, endDate, displayDate } from '../dates'
import Header from './Header'
import Gender from '../../GenderAge'
import SeasonalPattern from '../../SeasonalPattern'
import SystemNotification from './../../Dashboard/SystemNotification'
const FluZip3GeoDashboard = () => {
  const { geo_parent, area, loc } = useParams()
  const [getSideTable, setSideTable] = useState(); 
  const setDataStats = (data) => {
    setSideTable(data);    
  };

  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.body}>
            <GeoProvider
              startDate={startDate}
              endDate={endDate}
              displayDate={displayDate}
              mapOptions={dataDisplayOptions[0]['options'][0]}
              indicatorName={dataDisplayOptions[0]['options'][0].indicatorName}
            >
              <SystemNotification/>
              <Header headerType={geo_parent} headerNumber={3} geo_id={loc} stateData={setDataStats} />
              <DashboardMap
                geo_type={area}
                geo_parent={geo_parent}
                zoomLocation={loc}
                sidetableData={getSideTable}
              />
              <Gender geo_parent={geo_parent} geo_type={area} geo_id={loc} data_type="FLU"/>
              <SeasonalPattern geo_parent={geo_parent} geo_type={area} geo_id={loc} data_type="FLU"/>
            </GeoProvider>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default FluZip3GeoDashboard
