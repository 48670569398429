import colorAxisFluIndex from './colorAxisFluIndex'
import colorAxisVulnerabilityIndex from './colorAxisVulnerabilityIndex'
const dataDisplayOptions = [
  {
    label: 'Public Indicators',
    options: [
      {
        value: 'forecast_level',
        tagKey: 'forecast_level',
        tagValue: 'data_value',
        label: 'Incidense Rate',
        colorAxis: colorAxisFluIndex,
        mapTitle: 'US RSV Incidence',
        mapSubTitle: undefined,
        legend: {
          enabled: false,
          title: {
            text: 'US RSV Incidence',
          },
        },
      },
      {
        value: 'threshold_level',
        tagKey: 'threshold_level',
        tagValue: 'vulnrebility_index',
        label: 'Vulnrebility Index',
        colorAxis: colorAxisVulnerabilityIndex,
        mapTitle: 'US Vulnrebility Index',
        mapSubTitle: undefined,
        indicatorName: 'Vulnrability',
        legend: {
          enabled: false,
          title: {
            text: 'US Vulnrebility Index',
          },
        },
      },
    ],
  },
]

export default dataDisplayOptions
