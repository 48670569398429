import { useState, useEffect } from 'react'
import { useGeoFetch } from '../components/GeoProvider'

export const useMSADataFetch = (startDate, endDate, diseaseType) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState()

  const { data: msaMapData, error: msaMapDataError } = useGeoFetch(
    startDate,
    endDate,
    `${diseaseType}Map`,
    'MSA'
  )

  useEffect(() => {
    setLoading(true)
    if (msaMapData?.response_code === 200) {
      setData(msaMapData?.response_data)
      setLoading(false)
    } else if (msaMapData?.response_code >= 400) {
      setError(true)
      setLoading(false)
    }

    if (msaMapDataError) {
      setError(true)
      setLoading(false)
    }
  }, [msaMapData, msaMapDataError])

  return { data, error, loading }
}
