const CustomTextForForm = {
  header:"Report an Issue",
  success:'Your report is sent. Incident: ',
  requester: {
    labeltxt: "Requested by",
    empty_msg: "requester can not be blank",
    min: 4,
    max: 40,
    length_msg_min: `requester must be more than 4 characters`,
    length_msg: `requester must be less than 40 characters`,
  },

  email: {
    labeltxt: "Email",
    emailBlankMsg: "email can not be blank",
    min: 10,
    max: 50,
    emailInvalidMsg: `Invalid email format`,
  },
  phone: {
    labeltxt: "Phone",
    invalidFormat: "Invalid phone format",
    min: 0,
    max: 15,

  },
  company: {
    labeltxt: "Company",
    empty_msg: "company can not be blank",
    min: 3,
    max: 30,
    length_msg_min: `company must be more than 3 characters`,
    length_msg_max: `company must be less than 30 characters`,
  },
  description: {
    labeltxt: "Description",
    empty_msg: "description can not be blank",
    min: 25,
    max: 2000,
    length_msg_min: `description must be more than 25 characters`,
    length_msg: `description must be less than 2000 characters`,
  },

  issuetype: {
    labeltxt: "Issue Type",
    empty_msg: "issue type can not be blank",
    min: 0,
    max: 50,
    length_msg: ``,
  },
  priority: {
    labeltxt: "Priority",
    empty_msg: "issue type can not be blank",
    min: 0,
    max: 50,
    length_msg: ``,
  },
  file: {
    labeltxt: "Attachment",
    empty_msg: "file size can not be more than 5 MB",
    min: 0,
    max: 50,
    size_msg: `file size can not be more than 5 MB`,
  }
}

export default CustomTextForForm