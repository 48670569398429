import React from 'react'
import MenuContext from './MenuContext'
import Item from './Item'
import { WindowResize } from '../WindowEvent'

import styles from './Menu.module.scss'

const Menu = ({ children = null, menutype, ...rest }) => {
  const menuRef = React.createRef()

  // eslint-disable-next-line no-unused-vars
  //const [style, setStyle] = React.useState(null)
  const update = React.useCallback(() => {
    if (menuRef.current === null) return
  }, [menuRef])

  // Set initial focus
  const { isVisible } = React.useContext(MenuContext)
  React.useEffect(() => {
    if (menuRef.current === null) return

    const focusable = menuRef.current.querySelector('span > a, span > button')

    focusable && focusable.focus()
  }, [isVisible, menuRef])

  return (
    <WindowResize handler={update} invokeImmediately>
      {menutype === 'profile' ? (
        <ul
          aria-hidden={!isVisible}
          className={`${styles.baseone} ${isVisible ? styles.open : ''}`}
          ref={menuRef}
          /* style={{ ...style, width }} */
        >
          {children}
        </ul>
      ) : (
        <ul
          aria-hidden={!isVisible}
          className={`${styles.base} ${isVisible ? styles.open : ''}`}
          ref={menuRef}
          style={{ fontSize:'16px' ,fontWeight:'400',textDecoration:'none'}} 
        >
          {children}
        </ul>
      )}
    </WindowResize>
  )
}

Menu.Item = Item

export { Menu as default, MenuContext }
