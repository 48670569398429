const getThresholdValue = (level) => {
  switch (level) {
    case 1:
      return {
        threshold: 'Minimal',
        color: '#218371',
        OutlineColor: '#FFFFFF',
      }
    case 2:
      return {
        threshold: 'Low',
        color: '#6CB2A2',
        OutlineColor: '#0D5E4F',
      }
    case 3:
      return {
        threshold: 'Medium',
        color: '#FFFFF0',
        OutlineColor: '#AD8D01',
      }
    case 4:
      return {
        threshold: 'High',
        color: '#FB8D7B',
        OutlineColor: '#990000',
      }
    case 5:
      return {
        threshold: 'Very High',
        color: '#C40000',
        OutlineColor: '#FFFFFF',
      }

    default:
      return {
        threshold: 'No Data',
        color: '#E5E5E6',
        OutlineColor: '#7D7F81',
      }
  }
}

export default getThresholdValue
