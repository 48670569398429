import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuthDispatch } from '../../components/AuthProvider'
import ErrorLogs from '../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../components/AuthProvider'
const SignOut = () => {
  const { user } = useAuthState()
  const { clearSession } = useAuthDispatch()

  React.useEffect(() => {
    ErrorLogs({message_type: 'Activity',error_details: 'SignOut', user: user})
    clearSession()
  }, [clearSession])

  return <Redirect to="/" />
}

export default SignOut
