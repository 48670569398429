const getThresholdValueVulnerability = (level) => {
  switch (level) {
    case 1:
      return {
        threshold: 'Minimal',
        color: '#E5FDF7',
        OutlineColor: '#2B9181',
      }
    case 2:
      return {
        threshold: 'Low',
        color: '#AAC9E4',
        OutlineColor: '#2D6EA3',
      }
    case 3:
      return {
        threshold: 'Medium',
        color: '#7796CC',
        OutlineColor: '#0E456B',
      }
    case 4:
      return {
        threshold: 'High',
        color: '#5163AE',
        OutlineColor: '#FFFFFF',
      }
    case 5:
      return {
        threshold: 'Very High',
        color: '#422C88',
        OutlineColor: '#FFFFFF',
      }

    default:
      return {
        threshold: 'No Data',
        color: '#6D6F70',
        OutlineColor: '#6D6F70',
      }
  }
}

export default getThresholdValueVulnerability
