import axios from 'axios'

import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
//import ErrorMessage from '../components/Layout/ErrorMessage'
export default function useRequest(request, { initialData, ...config } = {}) {
  const history = useHistory()
  return useSWR(
    request && JSON.stringify(request),
    () =>
      axios(request || {})
        .then((response) => response.data)
        .then((response) => {
          if (response.response_code === 602) {
            history.replace('/unauthorized')
          } else {
            return response
          }
        }),
   
    {
      ...config,
      // refreshInterval: 0,
      // shouldRetryOnError: false,
      dedupingInterval: 1000 * 60 * 60 * 2, // 2hrs nothing is reloaded till refreshed
      focusThrottleInterval: 1000 * 60 * 60 * 2, // 2hrs nothing is reloaded till refreshed
      initialData: initialData && {
        status: 200,
        statusText: 'InitialData',
        headers: {},
        data: initialData,
      },
    }
  )
}
