import React, { useState, useEffect } from 'react'
import styles from './GeoHeader.module.scss'
import getVectorImage from '../../../utils/getVectorImage'
import { useGeoFetch, useGeoState } from '../../../components/GeoProvider'
import dayjs from 'dayjs'
import typeDisplayOptions from '../../Dashboard/typeDisplayOptions'
import SelectList from '../../../components/SelectMenuList'
import DefaultBreadcrumbs from '../../../components/Breadcrumbs/DefaultBreadcrumbs'
import Search from '../../../components/Search'
import { lastweekEndDate } from './../dates'

const HeaderDisplay = ({ headerType, geo_id, refSideTableData }) => {
  //const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getgeoid, setgeoid] = useState('')
  const [getistrue, setistrue] = useState('')
  const [getDatavalue, setDatavalue] = useState('')
  const [getstatename, setStatename] = useState('')
  const [getPercentage, setPercentage] = useState('')
  const { setLocationName } = useGeoState()
  const { data: headerdata, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluHeader',
    geo_id
  )

  useEffect(() => {
    if (headerdata !== null && headerdata !== undefined) {
      if (headerdata.response_code === 200) {

        setgeoid(headerdata.response_data.geo_id)
        setistrue(headerdata.response_data.istrue)
        setPercentage(headerdata.response_data.percentage)
        setDatavalue(headerdata.response_data.datavalue)
        setStatename(headerdata.response_data.statename)
        if (headerType === "ZIP3") {
          refSideTableData(headerdata.response_data)
          setLocationName(headerdata.response_data.statename)
        }
       
      } else {
        setErrorSubject(headerdata.response_subject)
        seterrorOccured(true)
      }
    }
  }, [headerdata])


  if (stateDataError || (errorOccured && errorSubject !== undefined)) {
    return <div>Unable to get the data for header {errorSubject}</div>
  }

  return (
    <>
      <div
        className={
          headerType === 'NAT'
            ? styles.natDiv
            : headerType === 'STATE'
              ? styles.stateDiv
              : styles.geoDiv
        }
      >
        <span className={styles.firstSpan}>
          {headerType !== 'STATE' && headerType !== 'NAT'
            ? getstatename
            : getgeoid}
        </span>
        <span className={styles.secondSpan}>{getVectorImage(getistrue)}</span>
        <span
          className={getistrue ? styles.stdatavalueup : styles.datavaluedown}
        >
          {' '}
          {getPercentage}%{' '}
        </span>
        <span className={styles.forthSpan}>
          {' '}
          ({getDatavalue})
          {headerType !== 'NAT' && headerType !== 'STATE' ? (
            <span className={styles.sinceWeek}> since last week</span>
          ) : (
            ''
          )}
        </span>
      </div>
    </>
  )
}

const Header = ({ headerType, headerNumber, geo_id, stateData }) => {



  const [searchCriteria, setSearchCriteria] = React.useState([])
  const { locationName } = useGeoState()
  const [activeCbsa, setActiveCbsa] = useState()
  const [covidStatesData, setCovidStatesData] = useState()
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    '',
    '',
    'search',
    'locations/FLU?geo_type=MSA'
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])


  const [activeZip3, setactiveZip3] = useState()
  const { data: activeZip3Data, error: activeZip3DataError } = useGeoFetch(
    '',
    '',
    'search',
    'locations/FLU?geo_type=ZIP3'
  )

  useEffect(() => {
    if (activeZip3Data !== null && activeZip3Data !== undefined) {
      setactiveZip3(activeZip3Data)
    }
  }, [activeZip3Data])




  return (
    <>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderText}>
          <DefaultBreadcrumbs
            geo_parent={headerType}
            geo_id={geo_id}
            geo_type={'ZIP3'}
            dashboardType={'FLU'}           
          />
          <SelectList
            dropdownData={typeDisplayOptions}
            defaultValue={'flu'}
            defaultLabel={'Flu Incidence'}
          />
        </div>
        <div className={styles.geoName}>{locationName}</div>
        <div className={styles.pageHeaderRight}>
          <div className={styles.pageHeaderTextValue}>
            <HeaderDisplay headerType={'NAT'} geo_id={'NAT'} ></HeaderDisplay>
            <HeaderDisplay
              headerType={'STATE'}
              geo_id={headerType}
            ></HeaderDisplay>
          {geo_id!==undefined && <HeaderDisplay headerType={'ZIP3'} refSideTableData={stateData} geo_id={geo_id}></HeaderDisplay>
         }  
         </div>
          <div className={styles.pageHeaderSearch}>
            {activeCbsa !== undefined && activeZip3 !== undefined && (
              <Search
                criteria={searchCriteria}
                onChange={setSearchCriteria}
                entity={'Flu'}
                activeMetros={activeCbsa}
                activeZip3={activeZip3}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default Header
