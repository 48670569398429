import React from 'react'
import requiresAuth from './requiresAuth'
import bindActionCreators from '../../utils/bindActionCreators'
import { USER_AUTH_KEY, USER_LOGIN_REDIRECT } from '../../utils/constants'

import { clearSession, createSession, preventNextRedirect } from './authActions'
import authReducer from './authReducer'

export const AuthStateContext = React.createContext()
export const AuthDispatchContext = React.createContext()

export const useAuthState = () => {
  const context = React.useContext(AuthStateContext)
  if (!context) {
    throw new Error('useAuthState must be used within an AuthProvider')
  }
  return context
}

export const useAuthDispatch = () => {
  const dispatch = React.useContext(AuthDispatchContext)
  if (!dispatch) {
    throw new Error('useAuthDispatch must be used within an AuthProvider')
  }

  const state = useAuthState()
  const getState = () => state

  return bindActionCreators(
    {
      clearSession,
      createSession,
      preventNextRedirect,
    },
    dispatch,
    getState
  )
}

export const useAuth = () => [useAuthState(), useAuthDispatch()]

const AuthProvider = ({ children = null }) => {
  const sessionUser = window.localStorage.getItem(USER_AUTH_KEY)
  const user = sessionUser ? JSON.parse(sessionUser) : null
  const userLoginRedirect = window.localStorage.getItem(USER_LOGIN_REDIRECT)
  const loginRedirect = userLoginRedirect ? JSON.parse(userLoginRedirect) : null
  const authMode = 'ohid'

  let redirectRoute = '/dashboard'

  if (loginRedirect && loginRedirect.location) {
    redirectRoute = loginRedirect.location.pathname
    if (loginRedirect.location.hash) {
      redirectRoute += loginRedirect.location.hash
    }
  }

  const initialState = {
    error: null,
    isAuthenticated: user !== null && user.expiry > new Date().getTime(),
    isCreatingSession: false,
    user,
    redirectRoute: redirectRoute,
    authMode,
  }
  const [state, dispatch] = React.useReducer(authReducer, initialState)

  // If the user is changing, update localStorage
  React.useEffect(() => {
    window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(state.user))
  }, [state.user])

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

export { AuthProvider as default, requiresAuth }
