const Priority = [
  {
    value: "3",
    text: "Critical",
  },
  {
    value: "4",
    text: "High",
  },
  {
    value: "5",
    text: "Low",
  },
]

export default Priority