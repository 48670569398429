import React from "react";
import GeoProvider from "../../components/GeoProvider";
import { useHistory } from 'react-router-dom'
import Layout from '../../components/Layout'
import Content from '../../components/Content'
import styles from './About.module.scss'
import getVectorImage from '../../utils/getVectorImage'
import DataSources from './DataSources'
import SystemNotification from './../Dashboard/SystemNotification'

const About = () => {
  const history = useHistory()
  return (
    <Layout>
      <Content>
        <GeoProvider>
        <SystemNotification/>
        </GeoProvider>
        
        <div className={styles.base}>
        <div className={styles.body}>
        <div className={styles.pageHeader}>
             
              <a href=""  onClick={() => history.push(`/dashboard`)}> {getVectorImage('arrowleft',  '24px',  '24px')}</a>
             <span className={styles.pageHeaderText}>About</span> 
            
            </div>
            <DataSources></DataSources>
          </div>
        </div>
       
      </Content>
    </Layout>
  )
  }

export default About;
