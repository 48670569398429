import React  from 'react'
import { useParams } from 'react-router-dom'
import styles from './StateHeader.module.scss'
import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import GeoProvider from '../../../components/GeoProvider'
import DashboardMap from './DashboardMap'
import dataDisplayOptions from '../dataDisplayOptions'
import { startDate, endDate } from '../dates'
import Header from './Header'
import VulnerabilityBreakdown from './VulnerabilityBreakdown'
import SystemNotification from './../../Dashboard/SystemNotification'
const VulnerabilityStateDashboard = () => {
  const { area, loc } = useParams()
  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.body}>
            <GeoProvider
              startDate={startDate}
              endDate={endDate}
              displayDate={"October 2023"}
              locationName={loc}
              mapOptions={dataDisplayOptions[0]['options'][1]}
              indicatorName={dataDisplayOptions[0]['options'][1].indicatorName}
              regionSelected={'state'}
            >
              <SystemNotification/>
              <Header headerType={area} headerNumber={2} geo_id={loc} />
              <DashboardMap
                geo_type={area}
                geo_parent={loc}
                zoomLocation={loc}
              />
             
              <VulnerabilityBreakdown geo_type={area}geo_id={loc}/>
             
            </GeoProvider>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default VulnerabilityStateDashboard
