import { requiresAuth } from '../components/AuthProvider'

import Home from './Home'
import Login from './Login'
import OneHealthcareIdLogin from './OneHealthcareIdLogin'
import LoginRedirect from './LoginRedirect'
import SignOut from './SignOut'
import Profile from './Profile'
import NoAccess from './NoAccess'
import NotFound from './NotFound'
import Dashboard from './Dashboard'
import RSVDashboard from './RSVDashboard'
import RSVStateDashboard from './RSVDashboard/RSVStateDashboard'
import RSVGeoDashboard from './RSVDashboard/RSVGeoDashboard'
import FluDashboard from './FluDashboard'
import FluStateDashboard from './FluDashboard/FluStateDashboard'
import FluZip3Dashboard from './FluDashboard/FluZip3Dashboard'
import FluGeoDashboard from './FluDashboard/FluGeoDashboard'
import CovidDashboard from './CovidDashboard'
import CovidStateDashboard from './CovidDashboard/CovidStateDashboard'
import CovidGeoDashboard from './CovidDashboard/CovidGeoDashboard'
import VulnerabilityDashboard from './VulnerabilityDashboard'
import VulnerabilityStateDashboard from './VulnerabilityDashboard/VulnerabilityStateDashboard'
import VulnerabilityGeoDashboard from './VulnerabilityDashboard/VulnerabilityGeoDashboard'
import VulnerabilityMsaDashboard from './VulnerabilityDashboard/VulnerabilityMsaDashboard'

import Registration from './Registration'
import Invitation from './Registration/invitation'
import UnAuthorized from './UnAuthorized'
import AccessAbort from './AccessAbort'
import About from './About'
import FluNewMap from './FluDashboardNew'
import StateRiskMap from './RiskMap/StateRiskMap'
import MSARiskMap from './RiskMap/MSARiskMap'
const adminAccounts = ['Admin']
const externalAccount = ['External']
const otherAccounts = ['Basic', 'Power User']
const allAccounts = [...otherAccounts, ...adminAccounts, ...externalAccount]
const restrictAccounts = ['Admin', 'Basic', 'Power User']
const allEnvironments = ['local', 'stage', 'prod']

const Pages = [
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: Home,
    label: 'Home',
    path: '/',
    routes: [
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(Profile),
        path: '/profile',
        routes: [],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(About),
        path: '/about',
        routes: [],
      },

      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(FluNewMap),
        path: '/flunew',
        routes: [],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(Dashboard),
        label: '',
        path: '/Dashboard',
        routes: [],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(RSVDashboard),
        label: '',
        path: '/RSVDashboard',
        routes: [],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(StateRiskMap),
        label: '',
        path: '/HeatMap/:diseaseType/NAT',
        routes: [],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(MSARiskMap),
        label: '',
        path: '/HeatMap/:diseaseType/STATE/:geoId',
        routes: [],
      },

      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(RSVDashboard),
        label: 'RSVDashboard',
        path: '/RSVStateDashboard',
        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(RSVStateDashboard),
            label: 'State View',
            path: ':area/:loc',
          },
        ],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(RSVDashboard),
        label: 'RSVDashboard',
        path: '/RSVMSADashboard',

        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(RSVGeoDashboard),
            label: 'MSA View',
            path: ':geo_parent/:area/:loc',
          },
        ],
      },

      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(FluDashboard),
        label: '',
        path: '/FluDashboard',
        routes: [],
      },

      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(FluDashboard),
        label: 'FluDashboard',
        path: '/FluStateDashboard',
        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(FluStateDashboard),
            label: 'State View',
            path: ':area/:loc',
          },
        ],
      },

      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(FluDashboard),
        label: 'FluDashboard',
        path: '/FluMSADashboard',

        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(FluGeoDashboard),
            label: 'MSA View',
            path: ':geo_parent/:area/:loc',
          },
        ],
      },

      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(FluZip3Dashboard),
        label: 'FLUZIP3Dashboard',
        path: '/FLUZIP3Dashboard',
        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(FluZip3Dashboard),
            label: 'MSA View',
            path: ':geo_parent/:area/:loc?',
          },
        ],
      },

      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(CovidDashboard),
        label: '',
        path: '/CovidDashboard',
        routes: [],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(CovidDashboard),
        label: 'CovidDashboard',
        path: '/CovidStateDashboard',
        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(CovidStateDashboard),
            label: 'State View',
            path: ':area/:loc',
          },
        ],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(CovidDashboard),
        label: 'CovidDashboard',
        path: '/CovidMSADashboard',

        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(CovidGeoDashboard),
            label: 'MSA View',
            path: ':geo_parent/:area/:loc',
          },
        ],
      },

      {
        isHidden: true,
        authorizedRoles: restrictAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(VulnerabilityDashboard, restrictAccounts),
        label: '',
        path: '/VulnerabilityDashboard',
        routes: [],
      },
      {
        isHidden: true,
        authorizedRoles: restrictAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(VulnerabilityDashboard, restrictAccounts),
        label: 'VulnerabilityDashboard',
        path: '/VulnerabilityStateDashboard',
        routes: [
          {
            isHidden: true,
            authorizedRoles: restrictAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(
              VulnerabilityStateDashboard,
              restrictAccounts
            ),
            label: 'State View',
            path: ':area/:loc',
          },
        ],
      },
      {
        isHidden: true,
        authorizedRoles: restrictAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(VulnerabilityDashboard, restrictAccounts),
        label: 'VulnerabilityDashboard',
        path: '/VulnerabilityGeoDashboard',

        routes: [
          {
            isHidden: true,
            authorizedRoles: restrictAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(
              VulnerabilityGeoDashboard,
              restrictAccounts
            ),
            label: 'MSA View',
            path: ':geo_parent/:area/:loc',
          },
        ],
      },
      {
        isHidden: true,
        authorizedRoles: restrictAccounts,
        envAccess: allEnvironments,
        component: requiresAuth(VulnerabilityDashboard, restrictAccounts),
        label: 'VulnerabilityDashboard',
        path: '/VulnerabilityMsaDashboard',

        routes: [
          {
            isHidden: true,
            authorizedRoles: restrictAccounts,
            envAccess: allEnvironments,
            component: requiresAuth(
              VulnerabilityMsaDashboard,
              restrictAccounts
            ),
            label: 'MSA View',
            path: ':geo_parent/:area/:loc',
          },
        ],
      },
    ],
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: Login,
    path: '/login',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: OneHealthcareIdLogin,
    path: '/ohid-login',
  },

  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: LoginRedirect,
    path: '/oauth2/login-redirect',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: SignOut,
    path: '/signout',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: NoAccess,
    path: '/access-denied',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: UnAuthorized,
    path: '/unauthorized',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: AccessAbort,
    path: '/accessabort',
  },

  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: Registration,
    path: '/registration',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: requiresAuth(Invitation),
    path: '/invitation',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    component: NotFound,
    path: '*',
  },
]

export default Pages
