import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import LinkButton from '../../components/LinkButton'
import getVectorImage from '../../utils/getVectorImage'
import styles from './SideTable.module.scss'
import toTitleCase from '../../utils/toTitleCase'
import { useGeoState } from '../../components/GeoProvider'
const Databind = ({ state, chartRef }) => {
  const {
    regionSelected,
  } = useGeoState()
  const history = useHistory()
  const handleMouseOver = (element) => {
    const chart = chartRef.current.chart
    var indexSeries = regionSelected === "metro" ? 1 :regionSelected === "zip3"? 2: 0
    if (chart !== undefined && chart !== null) {
      const point = chart.series[indexSeries].points.find(
        (point) => point.geo_id === element.attributes['geo_id'].textContent
        //element.getAttribute('title')
      )
      point && chart.tooltip.refresh(point)
    }
  }

  const handleMouseOut = () => {
    const chart = chartRef.current.chart
    if (chart && chart.tooltip) {
      chart.tooltip.destroy()
    }
  }
  let statename=(regionSelected.toUpperCase()==='STATE'?state.statename+' ('+state.geo_id+')':state.statename)
  return (
    <div style={{ display: 'flex' }} className={styles.Layout}>
      <div
        style={{
          width: '80%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={statename}
      >
        <LinkButton
          onMouseOver={(e) => handleMouseOver(e.target)}
          onMouseOut={() => handleMouseOut()}
          className={styles.btn}
          onClick={() => {
            {regionSelected==='metro'?
            history.push(`/CovidMSADashboard/${state.geo_parent}/MSA/${state.geo_id}`):
            history.push(`/CovidStateDashboard/STATE/${state.geo_id}`)}
           
          }}
          data-title={statename}
          title={statename}
          geo_id={state.geo_id}
        >
          {statename}
        </LinkButton>
      </div>
      <div className={styles.columnRate}>{state.datavalue}</div>
      <div className={styles.columnDays}>
        <span> {getVectorImage(state.istrue)} </span>
        <span
          className={
            state.istrue ? styles.percentageRed : styles.percentageGreen
          }
        >
          {' '}
          {state.percentage}%
        </span>
      </div>
    </div>
  )
}

const SideTable = ({ stateData, chartRef }) => {
  const {
    regionSelected,
  } = useGeoState()
  const stateDataForDisplayDate = Object.entries(stateData)
  const mappedStateData = stateDataForDisplayDate.map((state) => {
    const stateName = state[1].geo_name
    return { ...state[1], name: stateName, identifier: stateName }
  })

  let filteredStateData = mappedStateData
  const [getFilter, setfilter] = useState('down')
  const sortBy = (action) => {
    if (action === 'up') {
      setfilter('down')
      stateData.sort((a, b) => b.datavalue - a.datavalue)
    } else if (action === 'down') {
      setfilter('up')
      stateData.sort((a, b) => a.datavalue - b.datavalue)
    }
  }
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: '16px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                // width: '60%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontFamily: 'Optum Sans',
                    fontSize: '21px',
                    fontWeight: '600',
                    color: '#002677',
                  }}
                >
                  {regionSelected==='metro'?toTitleCase(regionSelected)+' Area Incidence':toTitleCase(regionSelected)+' Incidence'}
                </h5>
              </span>
             
            </div>
         
          </div>
          <div
            style={{
              fontSize: '12px',
              display: 'flex',
              lineHeight: '18px',
              padding: '1rem 0rem 0',
            }}
          >
            <span> {getVectorImage('Info', '16px', '16px')}</span> &nbsp;&nbsp;
            <div
              style={{ '&::WebkitScrollbar': { width: 0, height: 0 } }}
              className={styles.sideTableHeader}
            >
              {' '}
              COVID Rates represent the incidence of COVID within the population in
              a given week. Change indicates the difference in COVID incidence
              since the previous week.
            </div>
          </div>
        </div>
        <div
          style={{
            padding: '0rem 0rem',
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderTop: '1px solid #CBCCCD',
            borderBottom: '2px solid #002677',
            marginTop: '5px',
          }}
        >
          <div style={{ display: 'inline-flex', width: '54%' }}>
            <h4>{toTitleCase(regionSelected)}</h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '20%',
              justifyContent: 'flex-middle',
              cursor: 'pointer',
            }}
          >
            <h4 onClick={() => sortBy(getFilter)}>
              Rate{' '}
              <span style={{ marginLeft: '4px' }}>
                {getVectorImage(getFilter)}
              </span>
            </h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '26%',
              justifyContent: 'flex-middle',
            }}
          >
            <h4>Change</h4>
          </div>
        </div>
      </div>

      <div className={styles.scroll}>
        {filteredStateData.map((state) => {
          return (
            <Databind state={state} key={state.statename} chartRef={chartRef} />
          )
        })}
      </div>
    </div>
  )
}

export default SideTable
