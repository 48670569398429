import React, { useState } from 'react'
import LinkButton from '../../../components/LinkButton'
import styles from './SideTable.module.scss'
import DropdownMenu from './DropdownMenu'
import NewWindow from 'react-new-window'
import { useGeoState } from '../../../components/GeoProvider'
import {  toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import copyImg from '../../../assets/images/content_copy.svg';
const Databind = ({
  state,
  keyid,
  showProvidersMap
}) => {
  const {
    setPinPopup
  } = useGeoState()  
  const handleMouseOver = (data) => {
    setPinPopup(data)
  }
  const handleMouseOut = () => {
    setPinPopup({})  
    toast.dismiss() 
    
  }

  const redirecttoGoogleMap1 = (data) => {    
    toast.dismiss()
   navigator.clipboard.writeText(data);
   toast.success('copied address.', {
    position: "bottom-center",  
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,  
    autoClose: 1000    
    });
  }
  if (state !== null && state !== undefined) {
    let notAv = state.originating_business === 'Name Not Available'
    return (
      <div  onMouseOver={(e) => handleMouseOver(keyid)}
      onMouseOut={() => handleMouseOut()}  style={{ display: 'flex' }} className={styles.Layout}>
        <div
          style={{
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <div style={{ display: 'flex' }}>
            <LinkButton
              className={styles.button}
              data-title={state.building_code}
              title={state.location_code + ':' + state.originating_business}
              
            >
              {notAv !== true
                ? state.location_code + ': ' + state.originating_business
                : state.location_code + ': '}
            </LinkButton>
          
            <span className={styles.notAvailable}>
              {notAv && '(' + state.originating_business + ')'}
            </span>
            
          </div>  
             
          <span className={styles.filter}>
            {' '}
            {state.primary_location_type_formated}{' '}
            {state.primary_location_type_formated !== 'Other'
              ? state.primary_location_sub_type_formated
              : ''}
          </span>
        </div>
        <div> 
      <img  onClick={() => redirecttoGoogleMap1(`${state.address_line1}  ${state.address_line2}  ${state.geo_name}`)}
        src={copyImg}
        alt="copy location"
        style={{ height: "20px", width: "20px", cursor:'pointer' }}
      ></img></div>
      </div>
    )
  } else {
    return (
      <div style={{ display: 'flex' }} className={styles.Layout}>
        Providers not available for the selected MSA
      </div>
    )
  }
}

const SideTableProvider = ({
  dropdownData,
  stateData,
  chooseMessage,
  chartRef,
}) => {
  const [getOpen, setOpen] = useState(false)
  const [getlatitude, setlatitude] = useState(false)
  const [getlongitude, setlongitude] = useState(false)
  const [getDefaultMenu, setDefaultMenu] = useState('')

  const sendDataMenuToParent = (item) => { // the callback. Use a better name
    
    setDefaultMenu(item);
  };
  const newWindowUnloaded = () => {
    setOpen(false)
    setlatitude()
    setlongitude()
  }
  const redirecttoGoogleMap = (data) => {
    setOpen(true)
    setlatitude(data.latitude)
    setlongitude(data.longitude)
  }

  const stateDataForDisplayDate = Object.entries(stateData)
  const mappedStateData = stateDataForDisplayDate.map((state) => {
    const stateName = state[1].building_code // state[1].geo_name
    return { ...state[1], name: stateName, identifier: stateName }
  })
  const { showProvidersMap, setShowProvidersMap } = useGeoState()

  const handleChange = (val) => {
    setShowProvidersMap(val)
  }

  let filteredStateData = mappedStateData

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-start',

            paddingBottom: '16px',
            paddingTop: '16px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '30%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '20.25px',
                    lineHeight: '24px',
                    color: '#002677',
                  }}
                >
                  CDOs
                </h5>
              </span>
            </div>

            <div
              style={{
                width: '70%',
                marginTop: '-7px',
                height: '10%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
               <DropdownMenu sendDataMenuToParent={sendDataMenuToParent} dropdownData={dropdownData} />
            </div>
          </div>
          {getDefaultMenu && (<div style={{paddingTop:'8px'}}><span style={{background:'#002677', color:'white', fontSize:'12px', borderRadius:'16px', marginTop:'8px',  fontWeight:'700', textAlign:'center', padding:'6px'}}>{getDefaultMenu}</span></div>)}
        </div>
        <div
          style={{
            padding: '0rem 0rem',
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderTop: '1px solid #CBCCCD',
            borderBottom: '2px solid #002677',
            marginTop: '5px',
            display: 'flex',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '35%',
              justifyContent: 'flex-start',
              paddingBottom: '16px',
              paddingTop: '16px',
            }}
          >
            <h4>Location</h4>
          </div>
         
        </div>
        {getOpen && (
          <NewWindow
            url={`https://maps.google.com/?q=${getlatitude},${getlongitude}`}
            features={{ left: 200, top: 200, width: 800, height: 600 }}
            center="parent"
            onUnload={() => newWindowUnloaded()}
            title="Infectious Disease Platform"
            name="Infectious Disease Platform"
          />
        )}
      </div>

      <div className={styles.scroll}>
        {filteredStateData.length === 0 ? (
          <Databind
            state={null}
            keyid={0}
            chooseMessage={chooseMessage}
            redirecttoGoogleMap={redirecttoGoogleMap}
            chartRef={chartRef}
            showProvidersMap={showProvidersMap}
          />
        ) : (
          filteredStateData.map((state, index) => {            
            return (
              <Databind
                state={state}
                keyid={index}
                chooseMessage={chooseMessage}
                redirecttoGoogleMap={redirecttoGoogleMap}
                chartRef={chartRef}
                showProvidersMap={showProvidersMap}
              />
            )
          })
        )}
      </div>
    </div>
  )
}

export default SideTableProvider