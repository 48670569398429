import dayjs from 'dayjs'

export const startDate = (dayjs().$y - 1).toString() + '-10-02'
export const endDate = dayjs().day(0).add(182, 'day').format('YYYY-MM-DD') //someFutureSunday
export const displayDate = dayjs().day(+6).format('YYYY-MM-DD')
export const displayDateReport = dayjs().day(-1).format('YYYY-MM-DD')
export const lastDisplayDate = dayjs().day(-7).format('YYYY-MM-DD')
export const weekEndDate = dayjs()
  .day(+6)
  .format('YYYY-MM-DD')
export const lastweekEndDate = dayjs().day(+6).format('YYYY-MM-DD')
