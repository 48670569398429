import React from 'react'
import Highcharts from 'highcharts'
import HT from 'highcharts/modules/timeline'
import HighchartsReact from 'highcharts-react-official'
import Spinner from '../Loader/Spinner'
import getColumnChartOptions from './getColumnChartOptions'
import getAgeGenderColumnChartOptions from './getAgeGenderColumnChartOptions'
import styles from './ColumnChartDisplay.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const BarChartDisplay = ({ data, chartName,renderType,multiColumn,locationName,searchLocationText,displayDate }) => {
  let categories = []
  let categories1=[]
  let graph_female_data = []
  let graph_male_data = []
  let graph_female_data1 = []
  let graph_male_data1 = []
  var scatter_position_first=-0.15
  var scatter_position_second=0.15
  const finalData = []
  if (chartName === 'genderAgechart') {

   
    // finalData.push({ name: 'category', data:multiColumn?categories1:categories })
    // finalData.push({ name: 'Male', data: graph_male_data })
    // finalData.push({ name: 'Female', data: graph_female_data })
    if(data!==null && data.length>1){
    let res1 = data[1] 
    let counter=1
    let sum1
    let sum2
    // res1.map((data_val) => {
    //   if(counter===1)
    //   {
    //     sum1=scatter_position_first
    //     sum2=scatter_position_second
    //   }
    //   else
    //   {
    //     sum1+=1
    //     sum2+=1
    //   }
    //   graph_female_data1.push([sum2,data_val.female_Count])
    //   graph_male_data1.push([sum1,data_val.male_Count])
    //   counter++
    // })
    for(let m=0;m<data[0].length;m++)
    {
         if(counter===1)
      {
        sum1=scatter_position_first
        sum2=scatter_position_second
      }
      else
      {
        sum1+=1
        sum2+=1
      }
      let match_flag=false
      for(let j=0;j<data[1].length;j++)
      {
        if(data[0][m].age_bucket_id===data[1][j].age_bucket_id)
        {
          match_flag=true
          graph_female_data.push({name: data[0][m].age_bucket_display,y:data[0][m].female_Count,count1:data[0][m].female_Count,count2:data[1][j].female_Count})
          graph_male_data.push({name: data[0][m].age_bucket_display,y:data[0][m].male_Count,count1:data[0][m].male_Count,count2:data[1][j].male_Count})
          graph_female_data1.push({x:sum2,y:data[1][j].female_Count,count1:data[0][m].female_Count,count2:data[1][j].female_Count,data_series:data[0][m].age_bucket_display})
          graph_male_data1.push({x:sum1,y:data[1][j].male_Count,count1:data[0][m].male_Count,count2:data[1][j].male_Count,data_series:data[0][m].age_bucket_display})
        }
      }
      if(!match_flag)
      {
        graph_female_data.push({name: data[0][m].age_bucket_display,y:data[0][m].female_Count,count1:data[0][m].female_Count,count2:0})
        graph_male_data.push({name: data[0][m].age_bucket_display,y:data[0][m].male_Count,count1:data[0][m].male_Count,count2:0})
        graph_female_data1.push({x:sum2,y:0,count1:data[0][m].female_Count,count2:0,data_series:data[0][m].age_bucket_display})
        graph_male_data1.push({x:sum1,y:0,count1:data[0][m].male_Count,count2:0,data_series:data[0][m].age_bucket_display})
      }
    //  let female_ct= data[0][m]===undefined?0:data[0][m].female_Count
    //  let female_ct1= data[1][m]===undefined?0:data[1][m].female_Count
    //  let male_ct= data[0][m]===undefined?0:data[0][m].male_Count
    //  let male_ct1= data[1][m]===undefined?0:data[1][m].male_Count

    //     graph_female_data.push({name: data[0][m].age_bucket_display,y:female_ct,count1:female_ct,count2:female_ct1})
    //     graph_male_data.push({name: data[0][m].age_bucket_display,y:male_ct,count1:male_ct,count2:male_ct1})
    //     graph_female_data1.push({x:sum2,y:female_ct1,count1:female_ct,count2:female_ct1,data_series:data[0][m].age_bucket_display})
    //     graph_male_data1.push({x:sum1,y:male_ct1,count1:male_ct,count2:male_ct1,data_series:data[0][m].age_bucket_display})
      categories1.push(
        data[0][m].age_bucket_display        
     )
     counter++
    }

  }
  else{ 
    let res = data[0] //JSON.parse(data)
  res.map((data_val) => {
    categories.push(data_val.age_bucket_display)
    graph_female_data.push({name:data_val.age_bucket_display,y:data_val.female_Count})
    graph_male_data.push({name:data_val.age_bucket_display,y:data_val.male_Count})
  })
}
  finalData.push({ name: 'category', data:multiColumn?categories1:categories })
  finalData.push({ name: 'Male', data: graph_male_data })
  finalData.push({ name: 'Female', data: graph_female_data })
  finalData.push({ name: 'Male', data: graph_male_data1 })
  finalData.push({ name: 'Female', data: graph_female_data1 })
   
  }
  if (chartName === 'columnBarChart') {
    let res = data //JSON.parse(data)
    res.forEach((data_val) => {
      categories.push(data_val.age_bucket_display)

      graph_male_data.push(data_val.male_count)
    })
    finalData.push({ name: 'category', data: categories })
    finalData.push({ name: 'Male', data: graph_male_data })
    finalData.push({ name: 'Female', data: graph_female_data })
  }
  let msa1=[]
  let msa2=[]
  if(chartName==='cdcSVIColumnChart2' || chartName==='raceEthnicity')
  {

  if(data!==null && data.length>1)
  {
    for(let m=0;m<data[0].length;m++)
    {
      categories1.push(
        data[0][m].race+"<br/> <span style='font-weight:400'>"+data[0][m].data_value+" | "+data[1][m].data_value+"</span>"
     )
     if(data[0][m].data_value==='NA' || data[0][m].data_value===undefined)
     {
       msa1.push({name:data[0][m].race,
       y:0,
       data_moe1:0,
       data_moe2:0,
       data_value1:0,
       data_value2:0})}
     else{
       msa1.push({name:data[0][m].race,
       y:parseFloat(data[0][m].data_value),
       data_moe1:data[0][m].data_moe,
       data_moe2:data[1][m].data_moe,
       data_value1:data[0][m].data_value,
       data_value2:data[1][m].data_value})}
     

     if(data[1][m].data_value==='NA' || data[1][m].data_value===undefined)
    {
      msa2.push({name:data[0][m].race,
      y:0,
      data_moe1:0,
      data_moe2:0,
      data_value1:0,
      data_value2:0})}
    else{
      msa2.push({name:data[0][m].race,
      y:parseFloat(data[1][m].data_value),
      data_moe1:data[0][m].data_moe,
      data_moe2:data[1][m].data_moe,
      data_value1:data[0][m].data_value,
      data_value2:data[1][m].data_value})}
    }
    }
    else{
      let res = data[0]  
      res.map((data_val) => {
        categories.push(
           data_val.race+"<br/> <span style='font-weight:400'>"+data_val.data_value+"</span>"
        )
        if(data_val.data_value==='NA' || data_val.data_value===undefined)
        {msa1.push({name:data_val.race,
          y:0,
          data_moe:0})}
        else{msa1.push({name:data_val.race,
          y:parseFloat(data_val.data_value),
          data_moe:data_val.data_moe})}
      })
    }
 
    
    
    finalData.push({ name: 'category', data:multiColumn?categories1:categories })
    finalData.push({ name: 'Race', data: msa1 })
    finalData.push({ name: 'Race', data: msa2 })
  }
  if(chartName==='estimateChart')
  {
    if(data!==null && data.length>1)
    {
      for(let m=0;m<data[0].length;m++)
      {
        categories1.push(
          data[0][m].x_axis
           +"<br/> <span style='font-weight:400' data_val='^"+data[0][m].data_estimates_perc+"|"+data[1][m].data_estimates_perc+"^'></span>"
       )
       msa1.push({name:data[0][m].x_axis,
        y:data[0][m].data_estimates_perc,
        data_moe1:data[0][m].data_moe_perc,
        data_moe2:data[1][m].data_moe_perc,
        data_value1:data[0][m].data_estimates_perc,
        data_value2:data[1][m].data_estimates_perc,
        data_desc1:data[0][m].descriptions,
        data_desc2:data[1][m].descriptions})
       msa2.push({name:data[1][m].x_axis,
        y:data[1][m].data_estimates_perc,
        data_moe1:data[0][m].data_moe_perc,
        data_moe2:data[1][m].data_moe_perc,
        data_value1:data[0][m].data_estimates_perc,
        data_value2:data[1][m].data_estimates_perc,
        data_desc1:data[0][m].descriptions,
        data_desc2:data[1][m].descriptions})
      }
     
    }
    else
    {
      let res = data[0]  //JSON.parse(data)
      res.map((data_val) => {
      categories.push(
         data_val.x_axis
      )
      msa1.push({name:data_val.x_axis,y:data_val.data_estimates_perc,data_moe:data_val.data_moe_perc,data_desc:data_val.descriptions})
    })
    }
      finalData.push({ name: 'category', data:multiColumn?categories1:categories })
      finalData.push({ name: 'Estimates', data: msa1 })
      finalData.push({ name: 'Estimates', data: msa2 })
  }

  //dummy data
  const chartOptions =(chartName === 'genderAgechart'?
  getAgeGenderColumnChartOptions(finalData,renderType,chartName,multiColumn,locationName,searchLocationText,displayDate): 
  getColumnChartOptions(finalData,renderType,chartName,multiColumn,locationName,searchLocationText,displayDate))

  return chartOptions ? (
    <div className={styles.base}>
      <div className={styles.body}>
        <HighchartsReact
          containerProps={{
            style: {
              height: '100%',
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
           updateArgs={[true, true, true]}
        />
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default BarChartDisplay
