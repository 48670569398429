import { useState, useEffect } from 'react'
import { useGeoFetch } from '../components/GeoProvider'

export const useCBSADataFetch = (
  startDate,
  endDate,
  diseaseType,
  geo_parent_code
) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [error, setError] = useState()
  let url =
    geo_parent_code === '' || geo_parent_code === undefined
      ? 'activelocations?geo_type=MSA'
      : 'activelocations?geo_type=MSA&geo_parent_code=' + geo_parent_code
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    startDate,
    endDate,
    `${diseaseType}Locations`,
    url
  )

  useEffect(() => {
    setLoading(true)
    if (activeCbsasData?.length > 0) {
      setData(activeCbsasData)
      setLoading(false)
    } else if (activeCbsasData?.length === 0) {
      setLoading(false)
    }

    if (activeCbsasDataError) {
      setError(true)
      setLoading(false)
    }
  }, [activeCbsasData, activeCbsasDataError])

  return { data, error, loading }
}
