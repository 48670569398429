import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './FluIndices.module.scss'
import { useGeoFetch } from '../../components/GeoProvider'
import dayjs from 'dayjs'
import typeDisplayOptions from '../Dashboard/typeDisplayOptions'
import SelectList from '../../components/SelectMenuList'
import DefaultBreadcrumbs from '../../components/Breadcrumbs/DefaultBreadcrumbs'
import regionDisplayOptions from './regionDisplayOptions'
import MapOptions from '../../components/MapOptions'
const HeaderDisplay = ({ headerType }) => {
  const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')
  
  const [getgeoid, setgeoid] = useState('')
  const [getDatavalue, setDatavalue] = useState('')
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const { data: headerdata, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'vulnerability',
    'getheader/'+headerType
  )
  useEffect(() => {
    if (headerdata !== null && headerdata !== undefined) {
      if (headerdata.response_code === 200) {
        if (
          headerdata.response_data !== null &&
          headerdata.response_data !== undefined
        ) {
          setgeoid(headerdata.response_data.geo_id)
          setDatavalue(headerdata.response_data.vulnerability_index)
        }
      } else {
        setErrorSubject(headerdata.response_subject)
        seterrorOccured(true)
      }
    }
  }, [headerdata])

  if (stateDataError || errorOccured) {
    return (
      <div>
        {errorSubject !== undefined
          ? errorSubject
          : 'Unable to get the data for header'}{' '}
        : {errorSubject}
      </div>
    )
  }
  return (
    <>
      <span className={styles.firstSpan}> {getgeoid}</span>
      <span className={styles.forthSpan}> {getDatavalue}</span>
    </>
  )
}

const Header = ({ headerType, headerNumber }) => {
  const history = useHistory()
  const [activeCbsa, setActiveCbsa] = useState()
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'searchlocations/vulnerability?geo_type=MSA'
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      if (activeCbsasData.response_code === 200) {
        setActiveCbsa(activeCbsasData.response_data)
      
       }
    }
  }, [activeCbsasData])
  return (
    <>
      
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderText}>
          <DefaultBreadcrumbs
            geo_id={'NAT'}
            geo_type={'NAT'}
            dashboardType={'VULNERABILITY'}
          />
        <SelectList
         dropdownData={typeDisplayOptions}
         defaultValue={'vulnerability'}
         defaultLabel={'Vulnerability'}
        />
          {/* <SelectList
            dropdownData={typeDisplayOptions}
            defaultValue={'vulnerability'}
            defaultLabel={'Vulnerability'}
          /> */}
        </div>
        <div className={styles.pageHeaderTextValue}>
          <HeaderDisplay
            headerType={headerType}
            dashNo={headerNumber}
          ></HeaderDisplay>
           <MapOptions regionDisplayOptions={regionDisplayOptions} 
           activeCbsa={activeCbsa} 
           entity={'Vulnerability'}
           searchPlaceholder={'Search State or MSA'}
           />
          
        </div>
      </div>
    </>
  )
}
export default Header
