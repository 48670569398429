import React from "react";
import defaultBreakpoints from "./defaultBreakpoints";
import useMedia from "./useMedia";
import MediaContext from "./MediaContext";
import { WindowResize } from "../WindowEvent";

const ResponsiveMedia = ({
  breakpoints = defaultBreakpoints,
  children = null
}) => {
  const mediaKeys = Object.keys(breakpoints);
  const [media, setMedia] = React.useState(mediaKeys[0]);

  const update = React.useCallback(() => {
    for (let i = mediaKeys.length - 1; i > -1; i--) {
      if (i === 0 || window.matchMedia(breakpoints[mediaKeys[i]]).matches) {
        setMedia(mediaKeys[i]);
        break;
      }
    }
  }, [breakpoints, mediaKeys]);

  const value = mediaKeys.slice(0, mediaKeys.indexOf(media) + 1);

  return (
    <MediaContext.Provider value={value}>
      <WindowResize handler={update} invokeImmediately>
        {children}
      </WindowResize>
    </MediaContext.Provider>
  );
};

export {
  ResponsiveMedia as default,
  defaultBreakpoints,
  useMedia,
  MediaContext
};
