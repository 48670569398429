import React, { useState } from 'react'

import useGeoFetch from './useGeoFetch'

import useUHCGeoFetch from './useUHCGeoFetch'

const GeoContext = React.createContext()

function useGeoProvider(
  startDate,
  endDate,
  showingDate,
  countiesStartDate,
  countiesEndDate,
  options,
  weekEndDate,
  indicator,
  location,
  fDate,
  tDate,
  locationCd,
  regionName,
  geoLocationType,
  showAddsubscribe,
  showRiskResult,
  showProviders,
  regionSelectedDefault,  
  geoid,
  geotype,
  showPopup,
 
) {  
  const [loadedDate] = useState(showingDate)
  const [UHCStateLoadedDate, setUHCStateLoadedDate] = useState(showingDate)
  const [UHCCountyLoadedDate, setUHCCountyLoadedDate] = useState(showingDate)
  const [getPinPopup, setPinPopup] = useState(showPopup)
  const [getGeoIdandGeoType, setGeoIdandGeoType] = useState({geoid:geoid,geo_type:geotype})
  const [dataStartDate, setDataStartDate] = useState(startDate)
  const [startDateCounties, setStartDateCounties] = useState(countiesStartDate)

  const [dataEndDate, setDataEndDate] = useState(endDate)
  const [dataEndDateCounties, setDataEndDateCounties] =
    useState(countiesEndDate)
  const [displayDate, setDisplayDate] = useState(showingDate)
  const [displayWeek, setDisplayWeek] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [showWeek, setShowWeek] = useState(0)
  const [timelineIsPaused, setTimelineIsPaused] = useState(true)
  const [countyDataLoaded, setCountyDataLoaded] = useState(false)
  const [mapOptions, setMapOptions] = useState(options)
  const [regionSelected, setRegionSelected] = useState(regionSelectedDefault)
  const [isChecked, setIsChecked] = useState(false)
  const [lastweekEndDate, setLastWeekEndDate] = useState(weekEndDate)
  const [indicatorName, setIndicatorName] = useState(indicator)
  const [locationName, setLocationName] = useState(location)
  const [fromDate, setFromDate] = useState(fDate)
  const [toDate, setToDate] = useState(tDate)
  const [regionSelectedName, setRegionSelectedName] = useState(regionName)
  const [locationCode, setLocationCode] = useState(locationCd)
  const [locationType, setLocationType] = useState(geoLocationType)
  const [diseaseSelected, setDiseaseSelected] = useState('flu')
  const [claimsFiled, setClaimsFiled] = useState()
  const [showProvidersMap, setShowProvidersMap] = useState(false)
  const [showAddsubscribePop, setShowAddsubscribePop] = useState(false)
  const [getRiskResult, setRiskResult] = useState()

  const [getRespiratoryState,setRespiratoryState]=useState('')
  const [getAcuityState,setAcuityState]=useState('')
  const [getComorbidityState,setComorbidityState]=useState('')

  return {
    loadedDate,
    UHCStateLoadedDate,
    UHCCountyLoadedDate,
    getPinPopup,
    
    dataStartDate,
    startDateCounties,
    dataEndDate,
    dataEndDateCounties,
    displayDate,
    displayWeek,
    searchFilter,
    showWeek,
    timelineIsPaused,
    countyDataLoaded,
    mapOptions,
    regionSelected,
    isChecked,
    lastweekEndDate,
    indicatorName,
    locationName,
    fromDate,
    toDate,
    regionSelectedName,
    locationCode,
    locationType,
    claimsFiled,
    diseaseSelected,
    showAddsubscribePop,
    getRiskResult,
    showProvidersMap,
    getRespiratoryState,
    getAcuityState,
    getComorbidityState,
    getGeoIdandGeoType,
    setLocationType,
    setLocationCode,
    setRegionSelectedName,
    setToDate,
    setFromDate,
    setLocationName,
    setIndicatorName,
    setLastWeekEndDate,
    setUHCStateLoadedDate,
    setUHCCountyLoadedDate,
    setPinPopup,
    
    setDataStartDate,
    setStartDateCounties,
    setDataEndDate,
    setDataEndDateCounties,
    setDisplayDate,
    setDisplayWeek,
    setSearchFilter,
    setShowWeek,
    setTimelineIsPaused,
    setCountyDataLoaded,
    setMapOptions,
    setRegionSelected,
    setIsChecked,
    setDiseaseSelected,
    setShowAddsubscribePop,
    setRiskResult,
    setClaimsFiled,
    setShowProvidersMap,
    setRespiratoryState,
    setAcuityState,
    setComorbidityState,
    setGeoIdandGeoType,    
  }
}

const GeoProvider = ({
  children,
  startDate,
  startDateCounties,
  endDate,
  endDateCounties,
  displayDate,
  mapOptions,
  lastweekEndDate,
  indicatorName,
  locationName,
  fromDate,
  toDate,
  regionSelectedName,
  locationCode,
  locationType,
  showAddsubscribePop,
  getRiskResult,
  showProvidersMap,
  regionSelected,
  
  geoid,
  geotype,
  getRespiratoryState,
  getAcuityState,
  getComorbidityState,
  

}) => {
  const dataFilter = useGeoProvider(
    startDate,
    endDate,
    displayDate,
    startDateCounties,
    endDateCounties,
    mapOptions,
    lastweekEndDate,
    indicatorName,
    locationName,
    fromDate,
    toDate,
    regionSelectedName,
    locationCode,
    locationType,
    showAddsubscribePop,
    getRiskResult,
    showProvidersMap,
    regionSelected,
    geoid,geotype,
    getRespiratoryState,
    getAcuityState,
    getComorbidityState,
    
  )

  return (
    <GeoContext.Provider value={dataFilter}>{children}</GeoContext.Provider>
  )
}

export const useGeoState = () => {
  const context = React.useContext(GeoContext)
  if (!context) {
    throw new Error('useGeoState must be used within an GeoProvider')
  }
  return context
}

export { GeoProvider as default, useGeoFetch, useUHCGeoFetch }