import React from 'react'
import { Link } from 'react-router-dom'
import { useAuthState } from '../AuthProvider'

const BackToHomeLink = () => {
  const { isAuthenticated } = useAuthState()

  return isAuthenticated ? (
    <Link to="/">Back to Home</Link>
  ) : (
    <>
    
      <Link style={{display:'flex', justifyContent:'center',alignItems:'center',height:'40px', backgroundColor:'#002677', borderRadius:'999px', marginTop:'24px', width:'8%', color:'white'}} to="/">Sign In</Link>
    </>
  )
}

export default BackToHomeLink
