import { React } from 'react'
import styles from './RespiratoryCondition.module.scss'
import RespiratoryGraph from './RespiratoryGraph'
import AcuityGraph from './AcuityGraph'
import ComorbidityGraph from './ComorbidityGraph'

const RespiratoryCondition = ({ geo_type, geo_id }) => {
return  (
    <div className={styles.base}>
      <RespiratoryGraph geo_type={geo_type} geo_id={geo_id}/>
      <AcuityGraph geo_type={geo_type} geo_id={geo_id}/>
      <ComorbidityGraph geo_type={geo_type} geo_id={geo_id}/>
    
    </div>
    
  )
}

export default RespiratoryCondition
