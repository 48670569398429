import React from 'react'

import styles from './Icon.module.scss'

const Icon = ({ className, size: fontSize, type, padding, color }) => (
  <i
    className={`${styles.base} ${className ? className : ''} ${styles[type]}`}
    style={{ fontSize, padding, color }}
  />
)

export default Icon
