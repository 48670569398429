const colorAxis = {
  dataClasses: [
    {
      to: 0,
      color: '#6D6F70',
      plotBorderColor: '#6D6F70',
      borderWidth: 2,
      name: 'NA',
    },
    {
      from: 0.0000000001,
      to: 1,
      color: '#E5FDF7',
      plotBorderColor: '#2B9181',
      borderWidth: 2,
      name: 'Minimal',
    },
    {
      from: 1.0000000001,
      to: 2,
      color: '#AAC9E4',
      plotBorderColor: '#2D6EA3',
      borderWidth: 2,
      name: 'Low',
    },
    {
      from: 2.0000000001,
      to: 3,
      color: '#7796CC',
      plotBorderColor: '#0E456B',
      borderWidth: 2,
      name: 'Medium',
    },
    {
      from: 3.0000000001,
      to: 4,
      color: '#5163AE',
      plotBorderColor: '#FFFFFF',
      borderWidth: 2,
      name: 'High',
    },
    {
      from: 4.0000000001,
      color: '#422C88',
      plotBorderColor: '#FFFFFF',
      borderWidth: 2,
      name: 'Very High',
    },
  ],
}
export default colorAxis
