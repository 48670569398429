import axios from 'axios'
import fetchIntercept from 'fetch-intercept'
import { USER_AUTH_KEY } from './constants'

export const registerAxiosInterceptor = () => {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        window.location.replace('/signout')
      } else if (error.response.status === 403) {
        window.location.replace('/access-denied')
      }

      return Promise.reject(error)
    }
  )
}

// This function takes any existing headers that are being sent in the request as a JSON object,
// adds the headers that need to be added and sends them out as instance of a Headers class
const addHeadersToExistingHeaders = (existingHeaders, headers) => {
  let newHeaders = new Headers()

  // Create a headers object from the existing headers if its not null
  if (existingHeaders) {
    newHeaders = new Headers(existingHeaders)
  }

  // Append the required headers to the existing headers
  for (let header in headers) {
    newHeaders.append([header], headers[header])
  }
  return newHeaders
}

export const registerFetchInterceptor = () => {
  fetchIntercept.register({
    request: (url, config) => {
      const withDefaults = Object.assign({}, config)

      // Add authenticatedUser header for local environment alone
      if (
        process.env.REACT_APP_DEVELOPMENT_MODE === undefined ||
        process.env.REACT_APP_DEVELOPMENT_MODE === 'true'
      ) {
        const sessionUser = window.localStorage.getItem(USER_AUTH_KEY)

        let headers = addHeadersToExistingHeaders(withDefaults.headers, {
          authenticatedUser: sessionUser,
        })
        // withDefaults.headers should be a instance of the Headers class
        withDefaults.headers = headers
      }

      return [url, withDefaults]
    },
    response: (response) => {
      if (response.status === 401) {
        window.location.replace('/')
      } else if (response.status === 403) {
        window.location.replace('/access-denied')
      } else return response
    },
  })
}

export const registerAxiosRequestInterceptor = () => {
  axios.interceptors.request.use((config) => {
    if (
      config.url.toLowerCase().indexOf('api') !== -1 &&
      config.url.toLowerCase().indexOf('login') === -1
    ) {
      config.withCredentials = false
    }

    // Add authenticatedUser header for local environment alone
    if (
      process.env.REACT_APP_DEVELOPMENT_MODE === undefined ||
      process.env.REACT_APP_DEVELOPMENT_MODE === 'true'
    ) {
      const sessionUser = window.localStorage.getItem(USER_AUTH_KEY)
      config.headers.common['authenticatedUser'] = sessionUser
    }
    return config
  })
}
