import Message from '../../components/Message'
import  { useGeoState } from '../../components/GeoProvider'
const SystemNotification = (type,key,message) => {

    const {showAddsubscribePop,setShowAddsubscribePop} =useGeoState()
    return (
        <div style={{display:showAddsubscribePop?'block':'none'}}>
        <Message
        type="warning"
        onDismiss={() => {
          setShowAddsubscribePop(false)
        }}
        key={1}
      >
        {"Notice: Seasonal forecasting models are under review and will be finalized shortly."}
      </Message>
      </div>
    )
}
export default SystemNotification