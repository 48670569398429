import dayjs from 'dayjs'

export const outbreak = '2020-01-01'
export const startDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD')
export const endDate = dayjs().day(0).add(182, 'day').format('YYYY-MM-DD') //someFutureSunday
export const displayDate = dayjs().day(-1).format('YYYY-MM-DD')
export const lastDisplayDate = dayjs().day(-7).format('YYYY-MM-DD')
export const weekEndDate = dayjs()
  .day(+6)
  .format('YYYY-MM-DD')
export const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')
export const forecastsStartDate= dayjs().subtract(6, 'month').format('YYYY-MM-DD')
export const actualsStartDate= dayjs().subtract(1, 'year').format('YYYY-MM-DD')
