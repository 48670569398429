export default function getEpiWeek(date) {
  const date1 = new Date(date);
  const firstDayOfYear = new Date(date1.getFullYear(), 0, 1);
  let firstThursdayOfYear = new Date(firstDayOfYear);
 
  // Adjust to the first Thursday of the year
  while (firstThursdayOfYear.getDay() !== 4) {
    firstThursdayOfYear.setDate(firstThursdayOfYear.getDate() + 1);
  }
  // Calculate the difference in days between the given date and the first Thursday of the year
  const daysSinceFirstThursdayOfYear = Math.floor((date1.getTime() - firstThursdayOfYear.getTime()) / (1000 * 60 * 60 * 24));
  // Calculate the epidemiological week
  const eWeek = Math.floor(daysSinceFirstThursdayOfYear / 7) + 1;
 
  return eWeek;
}
