export default function spreadOutObject(
  obj,
  targetOutputLength,
  loadedDayIndex,
  latestPositionString
) {
  const originalObjLength = Object.keys(obj).length

  if (originalObjLength === 0) {
    return false
  }

  if (originalObjLength <= targetOutputLength) {
    return obj
  }

  const output = { 0: obj[0] }
  const interval = originalObjLength / targetOutputLength
  for (
    let index = originalObjLength - 1;
    index > 0;
    index = index - interval
  ) {
    // eslint-disable-next-line prefer-destructuring
    output[Math.floor(index)] = obj[Math.floor(index)]
  }
  // output[loadedDayIndex] = latestPositionString
  return output
}
