export default function MapLegend(props) {
  return (
    <svg viewBox="0 0 250 25" height="25" width="250" align="center" {...props}>
      <text
        x="10"
        y="12"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Very High
      </text>
      <rect
        x="75"
        y="0"
        width="16"
        height="16"
        fill="#C40000"
        stroke="#FFFFFF"
        strokeWidth="1px"
      ></rect>
      <rect
        x="100"
        y="0"
        width="16"
        height="16"
        fill="#FB8D7B"
        stroke="#990000"
        strokeWidth="1px"
      ></rect>
      <rect
        x="125"
        y="0"
        width="16"
        height="16"
        fill="#FFFFF0"
        stroke="#AD8D01"
        strokeWidth="1px"
      ></rect>
      <rect
        x="150"
        y="0"
        width="16"
        height="16"
        fill="#6CB2A2"
        stroke="#0D5E4F"
        strokeWidth="1px"
      ></rect>
      <rect
        x="175"
        y="0"
        width="16"
        height="16"
        fill="#218371"
        stroke="#FFFFFF"
        strokeWidth="1px"
      ></rect>

      <text
        x="200"
        y="12"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Minimal
      </text>
      {/* <text
        x="25"
        y="13"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Very High
      </text>
      <text
        x="25"
        y="39"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        High
      </text>
      <text
        x="25"
        y="63"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Medium
      </text>
      <text
        x="25"
        y="87"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Low
      </text>
      <text
        x="25"
        y="113"
        style={{
          width: '57px',
          height: '16px',
          fontWeight: 400,
          fontSize: '12.64px',
        }}
      >
        Minimal
      </text> */}

      {/* <path stroke="#CBCCCD" d="M 0 123 L 18 123" fill="#CBCCCD"></path>

      <text
        x="25"
        y="142"
        style={{
          width: '57px',
          height: '16px',
          fontw: 400,
          fontSize: '12.64px',
        }}
      >
        No Data
      </text> */}
    </svg>
  )
}
