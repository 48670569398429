import { React, useState, useEffect } from 'react'
import styles from './RespiratoryGraph.module.scss'
import { useGeoFetch,useGeoState } from '../../../components/GeoProvider'
import DrillDownChart from '../../../components/DrillDownChart'
import drilldownFilterOptions from './drilldownFilterOptions'
import SelectList from '../../../components/SelectList'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import Spinner from '../../../components/Loader/Spinner'

const ComorbidityGraph = ({ geo_type, geo_id }) => {
  
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getDrillDownData, setDrillDownData] = useState()
  const [getComorbidityData, setComorbidityData] = useState()
  const { locationName,displayDate } = useGeoState()
  const [drillDownSelectedValue,setDrillDownSelectedValue]=useState('')
  const setDrillDownSelectedValueMethod=(data)=>{setDrillDownSelectedValue(data)}
  const { data: comData, error: comDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'getdrilldown/'+geo_type+'/'+geo_id+'/comorbidity'
  )
  useEffect(() => {
    if (comData !== null && comData !== undefined) {
      if (comData.response_code === 200) {
        setComorbidityData(comData.response_data)
      } else {
        setErrorCode(comData.response_code)
        setErrorMessage(comData.response_message)
        setErrorSubject(comData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [comData])

  const { data: drillData, error: drillDataError } = useGeoFetch(
    '',
    '',
    'vulnerability',
    'getdrilldownjson/'+geo_type+'/'+geo_id
  )

  useEffect(() => {
    if (drillData !== null && drillData !== undefined) {
      if (drillData.response_code === 200) {
        setDrillDownData(drillData.response_data)
      } else {
        setErrorCode(drillData.response_code)
        setErrorMessage(drillData.response_message)
        setErrorSubject(drillData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [drillData])
  if ( errorOccured ||drillDataError  ||comDataError ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={''}
        errorSubject={errorSubject}
        errorDetails={errorMessage+' for Drill Down Graphs'}
      />
    )
  }
   
return  (
    <div className={styles.base}>
     
      <div className={styles.maindivFormat}>
        
          <span className={styles.captionFormat}>
          Comorbidity
            <p className={styles.subCaptionFormat}>
            Chart is displaying data from previous 10 weeks
            </p>
          </span>
     
          <SelectList
            dropdownData={drilldownFilterOptions[2]['options']}
            defaultValue={'4'}
            type={"Comorbidity"} 
            defaultLabel={drilldownFilterOptions[2]['options'][0].label}
            selectType={'drilldown'}
            setDrillDownSelectedValueMethod={setDrillDownSelectedValueMethod}
          />
       
        {getComorbidityData !== undefined 
        && locationName!==undefined 
        && getDrillDownData!==undefined 
        && drillDownSelectedValue!=='5'
        ? (
            <>
        <DrillDownChart data={getComorbidityData}
         type={"Comorbidity"} 
        locationName={locationName}
         dataSeries={JSON.parse(getDrillDownData[4].json_value)}
         displayDate={displayDate}
         drillDownState={drillDownSelectedValue}/>
        </>
          ) : ''}
           {getComorbidityData !== undefined 
        && locationName!==undefined 
        && getDrillDownData!==undefined 
        && drillDownSelectedValue==='5'
        ? (
            <>
        <DrillDownChart data={getComorbidityData}
         type={"Comorbidity"} 
        locationName={locationName}
         dataSeries={JSON.parse(getDrillDownData[5].json_value)}
         displayDate={displayDate}
         drillDownState={drillDownSelectedValue}/>
        </>
          ) : ''}
          
        
      </div>
    
    </div>
    
  )
}

export default ComorbidityGraph
