import React , {useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './StateHeader.module.scss'
import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import GeoProvider from '../../../components/GeoProvider'
import DashboardMap from './DashboardMap'
import dataDisplayOptions from './../dataDisplayOptions'
import { startDate, endDate, displayDate,actualsStartDate,forecastsStartDate } from '../dates'
import Header from './Header'
import Gender from '../../GenderAge'
import TrendChart from '../../TrendChart'
import SystemNotification from './../../Dashboard/SystemNotification'

const CovidStateDashboard = () => {
  const { area, loc } = useParams()
  const [actualStartDate, setActualStartDate] = useState(actualsStartDate)
  const [forecastStartDate, setForecastStartDate] = useState(forecastsStartDate)
  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.body}>
            <GeoProvider
              startDate={startDate}
              endDate={endDate}
              displayDate={displayDate}
              mapOptions={dataDisplayOptions[0]['options'][0]}
              indicatorName={dataDisplayOptions[0]['options'][0].indicatorName}
              regionSelected={'state'}
            >
              <SystemNotification/>
              <Header headerType={area} headerNumber={2} geo_id={loc} />
              <DashboardMap
                geo_type={area}
                geo_parent={loc}
                zoomLocation={loc}
              />
              <Gender data_type="COVID" geo_type={area} geo_id={loc} />
              <TrendChart actualStartDate={actualStartDate}
                        setActualStartDate={setActualStartDate}
                        forecastStartDate={forecastStartDate}
                        setForecastStartDate={setForecastStartDate}
                        time={'-1'} geo_type={area} geo_id={loc} data_type="COVID">
                        </TrendChart>
            </GeoProvider>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default CovidStateDashboard
