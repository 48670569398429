import React from 'react'
import Container from '../Container'
// import BellIcon from 'react-bell-icon'

import { ThemeContext } from '../Theme'

import styles from './ProfileHeader.module.scss'

const padding = { xs: [0, 1], md: [0, 3], lg: [0, 4], xl: [0, 5] }

const ProfileHeader = ({ appName, children = null, logoUrl, onLogoClick }) => {
  const theme = React.useContext(ThemeContext)

  return (
    <header className={`${styles.base} ${styles[theme]}`}>
      <Container className={styles.container} padding={padding}>
        {children && <div className={styles.items}>{children}</div>}
      </Container>
    </header>
  )
}

export default ProfileHeader
