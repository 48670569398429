import Highcharts from 'highcharts'
import HT from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import {dateWeekToolTip} from './toolTipFormatter'
import Spinner from '../../components/Loader/Spinner'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}
const CovidCaseTrend = ({
  seasonalPattern,
  seasonalPatternForecast,
  actualStartDate,
  forecastStartDate,
  height = '300px',
  loc,
  currentWeekEnd
}) => {
  let transformedData = []
  if (seasonalPattern !== null || seasonalPattern !== undefined) {
  
    Object.entries(seasonalPattern).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][loc]
      transformedData.push({ date: key, ...value })
    })
  }
  let transformedDataForecast = []
  if (seasonalPatternForecast !== null || seasonalPatternForecast !== undefined) {
   
    Object.entries(seasonalPatternForecast).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][loc]
      transformedDataForecast.push({ date: key, ...value })
    })
  }
var j=0;
  const casesActual = transformedData
    .filter(
      (d) =>
        new Date(d.week_ending) <= new Date() &&
        new Date(d.week_ending) >= new Date(actualStartDate)
    )
    .sort((a, b) => (a.week_ending > b.week_ending ? 1 : b.week_ending > a.week_ending ? -1 : 0))
    .map((d,j) => {
      var flag=false;
      if(j%4===0)
      {flag=true}
      j++;
      return {x:new Date(d.week_ending).getTime(),y:d.data_value,marker:{enabled:flag}}
    })
 j=0;   
  const casesForecast = transformedDataForecast
  .filter(
    (d) =>
     
      new Date(d.week_ending) >= new Date(forecastStartDate)
  )
  .sort((a, b) => (a.week_ending > b.week_ending ? 1 : b.week_ending > a.week_ending ? -1 : 0))
  .map((d) => {
    var flag=false;
      if(j%4===0)
      {flag=true}
      j++;
    return {x:new Date(d.week_ending).getTime(),y:d.data_value,marker:{enabled:flag}}

  })

    const casesForecastPrediction = transformedDataForecast
    .filter(
      (d) =>
      
        new Date(d.week_ending) >= new Date(forecastStartDate)
    )
    .sort((a, b) => (a.week_ending > b.week_ending ? 1 : b.week_ending > a.week_ending ? -1 : 0))
    .map((d) => {
     
      return {x:new Date(d.week_ending).getTime(),low:d.low95,high:d.high95,marker:{enabled:false}}
    })
  const chartOptions = {
    title: {
      text: undefined,
    },
  
    xAxis: {
      type: 'datetime',
      accessibility: {
        rangeDescription: 'Date Range',
      },
      labels: {
        formatter: function () {
          return Highcharts.dateFormat('%b %Y', this.value - 86400000) //added
        },
      },
      lineColor: '#929496',
      lineWidth: 1,
      plotLines: [
        {
          color: '#cdcdcd',
          width: 1,
          value: new Date(currentWeekEnd).getTime(),
          zIndex: 10,
          label: {
            x: -50,
            rotation: 0,
            text:  moment(currentWeekEnd).format('MMM DD,YYYY') ,
            style: { color: '#4B4D4F', fontSize: '12px' },
          },
        },
      ],
     
    },
    yAxis: {
      title: {
        text: 'Incidence',
        style: {
          color: Highcharts.getOptions().colors[1],
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          fontSize: '16px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
      },
      gridLineColor: '#CBCCCD',
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: dateWeekToolTip,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },

    series: [
      {
        name: 'Actual', 
        data: casesActual,
        zIndex: 1,
        color: '#15A796', 
        marker: {
          enabled: true,
        
        },
      },
      
      {
        name: 'Forecast',
        data: casesForecast,
        zIndex: 1,
        color: '#15A796', 
        dashStyle: 'ShortDot',
        marker: {
          enabled: true,
       
        },
      },
      
      {
        name: 'Prediction Intervals',
        data: casesForecastPrediction,
        type: 'arearange', 
        lineWidth: 0,
      linkedTo: ':previous',
      color: '#C7EAE6',
      fillOpacity: 0.25,
      zIndex: 0,
      showInLegend: true,
      visible: true,
      marker: {
        enabled: true,
      
      },
      },
     
    ],
  }
  return (
    <>
     
      {casesActual.length !== 0 && casesForecast.length !== 0 ? (
        <HighchartsReact
          containerProps={{
            style: {
              width: '100%',
              position: 'relative',
              height: height,
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      ) : (
          <Spinner />

      )}
    </>
  )
}

export default CovidCaseTrend
