import React from 'react'
import { Dialog } from '@reach/dialog'
import Card from '../Card'

import '@reach/dialog/styles.css'
import styles from './ModalPopup.module.scss'

const ModalPopup = ({
  children,
  onClose,
  open,
  margin = '72%',
  setWidth = '109vw',
  setHeight = '95vh',
  display = 'flex',
  overflow = 'auto',
}) => (
  <Dialog
    className={styles.base}
    role="dialog"
    aria-labelledby="dialog"
    onDismiss={onClose}
    isOpen={open}
    style={{
      marginLeft: margin,
      width: setWidth,
      height: setHeight,
      // display: display,
      overflow: overflow,
    }}
  >
    <Card className={styles.card} primary>
      {children}
    </Card>
  </Dialog>
)

export default ModalPopup
