import React, { useRef, useState, useEffect } from 'react'
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  GeoJSON,
  LayersControl,
  FeatureGroup,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import iconMarker from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import 'leaflet-geosearch/dist/geosearch.css'
import getThresholdValue from '../../utils/getThresholdValue'
import Markers from '../FluDashboardNew/ProviderMarker'
import mapToolTipFormatter from './MapToolTip'
import { useGeoState } from '../../components/GeoProvider'
import L from 'leaflet'
let DefaultIcon = L.icon({
  iconUrl: iconMarker,
  shadowUrl: iconShadow,
})
const StateWithProviderMarker = ({
  statesData,
  stateEnable,
  msaEnable,
  cbsaData,
  activeCbsa,
  providers,
  msaid,
  state,
  zip3Enable,
  zip3Data,
  activeZip3Data,
  stateRef,
  msaRef,
  zip3Ref,
}) => {
  const {
    displayDate,
    regionSelected,
    setPinPopup,
    setGeoIdandGeoType,
    getGeoIdandGeoType,
  } = useGeoState()
  const [getCBSAJsonData, setCBSAJsonData] = useState()
  const [getJsonData, setJsonData] = useState()
  const [getZipJson, setZipJson] = useState()
  const [getCBSAJson, setCBSAJson] = useState()
  const [getMSAEnable, setMSAEnable] = useState(msaEnable)
  const [getflagmsa, flagmsa] = useState(false)
  const marker = useRef(null)
  const mapRef = useRef(null)

  const stateMap = {
    lat: 39.82,
    lng: -98.58,
    zoom: 4.1,
  }
  const positionMap = [stateMap.lat, stateMap.lng]

  const onEachFeatureState = (feature, layer) => {
    var data = Object(statesData)[displayDate][feature.properties.geoid]
    feature.properties = { ...feature.properties, ...data }
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: ZoomMapLayer,
    })
    layer.bindTooltip(mapToolTipFormatter(feature.properties))

    layer.setStyle({
      fillColor: getThresholdValue(feature.properties.forecast_level).color,
      weight: 2,
      opacity: 1,
      color: getThresholdValue(feature.properties.forecast_level).OutlineColor,
      dashArray: '2',
      fillOpacity: 0.7,
    })

    // var labelLocation = new L.LatLngBounds(feature.geometry.coordinates);
    // var labelTitle = new L.LabelOverlay(labelLocation, '<b>GERMANY</b>');
    // mapRef.addLayer(labelTitle);

    // var options = {},
    // labelOverlay = new L.ImageOverlay(layer, "Hello, I'm a label overlay", options);
    // var myIcon = L.divIcon({
    //   iconSize: new L.Point(50, 50),
    //   html: 'foo bar'
    //   });
    //   layer.addLayer(myIcon);
  }

  const onEachFeatureMsa = (feature, layer) => {
    let geoid = getflagmsa ? getGeoIdandGeoType.geoid : feature.properties.geoid
    var data = Object(cbsaData)[displayDate][parseInt(geoid)]
    feature.properties = { ...feature.properties, ...data }
    feature.properties['highlight'] = true
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: ZoomMapLayer,
    })
    layer.bindTooltip(mapToolTipFormatter(feature.properties))

    layer.setStyle({
      fillColor: getThresholdValue(feature.properties.forecast_level).color,
      weight: 1,
      opacity: 1,
      color: getThresholdValue(feature.properties.forecast_level).OutlineColor,
      dashArray: '1.5',
      fillOpacity: 0.7,
    })
  }

  const MSAFilter = (feature) => {
    
    //debugger
    let geoid = getflagmsa ? getGeoIdandGeoType.geoid : feature.properties.geoid
    var data = Object(cbsaData)[displayDate][parseInt(geoid)]
    feature.properties = { ...feature.properties, ...data }
    
    if (regionSelected === 'metro') {
      return true
    } else {
      if (getGeoIdandGeoType.geoid !== feature.properties.geo_parent) {
        return false
      } else {
        return true
      }
    }
  }

  const onEachFeatureZip3 = (feature, layer) => {
    var data = Object(zip3Data)[displayDate][parseInt(feature.properties.geoid)]
    feature.properties = { ...feature.properties, ...data }

    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: ZoomMapLayer,
    })
    layer.bindTooltip(mapToolTipFormatter(feature.properties))
    layer.setStyle({
      fillColor: getThresholdValue(feature.properties.forecast_level).color,
      weight: 1,
      opacity: 1,
      color: getThresholdValue(feature.properties.forecast_level).OutlineColor,
      dashArray: '1.5',
      fillOpacity: 0.7,
    })
  }

  const resetHighlight = (e) => {
    var layer = e.target
    layer.setStyle({})
  }

  const highlightFeature = (e) => {
    //var layer = e.target;
    // layer.setStyle({
    //     weight: 3,
    //     color: '#666',
    //     dashArray: '',
    //     fillOpacity: 0.7
    // });
    //layer.bringToFront();
  }

  function ZoomMapLayer(e) {
    debugger
    setGeoIdandGeoType({ ...e.target.feature.properties })
    mapRef.current.flyToBounds(e.target.getBounds())
    setMSAEnable(true)
    flagmsa(true)
  } 

  useEffect(() => {
    let url = `${window.location.origin}/state.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setJsonData(data)
      })
      .catch(function (err) {
        // console.log(err, ' error file state ')
      })
  }, [])

  useEffect(() => {
    let url = `${window.location.origin}/metropolitan_statistical_area.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        let filteredFeats = []
        setCBSAJson(data)
        data.features.map((feature) => {
          activeCbsa.includes(feature.properties.geoid)
          if (activeCbsa.includes(feature.properties.geoid)) {
            filteredFeats.push(feature)
          }
        })
        setCBSAJsonData(filteredFeats)
      })
      .catch(function (err) {
        // console.log(err, ' error file us_hrr_values ')
      })
  }, [activeCbsa])

  useEffect(() => {
    let url = `${window.location.origin}/three_dig_zips_latest.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        let filteredFeats = []
        data.features.map((feature) => {
          activeZip3Data.includes(feature.properties.geoid)
          if (activeZip3Data.includes(feature.properties.geoid)) {
            filteredFeats.push(feature)
          }
        })
        setZipJson(filteredFeats)
      })
      .catch(function (err) {
        // console.log(err, ' error file us_hrr_values ')
      })
  }, [activeZip3Data])

  return (
    getJsonData && (
      <div id="content">
        <div id="map">
          <MapContainer
            zoom={stateMap.zoom}
            center={positionMap}
            zoomControl={false}
            ref={mapRef}
            style={{ height: '85vh', width: '100wh' }}
          >
            <ZoomControl position="bottomright" />
            <TileLayer
              noWrap={true}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <Markers providers={providers}/> */}
            {stateEnable && (
              <GeoJSON
                key={displayDate}
                ref={stateRef}
                data={getJsonData}
                onEachFeature={onEachFeatureState}
              ></GeoJSON>
            )}
            {getMSAEnable && getCBSAJsonData && (
              <GeoJSON
                key={displayDate + getGeoIdandGeoType.geoid + getflagmsa}
                ref={msaRef}
                data={getCBSAJsonData}
                onEachFeature={onEachFeatureMsa}
                filter={MSAFilter}
              ></GeoJSON>
            )}
            {zip3Enable && getZipJson && (
              <GeoJSON
                key={displayDate}
                ref={zip3Ref}
                data={getZipJson}
                onEachFeature={onEachFeatureZip3}
              ></GeoJSON>
            )}
          </MapContainer>
        </div>
      </div>
    )
  )
}

export default StateWithProviderMarker
