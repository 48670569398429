import React from 'react'

import styles from './SearchInput.module.scss'

const SearchInput = React.forwardRef(
  ({ hasCriteria, id, onChange, searchText,searchValue, placeholder, ...rest }, ref) => (
    <input
      autoComplete="off"
      className={styles.base}
      id={id}
      onChange={({ target: { value } }) => onChange(value)}
      onBlur={rest.onBlur}
      onFocus={rest.onFocus}
      onKeyDown={rest.onKeyDown}
      placeholder={placeholder}
      ref={ref}
      type="text"
      value={searchValue}
      style={{ fontSize: rest.fontSize }}
    />
  )
)

export default SearchInput
