import React, { useState } from 'react'
import styles from './FluIndices.module.scss'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import GeoProvider from '../../components/GeoProvider'
import DashboardMap from './DashboardMap'
import dataDisplayOptions from './dataDisplayOptions'
import { useGeoState } from '../../components/GeoProvider'
import Header from './Header'
import Gender from '../GenderAge'
import SeasonalPattern from '../SeasonalPattern'
const AllDashboard = () => {
  const [getData_type, setData_type] = useState('FLU')
  const { getGeoIdandGeoType  } = useGeoState() 
   
  return (
    <>
      <Header headerType="NAT" headerNumber={1} />
      <DashboardMap />
   
         <Gender
        geo_type={getGeoIdandGeoType.geo_type}
        geo_id={getGeoIdandGeoType.geoid}
        data_type={getData_type}
      />
      
      <SeasonalPattern
        geo_type={getGeoIdandGeoType.geo_type}
        geo_id={getGeoIdandGeoType.geoid}
        data_type={getData_type}
      /> 
    </>
  )
}

export default AllDashboard
