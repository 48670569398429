import { elementRoles } from 'aria-query'
import { el } from 'date-fns/locale'
import React from 'react'
import { useHistory } from 'react-router-dom'

import usStateAll from '../Map/usStatesAll'

import styles from './SearchPopover.module.scss'

const SearchPopoverStateItems = React.forwardRef(
  ({ criteria, onAdd, searchText, visible: isVisible, entity ,setSearchText,
    setSearchValue,setPopoverVisible,setSearchLocationValue,setSearchLocationTextMethod}, ref) => {
    const history = useHistory()

    const filteredStates = usStateAll.filter((state) => {
      if (
        (state.label &&
          state.label.toLowerCase().includes(searchText.toLowerCase())) ||
        state.value.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return state
      } else {
        return null
      }
    })
    const slicedFilteredStates = filteredStates.slice(0, 10)

    return (
      <>
        {slicedFilteredStates.map((option) => (
          <li
            data-option
            key={option.value}
            onClick={() =>
              {
                if(entity==='VulnerabilityEvent')
                {
                setSearchLocationValue(option.value)
                setSearchText(option.value)
                setSearchValue(option.label)
                setSearchLocationTextMethod(option.label)
                setPopoverVisible(false)
                }
                else{(window.location.href = `/${entity}StateDashboard/STATE/${option.value}`)}
              }
            
            }
            onKeyDown={(event) => {
              
              const { key, currentTarget } = event
              const next = key === 'ArrowDown'
              const prev = key === 'ArrowUp'
              if (!next && !prev) return

              event.preventDefault()

              const nextFocus =
                currentTarget[
                  next ? 'nextElementSibling' : 'previousElementSibling'
                ]
              if (nextFocus) nextFocus.focus()
            }}
            // onKeyPress={
            //   ({ key }) =>
            //     key === 'Enter'
            //       ? 
            //       (entity==='Vulnerability'?setSearchText(option.value):
            //        (window.location.href = `/${entity}StateDashboard/STATE/${option.value}`))
            //       : ''
            //   // history.push(`/StateDashboard/STATE/${option.value}`)
            // }
            tabIndex={-1}
          >
            <span>
              {option.label} ({option.value}){' '}
              <span style={{ color: '#7D7F81', paddingLeft: '5px' }}>
                {' '}
                State
              </span>
            </span>
          </li>
        ))}
      </>
    )
  }
)
export default SearchPopoverStateItems
