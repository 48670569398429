import Highcharts from 'highcharts'
import HC_patternFill from 'highcharts-pattern-fill'
import HC_rounded from 'highcharts-rounded-corners'
import HC_drilldown from 'highcharts/modules/drilldown.js';
import CountingMethod from '../../utils/getCountingMethod'
HC_rounded(Highcharts)
HC_patternFill(Highcharts)
HC_drilldown(Highcharts)

function getColumnChartOptions(finalData,pointLine,titleText,locationName,dataSeries,type,displayDate) {
  return {
    chart: {
      type: "column",
       height: 300,     
        marginTop:25
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      type: "category",
      labels: {
        style: {
          fontSize: '16px',
          fontWeight: '400',
          color: '#4B4D4F',
          
        },
      },
    },
    yAxis: {
      title: {
        text: titleText,
        style: {
          color: Highcharts.getOptions().colors[1],
          fontSize: '14px',
        },
      },
     
      labels: {
        style: {
          fontSize: '16px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          return this.total 
        },
      },
    },
    legend: {
      enabled: false
    },
    tooltip: {
      outside:true,
      enabled: true, //Added to disable tooltip
      borderRadius: 10,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      style: {
        padding: 0,
      },
      
      formatter: function () {
        return (
          `<div style="background-color: #FFFFFF;color:4B4D4F;padding: 12px 12px;border: 1px  solid #CBCCCD;
          border-radius: 4px; font-family: 'Optum Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          ">
          <div >${displayDate}</div>
          <div style='padding-top:10px'>${this.key}</div>
          <div style="color:#002677;padding-top:10px">${locationName}</div>
          <div style='padding-top:10px'>${CountingMethod(this.y,'fixed')}
          </div>`
        )
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            return CountingMethod(this.y,'fixed')
          },
        },
        enableMouseTracking: true,
      },
      series: {
        borderRadiusTopLeft: '30%',
        borderRadiusTopRight: '30%',
        // cursor: 'pointer',
        showInLegend: false,
        color: '#15A796',
        enableMouseTracking: true,
        pointWidth: 25,
        dataLabels: {
          enabled: true,
          style:{fontSize:'14'} 
      }
       
      },
    },
    series: [{
      name:type,
      data:finalData,
    
  }],
    drilldown: {
      breadcrumbs: {
        position: {
          align: 'right',
          x: 0,
          y: -30,
        },
        
      separator: {
        text:'>',
          style: {
              fontSize:'14px'
          }
      },
        buttonTheme: {
          style: {
            fontSize:'14px',
          
          }
        },
        format: '{level.name}',
      
    },
      activeAxisLabelStyle: {
        textDecoration: 'none',
        fontSize: '14px',
        fontWeight: '700',
        color: '#4B4D4F',
       
    },
    activeDataLabelStyle: {
        textDecoration: 'none',
        fontSize: '14px',
        fontWeight: '400',
        color: '#4B4D4F',
      
    },
      series: dataSeries
    }
  }
}

export default getColumnChartOptions