import React from "react";
import Icon from "../Icon";

import styles from "./Label.module.scss";

const Label = ({
  children = null,
  error: hasError,
  required: isRequired,
  ...rest
}) => (
  <label className={`${styles.base} ${hasError ? styles.error : ""}`} {...rest}>
    {hasError && <Icon type="warning_filled" />}
    {children}
    {isRequired && <span className={styles.required}>*</span>}
  </label>
);

export default Label;
