import React from 'react'

import Spinner from '../Loader/Spinner'
import BarChartDisplay from './ColumnChartDisplay'

const SingleColumnChart = ({ data, chartName ,pointLine,drillSeleted,setDrillSeleted,setDrillValueSeleted}) => {
  return data ? (
    <BarChartDisplay data={data} chartName={chartName} pointLine={pointLine} drillSeleted={drillSeleted} setDrillSeleted={setDrillSeleted} setDrillValueSeleted={setDrillValueSeleted}/>
  ) : (
    <Spinner />
  )
}

export default SingleColumnChart
