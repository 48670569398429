const bindActionCreators = (actionCreators, dispatch, getState) => {
  return Object.keys(actionCreators).reduce((boundActions, key) => {
    boundActions[key] = (...args) => {
      const action = actionCreators[key](...args)
      // Test for thunk
      return typeof action === 'function'
        ? action(dispatch, getState)
        : dispatch(action)
    }
    return boundActions
  }, {})
}
export default bindActionCreators
