export default function toTitleCase(string_value) {
  let finalstring = ''
  let strn = string_value.split(' ')
  for (let i = 0; i < strn.length; i++) {
    finalstring +=
      strn[i]
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toUpperCase() : word.toLowerCase()
        })
        .replace(/\s+/g, '') + (i === strn.length - 1 ? '' : ' ')
  }
  return finalstring
}
