import React from 'react';
import {Popup} from 'react-leaflet';
const MarkerPopup = (props) => {
  const { content } = props;

  const ToolTipFormatter = ({ content }) => {
    let notAv = content.originating_business === 'Name Not Available'
    return (
      <div style={{ fontFamily: 'Optum Sans', width: '100%', background: 'transparent', borderRadius: '4px', padding: '16px 0 16px 16px', marginRight: '20px' }}>
        <div style={{ color: '#424242' }}><div style={{ fontStyle: 'normal', fontWeight: '700', fontSize: '14px', lineHeight: '20px', color: '#002677' }}>
          {content.location_code}: {
            notAv !== true
              ? content.originating_business
              : <span style={{ color: '#4b4d4f', fontWeight: '400' }}> ({content.originating_business})</span>
          }</div>
          <div style={{ fontStyle: 'normal', fontWeight: '700', fontSize: '14px', lineHeight: '18px', color: '#4B4D4F', marginTop: '16px' }}>
            {content.primary_location_type_formated}{' '}
            {
              content.primary_location_type_formated !== 'Other'
                ? content.primary_location_sub_type_formated
                : ''
            }
          </div>
          <div style={{ marginRight: '12px', fontStyle: 'normal', fontWeight: '400', fontSize: '14px', lineHeight: '18px', display: 'flex', alignItems: 'center', color: '#4b4d4f', marginTop: '16px' }}>
            <div style={{ paddingRight: '10px' }}><img style={{ height: '19px', width: '16px' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAYAAACEYr13AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFwSURBVHgBnVNdSsNAEJ7ZbsBohXiDeIPeQEWQii9RDPjYnkA9QXOD2hPYRyGgfbMWpHqD3MB6gsaf0kqzGXdTUiVu2tgPEmZ25vsymZlFyKDs9naAYgcRHAKwk0OCgOQjyPAmt/uvv/MxNSznzor4egOQLmABKIa2IcaXYec4nAvMyGZfehUoAlkNj8Z7SoQpXxhmszB59tlKUq0yrbN7OxL4AiuB7TIhsAGrQjabEWBFXyW0+XS8xUu0rWwtX04Ky26XdMFIGHY6MsvpW5HxNdTlMcjBWvz+9uNMrLw8LhdmIJfEzgamJbO5dvLoKaH8PmHAKMaONsSgxkvTQWSYQ/mPNV0OxRQwQL1AITDRYp/+wbPcrCf4J9RKj/yjIGki51SXRYfF6Riqi5UUoV7hzeGAgLyidJWbjng+xpFfbcnNuFrORi/JTWvJxjfd7nVe19VGfvjVeubsL3QiOnKuQFYkj7wUG27vvHz6sPC2fgNzepm05hkshQAAAABJRU5ErkJggg==" alt="location" /></div>
            {content.address_line1} {content.address_line2}, <br />{content.geo_name}
          </div></div></div>
    )

  }

  return  (
    <Popup>
     <ToolTipFormatter content={content} />
    </Popup>
  );
};
export default MarkerPopup;