import React, { useState } from 'react'
import styles from './ProductLinks.module.scss'
import resIcon from '../../assets/images/icon/Lungs.png'
import birIcon from '../../assets/images/icon/Pregnancy.png'
import vulIcon from '../../assets/images/icon/Heart.png'
import airIcon from '../../assets/images/icon/Leaf.png'
import comIcon from '../../assets/images/icon/PieChart.png'
import closeIcon from '../../assets/images/icon/close_24dp.png'
import Modal from '../../components/ModalPopupProfile'
const ProductLinks = ({}) => {
  const [showInfoDialog, setShowInfoDialog] = useState(false)
  return (
    <>
      <div
        className={styles.base}
        onClick={() => {
          setShowInfoDialog(true)
        }}
      >
        <div>
          <span className={styles.captionFormat}>
            Coming Soon
            {/* <p className={styles.subCaptionFormat}>
              One-liner text about the product.
            </p> */}
          </span>
        </div>
        <div className={styles.kpisDiv}>
          <div className={styles.infoDiv1}>
            <img src={resIcon} alt="Respiratory Incidence"></img>
            <p>Respiratory Incidence</p>
          </div>
          <div className={styles.infoDiv2}>
            <img src={birIcon} alt="Birth & Trends"></img>
            <p> Birth & Trends</p>
          </div>
          <div className={styles.infoDiv3}>
            <img src={vulIcon} alt="Vulnerability"></img>
            <p>Vulnerability</p>
          </div>
        </div>
        <div className={styles.kpisDiv1}>
          <div className={styles.infoDiv1}>
            <img src={airIcon} alt="Air Quality"></img>
            <p>Air Quality</p>
          </div>
          <div className={styles.infoDiv2}>
            <img src={comIcon} alt="Comms Analytics"></img>
            <p> Comms Analytics</p>
          </div>
          <div
            className={styles.infoDiv3}
            style={{ backgroundColor: 'transparent' }}
          >
            <p></p>
          </div>
        </div>
      </div>
      <Modal open={showInfoDialog} onClose={() => setShowInfoDialog(false)}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '15%' }}>
            <span className={styles.captionFormat}>Coming Soon</span>
            {/* <p className={styles.subCaptionFormat}>
              One-liner text about the product.
            </p> */}
          </div>

          <div >
            <img
              className={styles.closePopup}
              src={closeIcon}
              alt="Close"
              onClick={() => setShowInfoDialog(false)}
            ></img>
          </div>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div className={styles.modal}>
            <div className={styles.infoDiv1}>
              <img src={resIcon} alt="Respiratory Incidence"></img>
              <p>Respiratory Incidence</p>
            </div>
            <div style={{ width: '220px', paddingLeft: '50px' }}>
              <p>
              Near Real Time Surveillance and Risk Level Forecasting of respiratory diseases for the current week and the next 3 weeks down to a hyper local level.
              </p>
            </div>
          </div>
          <div className={styles.modal}>
            <div className={styles.infoDiv1}>
              <img src={birIcon} alt="Birth & Trends"></img>
              <p>Birth & Trends</p>
            </div>
            <div style={{ width: '220px', paddingLeft: '50px' }}>
              <p>
              Near Real Time Hyper Local Surveillance & Forecasting of US Births up to 3 months in advance.
              </p>
            </div>
          </div>
          <div className={styles.modal}>
            <div className={styles.infoDiv1}>
              <img src={vulIcon} alt="Vulnerability"></img>
              <p>Vulnerability</p>
            </div>
            <div style={{ width: '220px', paddingLeft: '50px' }}>
              <p>
              Identify vulnerable population at risk of having an outcome event due to respiratory conditions at selected geo-resolution using predefined factors.
              </p>
            </div>
          </div>
          <div className={styles.modal}>
            <div className={styles.infoDiv1}>
              <img src={airIcon} alt="Air Quality"></img>
              <p>Air Quality</p>
            </div>
            <div style={{ width: '220px', paddingLeft: '50px' }}>
              <p>
              Near Real Time Surveillance of air quality parameters and Forecasting the consequent risk for patients with identified respiratory diseases for the current week and the next 3 weeks down to a hyper local level.
              </p>
            </div>
          </div>
          <div className={styles.modal}>
            <div className={styles.infoDiv1}>
              <img src={comIcon} alt="Comms Analytics"></img>
              <p>Comms Analytics</p>
            </div>
            <div style={{ width: '220px', paddingLeft: '50px' }}>
              <p>
              Key engagement analytics on segmented and targeted Healthcare Providers. 
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default ProductLinks
